<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getSitePlanData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Plan
        </a>
      </div>
    </div>
    <div  v-if="getSitePlanData().plan_manager === getUsername()" class="card card-details">
           <ReschedulePlanComponent
              :id = id
              :name = getSitePlanData().name
              :site_code = getSitePlanData().site_code
              :currency = getSitePlanData().currency
              :co2_emission_tonnes = Number(getSitePlanData().co2_emission_tonnes)
              :cost_baseline = getSitePlanData().cost_baseline
              :opportunityArray = getSitePlanData().opportunity_plan_relations
              :opportunityList = getSitePlanData().opportunity_plan_relations_list
              :opportunityDates = getSitePlanData().additional_details.opportunity_plan_dates
              :totalInvestmentChartData = getInvestmentWaterfallChartData()
           />
      </div>

    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import ReschedulePlanComponent from "@/components/site_plans/ReschedulePlanComponent.vue";

export default {
  name: "ReschedulePlan",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    NoPermissionComponent,
    ReschedulePlanComponent,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "isLoggedIn","getInvestmentWaterfallChartData"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError"]),
    getSitePlanDetails(id) {
      this.fetchSitePlanData({"id": id })
    },
    hasHistory () {
      return window.history?.length > 1
    },

  },
  computed: {

  },
  created() {
    this.getSitePlanDetails(this.id);
    this.performSetFormError(null);
    document.title = "Waterfall Graph";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.chosenSiteId = to.params.site_id;
    this.getSitePlanDetails(this.chosenId);
    next();
  }

}
</script>
