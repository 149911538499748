import api from "../../api/api.js"
import router from "../../router.js"

const state = {
  corporatePlans: [],
  corporatePlanData: null,
  corporateSitePlans: [],
  corporatePlanCarbonTracker: null,
  siteSpecificClimateWaterfall: null,
  siteSpecificAnnualWaterfall: null,
};


const getters = {
  getCorporatePlans: state => state.corporatePlans,
  getCorporatePlanData: state => state.corporatePlanData,
  getCorporateSitePlans: state => state.corporateSitePlans,
  getCorporatePlanCarbonTracker: state => state.corporatePlanCarbonTracker,
};


const actions = {
// CORPORATE
  async fetchAllCorporatePlans({commit}) {
    commit("setLoader", true);
    const response = await api.getAllCorporatePlans();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setCorporatePlans", response.queryset);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
// CORPORATE
  async fetchCorporatePlanData({commit, dispatch}, payload) {
    const response = await api.corporatePlanData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setCorporatePlanData", response);
      if(response.site_names.length > 0) {
        var firstSite = response.site_names[0]["site_code"]
        var siteSpecificClimateWaterfallDataset = {
            labels: response.site_carbon_reduction_per_climate_action_phase[firstSite][0],
            datasets:  [{
                        backgroundColor: response.site_carbon_reduction_per_climate_action_phase[firstSite][2],
                        data: response.site_carbon_reduction_per_climate_action_phase[firstSite][1],
                        tooltips: response.site_carbon_reduction_per_climate_action_phase[firstSite][5],
                      }]
      };
        dispatch ("performSetSiteSpecificClimateWaterfall", siteSpecificClimateWaterfallDataset);

        var siteSpecificAnnualWaterfallDataset = {
            labels: response.site_total_carbon_chart_data[firstSite][0],
            datasets:  [{
                        backgroundColor: response.site_total_carbon_chart_data[firstSite][2],
                        data: response.site_total_carbon_chart_data[firstSite][1],
                        tooltips: response.site_total_carbon_chart_data[firstSite][5],
                      }]
      };
        dispatch ("performSetSiteSpecificAnnualWaterfall", siteSpecificAnnualWaterfallDataset);
    }

// CORPORATE
//    Carbon  Climate Action Phase Waterfall
      var carbonClimateActionPhaseWaterfallChartDataset = {
                labels: response.carbon_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.carbon_climate_action_phase_chart_data[2],
                            data: response.carbon_climate_action_phase_chart_data[1],
                            tooltips: response.carbon_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setCarbonClimateActionPhaseWaterfallChartData", carbonClimateActionPhaseWaterfallChartDataset);
// CORPORATE
//    Financial  Climate Action Phase Waterfall
      var financialClimateActionPhaseWaterfallChartDataset = {
                labels: response.financial_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.financial_climate_action_phase_chart_data[2],
                            data: response.financial_climate_action_phase_chart_data[1],
                            tooltips: response.financial_climate_action_phase_chart_data[4],
                            currencies: response.financial_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setFinancialClimateActionPhaseWaterfallChartData", financialClimateActionPhaseWaterfallChartDataset);
// CORPORATE
//    Total investment Waterfall Chart
      var investmentWaterfallChartDataset  =  {
          labels: response.total_investment_chart_data[0],
          datasets: [{
                        backgroundColor: response.total_investment_chart_data[2],
                        data: response.total_investment_chart_data[1],
                        tooltips: response.total_investment_chart_data[4],
                        currencies: response.total_investment_chart_data[5],
                    }]
      };
      commit("setInvestmentWaterfallChartData", investmentWaterfallChartDataset);
// CORPORATE
//      Cost Savings Waterfall Waterfall
      var costSavingsWaterfallChartDataset = {
           labels: response.total_cost_savings_data[0],
           datasets: [{
                         backgroundColor: response.total_cost_savings_data[2],
                         data: response.total_cost_savings_data[1],
                         tooltips: response.total_cost_savings_data[5],
                         currencies: response.total_cost_savings_data[6],
                     }],
      };
      commit("setCostSavingsWaterfallChartData", costSavingsWaterfallChartDataset);
// CORPORATE
//    Cost Savings per Project chart
      var costSavingsChartDataset = {
                labels: response.cost_savings_per_project_data[0],
                datasets: [{
                              backgroundColor: response.cost_savings_per_project_data[2],
                              data: response.cost_savings_per_project_data[1],
                              tooltips: response.cost_savings_per_project_data[3],
                              currencies: response.cost_savings_per_project_data[4],
                          }]
            };
      commit("setCostSavingsChartData", costSavingsChartDataset);
// CORPORATE
//      Investment per Project Chart
      var investmentChartDataset = {
                labels: response.investment_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.investment_per_project_chart_data[2],
                              data: response.investment_per_project_chart_data[1],
                              tooltips: response.investment_per_project_chart_data[3],
                              currencies: response.investment_per_project_chart_data[4],
                          }]
            };
      commit("setInvestmentChartData", investmentChartDataset);
// CORPORATE
//      Carbon Waterfall Chart
      var carbonWaterfallChartDataset = {
           labels: response.total_carbon_chart_data[0],
           datasets: [{
                          data: response.carbon_tracker,
                          borderColor: "#ff7f0f",
                          borderWidth: 4,
                          type: 'line',
                          order: 0,
                          pointRadius: 1,
                        },
                        {
                         backgroundColor: response.total_carbon_chart_data[2],
                         data: response.total_carbon_chart_data[1],
                         tooltips: response.total_carbon_chart_data[5],
                        }
                     ],
      };
      commit("setCarbonWaterfallChartData", carbonWaterfallChartDataset);
// CORPORATE
//      Carbon per project chart
      var carbonChartDataset = {
                labels: response.carbon_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.carbon_per_project_chart_data[2],
                              data: response.carbon_per_project_chart_data[1],
                              tooltips: response.carbon_per_project_chart_data[3],
                          }]
            };
      commit("setCarbonChartData", carbonChartDataset);
// CORPORATE
//      Carbon per Euro chart
      var carbonPerEuroChartDataset = {
                labels: response.carbon_per_euro_data[0],
                datasets: [{
                              backgroundColor: response.carbon_per_euro_data[2],
                              data: response.carbon_per_euro_data[1],
                              tooltips: response.carbon_per_euro_data[3],
                              currencies: response.carbon_per_euro_data[4],
                          }]
            };
      commit("setCarbonPerEuroChartData", carbonPerEuroChartDataset);
// CORPORATE
//      Carbon per Euro
      if (response.carbon_per_euro_data.length[5] > 0) {
        var carbonPerEuroDict = response.carbon_per_euro_data[5];
        commit("setCarbonPerEuroDict", carbonPerEuroDict);
      } else {
        commit("setCarbonPerEuroDict", {});
      }
    }

  },
// CORPORATE
  async performCreateCorporatePlan({commit}, payload) {
    commit("setLoader", true);
    const response = await api.corporatePlanCreate(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setLoader", false);
        commit("setFormError", response.error);
      } else {
        commit("setLoader", false);
        router.push(`/corporate-plan-search`);
      }
    }
  },
// CORPORATE
  performCorporatePlanUpdate({commit}, payload) {
    const response = api.corporatePlanUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/corporate-plan-success/${payload.id}`);
      }
  },
// CORPORATE
  performCorporatePlanPublish({commit}, payload) {
    const response = api.corporatePlanPublish(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/corporate-plan-success/${payload.id}`);
      }
  },
// CORPORATE
  async fetchCorporateSitePlansQueryset({commit, dispatch}, payload) {
    dispatch ("clearCorporateSitePlanList");
    commit("setLoader", true);
    const response = await api.corporateSitePlans(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setCorporateSitePlans", response.site_plans);
      commit("setResultCount", response.site_plans.length);
      commit("setLoader", false);
    }
  },
// CORPORATE
  async performCreateCorporateSitePlanRelation({commit}, payload) {
    commit("setLoader", true);
    const response = await api.createCorporateSitePlanRelation(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setLoader", false);
      router.push(`/corporate-plan-success/${payload.id}`);
    }
  },
// CORPORATE
  async fetchCorporatePlanCarbonTracker({commit}, payload) {
    const response = await api.corporatePlanCarbonTracker(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setCorporatePlanCarbonTracker", response.carbon_trackers);
      commit("setLoader", false);
    }
  },
// CORPORATE
  async performCorporatePlanCarbonTrackerUpdate({commit}, payload) {
    commit("setLoader", true);
    const response = await api.corporatePlanCarbonTrackerUpdate(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setLoader", false);
        commit("setFormError", response.error);
      } else {
        commit("setLoader", false);
        router.push(`/corporate-plan-update-success/${payload.id}`);
      }
    }
  },
  clearCorporateSitePlanList({commit}) {
    commit("setCorporateSitePlans", []);
    commit("setNext", null);
    commit("setCurrentSearch", null);
    commit("setResultCount", null);
  },
};


const mutations = {
  setCorporatePlans:  (state, corporatePlans) => {
    state.corporatePlans = corporatePlans;
  },
  setCorporatePlanData: (state, corporatePlanData) => {
    state.corporatePlanData = corporatePlanData
  },
  setCorporateSitePlans:  (state, corporateSitePlans) => {
    state.corporateSitePlans = corporateSitePlans;
  },
  setCorporatePlanCarbonTracker: (state, corporatePlanCarbonTracker) => {
    state.corporatePlanCarbonTracker = corporatePlanCarbonTracker;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}