<template>
    <div v-if="currency" class="row plain-element">
        <form @submit.prevent="updatePlan()" enctype="multipart/form-data">
            <div class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                    <div class="row row-functions text-start">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                            <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png'"
                                 class="img responsive img-icon">
                        </div>
                        <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                            <h3 class="font-dark-grey">Update {{ getSitePlanData().name }}</h3>
                        </div>
                        <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                            <button type="submit" class="btn btn-report">
                                Submit Update
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row plain-element text-start">
                    <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
                        <div class="row plain-element opportunity-description text-start">
                            <h6 class="font-dark-grey">
                                Update Currency:

                            </h6>
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                                    <select v-model="currency" name="currency" id="currency" required>
                                        <option v-for="(element, index) in getCurrencies()" :value="element" :key="index">
                                            {{ element }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                         <p class="w-100"> </p>
                        <div class="row plain-element opportunity-description text-start">
                            <h6 class="font-dark-grey">
                                Update Name:

                            </h6>
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                                    <textarea rows="2" cols="40" name="name" v-model="name" maxlength="120" required>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"> </p>
                        <div class="row plain-element opportunity-description text-start">
                            <h6 class="font-dark-grey">
                                Update Description:
                            </h6>
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                                    <textarea rows="5" cols="40" name="description" v-model="description" maxlength="120" required>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-6 col-lg-7 plain-element text-end">
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-dark-grey">
                                        Cost Baseline:
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                            {{ currency }}
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-5 col-value">
                                            <input id="cost_baseline" type="number" name="cost_baseline" v-model="cost_baseline" class="validate" min="-10000000" max="1000000000"
                                                   step="1" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"> </p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-cyan-blue">
                                        Prev. Year Annual CO2 Electricity Emission:
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                            Tonnes:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-5 col-value">
                                            <input id="co2_electricity_tonnes_previous_year" type="number" name="co2_electricity_tonnes_previous_year"
                                                   v-model="co2_electricity_tonnes_previous_year" class="validate" min="-10000000" max="1000000000"
                                                   step="01" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-cyan-blue">
                                        Renewable Electricity Year
                                        <span class="font-14">*</span>
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-4 ">
                                            Year:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                                            <select v-model="co2_electricity_reductions_year" name="co2_electricity_reductions_year" id="co2_electricity_reductions_year" required>
                                                <option v-for="year in years" :value="year" :key="year">
                                                    {{ year }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"> </p>
                        <div class="row plain-element">
                             <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-lime-green">
                                        Prev. Year Annual CO2 Fuel Emission:
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                            Tonnes:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-5 col-value">
                                            <input id="co2_fuel_tonnes_previous_year" type="number" name="co2_fuel_tonnes_previous_year" v-model="co2_fuel_tonnes_previous_year"
                                                   class="validate" min="-10000000" max="1000000000"
                                                   step="01" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-lime-green">
                                        Renewable Fuels Year
                                        <span class="font-14">*</span>
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-4 ">
                                            Year:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                                            <select v-model="co2_fuel_reductions_year" name="co2_fuel_reductions_year" id="co2_fuel_reductions_year"
                                                    required>
                                                <option v-for="year in years" :value="year" :key="year">
                                                    {{ year }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"> </p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-gold">
                                        Optional CO2 Baseline:
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                            Tonnes
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-5 col-value">
                                            <input id="co2_baseline" type="number" name="co2_baseline" v-model="co2_baseline" class="validate" min="-10000000" max="1000000000"
                                                   step="1" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                <div class="row plain-element text-start">
                                    <h6 class="font-gold">
                                        Optional CO2 Baseline Year
                                        <span class="font-14">*</span>
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-4 ">
                                            Year:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                                            <select v-model="co2_baseline_year" name="co2_baseline_year" id="co2_baseline_year"
                                                    required>
                                                <option v-for="year in previous_years" :value="year" :key="year">
                                                    {{ year }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </form>
    </div>
</template>


<script>
import { validateString, } from "@/common/validation.js";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "UpdateSitePlanComponent",
  props: {
    id: { type: String, required: true },
    current_name: { type: String, required: false },
    current_description: { type: String, required: false },
    current_co2_electricity_tonnes_previous_year: { type: String, required: false },
    current_co2_fuel_tonnes_previous_year: { type: String, required: false },
    current_cost_baseline: { type: Number, required: false },
    current_currency: { type: String, required: false },
    current_company: { type: String, required: false },
    current_site_code: { type: String, required: false },
    current_co2_electricity_reductions_year: { type: String, required: false },
    current_co2_fuel_reductions_year: { type: String, required: false },
    current_co2_baseline: { type: String, required: false },
    current_co2_baseline_year: { type: String, required: false },
    current_publish: { type: Boolean, required: false },
    current_macc_carbon_offsetting_cost: { type: String, required: false },
    current_macc_carbon_per_money: { type: String, required: false },
    current_archive: { type: Boolean, required: false },

  },
  data() {
    return {
      name: this.current_name,
      description: this.current_description,
      co2_electricity_tonnes_previous_year: this.current_co2_electricity_tonnes_previous_year,
      co2_fuel_tonnes_previous_year: this.current_co2_fuel_tonnes_previous_year,
      cost_baseline: this.current_cost_baseline,
      company: this.current_company,
      site_code: this.current_site_code,
      currency: this.current_currency,
      co2_electricity_reductions_year: this.current_co2_electricity_reductions_year,
      co2_fuel_reductions_year: this.current_co2_fuel_reductions_year,
      co2_baseline: this.current_co2_baseline,
      co2_baseline_year: this.current_co2_baseline_year,
      years: ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"],
      previous_years: ["2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"],
      macc_carbon_offsetting_cost: this.current_macc_carbon_offsetting_cost,
      macc_carbon_per_money: this.current_macc_carbon_per_money,
      archive: this.current_archive,

    }
  },

  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "getCompanyString", "getCurrencies"]),
    ...mapActions(["performSitePlanUpdate", "performSetFormError"]),
    validate: validateString,
    updatePlan() {
      if (this.validate(this.name) === "error" ||
          this.validate(this.description) === "error"
      )  {
            this.performSetFormError("Forbidden character at the start of a string(+,=,-,@)");
      } else {
      this.performSitePlanUpdate({
                               "id": this.id,
                               "name": this.validate(this.name),
                               "description": this.validate(this.description),
                               "co2_electricity_tonnes_previous_year": this.co2_electricity_tonnes_previous_year,
                               "co2_fuel_tonnes_previous_year": this.co2_fuel_tonnes_previous_year,
                               "cost_baseline": this.cost_baseline,
                               "currency": this.currency,
                               "company": this.company,
                               "site_code": this.site_code,
                               "co2_electricity_reductions_year": this.co2_electricity_reductions_year,
                               "co2_fuel_reductions_year": this.co2_fuel_reductions_year,
                               "co2_baseline": this.co2_baseline,
                               "co2_baseline_year": this.co2_baseline_year,
                               "macc_carbon_offsetting_cost": this.macc_carbon_offsetting_cost,
                               "macc_carbon_per_money": this.macc_carbon_per_money,
                               "archive": this.archive
                              })
      }
    },
  },
  computed: {
  },
  created() {

  }


}
</script>