import api from "../../api/api.js"
import router from "../../router.js"

const state = {
  token: window.localStorage.getItem('token'),
  username: window.localStorage.getItem('username'),
  admin: window.localStorage.getItem('admin'),
  staff: window.localStorage.getItem('staff'),
  profile: null,
  position: null,
  error: null,
  userSites: null,
  userSitesArray: null,
  userPermissionsArray: null,
  userList: null,
  siteAdmin: null,
  noProfile: "",
};


const getters = {
// Check if token exists or not
  isLoggedIn: state => !!state.token || !!state.username,
  getUsername: state => state.username,
  getProfile: state => state.profile,
  getPosition: state => state.position,
  getToken: state => state.token,
  getAdmin: state => state.admin,
  getStaff: state => state.staff,
  getSiteAdmin: state => state.siteAdmin,
  getError: state => state.error,
  getUserSites: state => state.userSites,
  getUserSitesArray: state => state.userSitesArray,
  getNoProfile: state => state.noProfile,
  getUserPermissionsArray: state => state.userPermissionsArray,
  getUserPermissionsUsername: state => state.userPermissionsUsername,
  getUserList: state => state.userList,
};


const actions = {
  async fetchUserList({ commit }) {
    const response = await api.userList();
    if (!response) {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      commit('setUserList', response.queryset);

    }
  },
  async getUserInfo({ commit }) {
    const response = await api.userInfo();
    if (response["email"] || response["username"]) {
      const username = response["email"] || response["username"];
      window.localStorage.setItem('username', username);
      const admin = response["admin"];
      window.localStorage.setItem('admin', admin);
      const staff = response["staff"];
      window.localStorage.setItem('staff', staff);
      const site_permissions = response["site_permissions"];
      window.localStorage.setItem('site_permissions', site_permissions);
    }
    else {
      commit('setUsername', null);
      commit('setAdmin', null);
      commit('setStaff', null);
      window.localStorage.setItem('admin', null);
      window.localStorage.setItem('username', null);
      window.localStorage.setItem('staff', null);
    }
  },
  async fetchUserProfile({ commit }) {
    const response = await api.userProfile();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      if ( response.user_profile ) {
        commit('setProfile', response.user_profile);
        commit('setNoProfile', "");
      } else {
        console.log(response);
        commit('setNoProfile', "None");
      }
    }
  },
  async performUpdateUserProfile({commit}, payload) {
    const response = await api.updateUserProfile(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit('setProfile', null);
      router.push(`/profile-success`);
    }
  },
  async performCreateProfile({commit}) {
    const response = await api.createUserProfile()
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit('setProfile', null);
      router.push(`/profile-success`);
    }
  },
  async fetchUserSites({ commit }) {
    commit("setLoader", true);
    const response = await api.userSites();

    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
      commit("setLoader", false);
    }
    else {
      commit('setUserSites', response.user_sites);
      commit('setUserSitesArray', response.user_sites_list);
      commit('setSiteAdmin', response.site_admin);
      commit("setLoader", false);
    }
  },
  async fetchUserPermissions({commit}, payload) {
    const response = await api.userPermissions(payload.id);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setUserPermissionsArray", response.queryset);
      commit("setUserPermissionsUsername", response.username);
    }
  },
  async performUpdateSitePermissions({commit}, payload) {
    const response = await api.updateSitePermissions(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/permissions`);
    }
  },
};


const mutations = {
// Update user
  setUserList: (state, userList) => {
    state.userList = userList
  },
  setUsername: (state, username) => {
    state.username = username
  },
  setAdmin: (state, admin) => {
    state.admin = admin
  },
  setStaff: (state, staff) => {
    state.staff = staff
  },
  setSiteAdmin: (state, siteAdmin) => {
    state.siteAdmin = siteAdmin
  },
  setProfile: (state, profile) => {
    state.profile = profile
  },
  setNoProfile: (state, noProfile) => {
    state.noProfile = noProfile
  },
  setPosition: (state, position) => {
    state.position = position
  },
  setUserSites: (state, userSites) => {
    state.userSites = userSites
  },
  setUserSitesArray: (state, userSitesArray) => {
    state.userSitesArray = userSitesArray
  },
  setUserPermissionsArray: (state, userPermissionsArray) => {
    state.userPermissionsArray = userPermissionsArray
  },
  setUserPermissionsUsername: (state, userPermissionsUsername) => {
    state.userPermissionsUsername = userPermissionsUsername
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}