<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getSiteNames()" class="dashboard">
    <div class="row row-cards">
      <div class="row plain-element top-menu">
        <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'alarm-search'}" class="horizontal-link">Meter Alarms
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'alarm-create'}" class="horizontal-link underline-green">Create Alarm
          </router-link>
        </li>
        </ul>
      </div>
    </div>
    <p class="w-100"></p>
    <div class="card card-details">
      <p class="error" v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error" v-else>
      </p>
      <form @submit.prevent="createAlarm()" enctype="multipart/form-data">
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
            <div class="row row-functions text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                <img src="https://saperapublicstorage.blob.core.windows.net/media/logos/em3-plain.png"
                     class="img responsive img-icon">
              </div>
              <div class="col col-sm-6 col-md-8 col-lg-5 plain-element text-start">
                <h3 class="font-dark-grey">Create Meter Alarm</h3>
              </div>
              <div class="col col-sm-6 col-md-4 col-lg-6 plain-element text-end">
                <button type="submit" class="btn btn-report">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
            <div v-if="getNestedEnpi()" class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 text-start">
                <div class="row text-start">
                  <h6 class="font-dark-grey">Site<span class="font-14">*</span>
                  </h6>
                  <div class=" col col-12 col-sm-12 col-md-11 col-lg-11 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-form">
                      <select v-model="site_code" name="site_code" id="site_code"
                              required>
                        <option v-for="(key, value, index) in getNestedEnpi()" :value="value" :key="index">
                          {{ value }} - {{ getSiteNames()[value] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="getNestedEnpi()" class="row plain-element text-start">
                  <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 text-start">
                    <div class="row text-start">
                      <h6 class="font-dark-grey">SEU<span class="font-14">*</span>
                      </h6>
                      <div class=" col col-12 col-sm-12 col-md-11 col-lg-11 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-form">
                          <select v-model="seu" name="seu" id="seu" required>
                            <option v-for="(key, value, index) in getNestedEnpi()[site_code]" :value="value" :key="index">
                              {{ value }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div v-if="getNestedEnpi()" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
            <div v-if="getNestedEnpi()[site_code]" class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 text-start">
                <div class="row text-start">
                  <h6 class="font-dark-grey">EnPI<span class="font-14">*</span>
                  </h6>
                  <div class=" col col-12 col-sm-12 col-md-11 col-lg-11 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-form">
                      <select v-model="enpi" name="enpi" id="enpi" required>
                        <option v-for="(key, value, index) in getNestedEnpi()[site_code][seu]" :value="key" :key="index">
                          {{ key }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="enpi" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
            <div class="row plain-element text-start">
              <div class="row plain-element opportunity-description text-start"><h6 class="font-dark-grey">Email Alarm To<span class="font-14">*</span></h6>
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-11 col-form">
                    <textarea rows="1" cols="15" name="email" v-model="email" required></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class="row plain-element opportunity-description text-start"><h6 class="font-dark-grey">Trigger Value<span class="font-14">*</span></h6>
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-11 col-form">
                    <input id="threshold" type="number" name="threshold" v-model="threshold" min="0" max="1000000000"
                                                       step="0.1" required>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class="row plain-element opportunity-description text-start"><h6 class="font-dark-grey">Above/Below<span class="font-14">*</span></h6>
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-11 col-form">
                      <select v-model="above" name="above" id="above" required>
                        <option>Above</option>
                        <option>Below</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>




</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "AlarmCreate",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      site_code: null,
      seu: null,
      enpi: null,
      threshold: null,
      email: null,
      above: null,
    }
  },
  methods: {
    ...mapGetters([
                    "getSiteNames", "getFormError", "getUsername", "isLoggedIn", "getActionTypes", "getCompanyString", "getNestedEnpi"
                  ]),
    ...mapActions([
                    "fetchSiteNames", "performCreateAlarm", "performSetFormError", "fetchNestedEnpi"
                   ]),
    createAlarm() {
      this.performCreateAlarm({
        "site_code" : this.site_code,
        "seu": this.seu,
        "enpi": this.enpi,
        "threshold": this.threshold,
        "email": this.email,
        "above": this.above,
      })
    },
    handleFileUpload() {
          this.pdf_report = this.$refs.file.files[0];
          this.filename = this.$refs.file.files[0].name;

        },
    hasHistory () {
      return window.history?.length > 1
    },

  },
  computed: {

  },
  created() {
    document.title = "Create Alarm";
    this.fetchSiteNames();
    this.fetchNestedEnpi();
    window.scrollTo(0,0);
  },
}

</script>