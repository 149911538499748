<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="$router.push('/permissions')">
          <i class="fas fa-chevron-left"></i> Back to User Choice
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>


      <div v-if="getUserPermissionsArray()"  class="row plain-element">
        <div class="row plain-element">
          <p class="w-100"></p>
          <div v-if="getUserPermissionsArray()" class="row plain-element text-start">
            <div class="col col-12 col-sm-12 col-md-6 col-lg-12 text-start">
              <div class="row plain-element">
                <ChangeUserPermissionsComponent
                  :user_permissions = getUserPermissionsArray()
                  :user_id = id
                  :username = getUserPermissionsUsername()


                />
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import ChangeUserPermissionsComponent from "@/components/permissions/ChangeUserPermissionsComponent.vue"
export default {
  name: "UserPermissionsView",
  components: {
    NoPermissionComponent,
    ChangeUserPermissionsComponent,

  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUsername", "getUsers", "getCompanyString", "isLoggedIn", "getFormError", "getUserPermissionsArray", "getUserList", "getUserPermissionsUsername" ]),
    ...mapActions(["performSetFormError", "fetchUserPermissions"]),
    hasHistory () {
      return window.history?.length > 1
    },
    goToUserPermissions(id) {
        location.href=(`/user-permissions/${id}`);
    },
  },
  computed: {
  },
  created() {
    this.performSetFormError(null);
    this.fetchUserPermissions({"id": this.id});
    document.title = "User Permissions";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchUserPermissions({"id": this.id});
    next();
  }
}
</script>
