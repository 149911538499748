import api from "../../api/api.js";
import router from "../../router.js";

const state = {
  alarmList: [],
  alarmData: null,
  alarmId: "",
  nestedSitesAndAlarms: null,
};


const getters = {
  getAlarmList: state => state.alarmList,
  getAlarmData: state => state.alarmData,
  getAlarmId: state => state.alarmId,
  getNestedSitesAndAlarms: state => state.nestedSitesAndAlarms,
};


const actions = {
  async fetchAllAlarms({commit}) {
    commit("setLoader", true);
    const response = await api.getSearchAlarms();

    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setAlarmList", response.queryset);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
  async performCreateAlarm({commit}, payload) {
    const response = await api.alarmCreate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
      } else {
        router.push(`/alarm-success/${payload.id}`);
      }
    }
  },
  performAlarmUpdate({commit}, payload) {
    const response = api.alarmUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {

        router.push(`/alarm-success/${payload.id}`);
      }
  },
  async fetchAlarmData({commit}, payload) {
    const response = await api.alarmData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setAlarmData", response);
    }
  },
  async fetchNestedSitesAndAlarms({commit}) {
    const response = await api.nestedSitesAndAlarms();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedSitesAndAlarms", response.queryset);
    }
  },
  clearAlarmData({commit}) {
    commit("setAlarmData", null);
  }
};

const mutations = {
  setAlarmList:  (state, alarmList) => {
    state.alarmList = alarmList;
  },
  setAlarmData: (state, alarmData) => {
    state.alarmData = alarmData;
  },
  setAlarmId: (state, alarmId) => {
    state.alarmId = alarmId;
  },
  setNestedSitesAndAlarms: (state, nestedSitesAndAlarms) => {
    state.nestedSitesAndAlarms = nestedSitesAndAlarms
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}