<template>
  <div class="card card-site-dashboard card-site-dashboard-1">
    <div class="row-card-site-dashboard-title">
      {{ title }}
    </div>
    <div class="row-card-site-dashboard-value-1 font-light-green">
      <i-count-up
              :start="0"
              :endVal="value"
              :options="options"
      ></i-count-up>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ICountUp from 'vue-countup-v2';

export default {
  name: "OverallKpiCardComponent",
  components: {
      ICountUp,
  },
  props: {
    title: {type: String, required: true },
    value: {type: Number, required: true },
  },
  data() {
    return {
      options: {
            useEasing: true,
            useGrouping: true,
            duration: 1,
      },
    }
  },
  methods: {
    ...mapGetters([]),
    ...mapActions([]),
    callback: function (ins) {
        ins.update(ins.endVal + 100)
    },

  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>