<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getSitePlanData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Site Plan
        </a>
      </div>
    </div>
    <div v-if="getSitePlanData().plan_manager === getUsername()" class="card card-details card-opportunity-select">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>

      </p>
           <AttachRemovePlanOpportunityComponent
              :id = id
              :name = getSitePlanData().name
              :site = getSitePlanData().site_code
              :opportunityArray = getSitePlanData().opportunity_plan_relations_list
           />
      </div>

    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import AttachRemovePlanOpportunityComponent from "@/components/site_plans/AttachRemovePlanOpportunityComponent.vue";

export default {
  name: "AttachRemoveOpportunity",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    NoPermissionComponent,
    AttachRemovePlanOpportunityComponent,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "isLoggedIn","getFormError"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError"]),
    getSitePlanDetails(id) {
      this.fetchSitePlanData({"id": id })
    },
    hasHistory () {
      return window.history?.length > 1
    },

  },
  computed: {

  },
  created() {
    this.getSitePlanDetails(this.id);
    this.performSetFormError(null);
    document.title = "Attach/Remove Opportunities";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.chosenSiteId = to.params.site_id;
    this.getSitePlanDetails(this.chosenId);
    next();
  }

}
</script>
