<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getNestedSitesAndSeu()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'seu-dashboards'}" class="horizontal-link underline-green">SEU Dashboards
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="row plain-element">
    <div class="row row-opportunities">
      <form @submit.prevent="displayDashboard()">
        <div class="row plain-element">
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site Code</label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearSeu()">
                <option value="" selected> Select Site</option>
                <option v-for="(key, value, index) in getNestedSitesAndSeu()" :key="index">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">SEU</label>
              </div>
              <select v-model="seu" name="seu" id="seu">
                <option value="" selected> Select SEU</option>
                <option v-for="(element, index) in getNestedSitesAndSeu()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getNestedSitesAndSeu()" class="col col-sm-6 col-md-3 col-lg-3 plain-element">
              <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-12 col-form">
                 <button type="submit" class="btn btn-dashboard">
                    Display Dashboard
                 </button>
              </div>
          </div>
        </div>
      </form>
      <p class="w-100"></p>
      <p class="w-100"></p>
      <div v-if="getDashboard()" class="row plain-element">
        <div v-if="getDashboard() !== 'nan' && getDashboard() !== 'None'" class="row plain-element">
            <iframe width="1140" height="541.25"  :src="getDashboard()" allowFullScreen="true"></iframe>
        </div>
        <div v-else class="text-center">
            <p>Dashboard in Development</p>
        </div>
      </div>
    </div>
  </div>





</div>


</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "PowerBI",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      search: "",
      siteList: null,
      site: "",
      seu: "",
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getNestedSitesAndSeu", "isLoggedIn", "getLoader", "getResultCount", "getDashboard" ]),
    ...mapActions([ "performSetFormError", "fetchAllActions", "fetchNestedSitesAndSeu", "getSEUDashboard"]),
    clearSeu() {
        if (this.site === "") {
          this.seu = ""
        } else {
          this.seu = ""
        }
    },
    displayDashboard() {
      if (this.site !== "" && this.seu !== "") {

        this.getSEUDashboard({"site_code": this.site, "seu": this.seu})


      } else {
        console.log("empty")
      }
    }


  },
  computed: {

  },
  created() {
    document.title = "SEU Dashboards";
    this.fetchNestedSitesAndSeu();
    this.performSetFormError(null);
    window.scrollTo(0,0);
  },
}

</script>