<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()"  class="dashboard-cards">
      <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()"  class="dashboard-cards" v-cloak>
    <div class="row row-title">
      <div class="page-title-container">
        <h1 class="page-title underline-green">
          {{ getCompanyName() }} Portal
        </h1>
      </div>
    </div>
    <div v-if="getUsername()"  class="row row-cards">
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <router-link :to="{name: 'action-search'}">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/actions.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Actions</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <router-link :to="{name: 'opportunity-search'}">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/turbine.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Opportunities</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <a href="/action-report">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/compass.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Reports</span>
            </div>
          </div>
        </a>
      </div>
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <a href="/power-bi/powerbi-dashboards">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/screen.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">SEU Dashboards</span>
            </div>
          </div>
        </a>
      </div>
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <a href="/power-bi/energyeye-dashboards">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/energy_eye.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Energy Eye</span>
            </div>
          </div>
        </a>
      </div>
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <router-link :to="{name: 'capacity-tool'}">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/capacity_tool.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Capacity Tool</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <router-link :to="{name: 'pdf-report-search'}">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/documents.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Download Docs</span>
            </div>
          </div>
        </router-link>
      </div>
      <div  v-if="getStaff() === 'true'" class=" col col-12 col-sm-12 col-md-4 col-lg-3 plain-element">
        <a href="/db-extraction/">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/nuclear.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Refresh Data</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="footer">
    <p>
      <a class="font-blue" target="_blank" href="https://em3-support.atlassian.net/servicedesk/customer/portal/3">Need Help?</a> • <a target="_blank" href="https://em3.ie/privacy-policy/">Privacy Policy</a> • © 2022 EM3 SAPERA - All Rights Reserved
    </p>
  </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "Home",
  components: {
    NoPermissionComponent,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters([ "isLoggedIn", "getUsername", "getAdmin", "getCompanyName", "getCompanyString", "getStaff"]),
    ...mapActions(["getUserInfo", "getCompanyInfo"]),

  },
  computed: {

  },
  created() {
    window.scrollTo(0,0);
    this.getUserInfo();
    this.getCompanyInfo();
  },
  beforeRouteUpdate (to, from, next) {
    this.getUserInfo();
    next();
  }
}

</script>

