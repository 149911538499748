<template>
  <div v-if="current_name" class="row plain-element">
    <form @submit.prevent="updatePlan()" enctype="multipart/form-data">
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png'"
                   class="img responsive img-icon">
            </div>
            <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
              <h3 class="font-dark-grey">Copy {{ getSitePlanData().name }} </h3>
            </div>
            <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
              <button type="submit" class="btn btn-report">
                Copy Plan
              </button>
            </div>
          </div>
        </div>
        <div class="row plain-element text-start">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">

            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Plan Name:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="2" cols="40" name="name" v-model="name" maxlength="120" required>

                    </textarea>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>

    </form>
  </div>
</template>


<script>
import { validateString, } from "@/common/validation.js";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "CopySitePlanComponent",
  props: {
    id: { type: String, required: true },
    current_name: { type: String, required: false },
  },
  data() {
    return {
      name: "Copy - " + this.current_name,
    }
  },

  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "getCompanyString"]),
    ...mapActions(["performSitePlanCopy", "performSetFormError"]),
    validate: validateString,
    updatePlan() {
      if (this.validate(this.name) === "error" )  {
            this.performSetFormError("Forbidden character at the start of a string(+,=,-,@)");
      } else {
      this.performSitePlanCopy({
                               "id": this.id,
                               "name": this.validate(this.name),

                              })
      }
    },
  },
  computed: {
  },
  created() {

  }


}
</script>