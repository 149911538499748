<template>
    <div v-if="value && difference > 0"
         @click="goToActions()"
         class="card card-site-dashboard card-site-dashboard-2 clickable"
         :style="{ 'background-image': 'url(' + 'https://saperapublicstorage.blob.core.windows.net/static/img/charts/chart-asc-positive-' + chart + '.png' +  ')' }"
    >
      <div class="row-card-site-dashboard-title">
        Potential Savings<span id="span-T">(PY)</span>
      </div>
      <div class="row-card-site-dashboard-value-2">
        <i-count-up
                :start="0"
                :endVal="currencyValue"
                :options="options"
        ></i-count-up>
        {{ symbol }}
      </div>
      <div v-if="difference < 0"
           class="row-card-site-dashboard-change appear red">
        {{difference}}% m/m
      </div>
      <div v-else-if="difference === 0"
           class="row-card-site-dashboard-change appear grey">
        {{difference}}% m/m
      </div>
      <div v-else class="row-card-site-dashboard-change appear green">
        {{difference}}% m/m
      </div>
    </div>
    <div v-else-if="value && difference === 0"
         @click="goToActions()"
         class="card card-site-dashboard card-site-dashboard-2 clickable"
         :style="{ 'background-image': 'url(' + 'https://saperapublicstorage.blob.core.windows.net/static/img/charts/chart-neutral-' + chart + '.png' +  ')' }"
    >
      <div class="row-card-site-dashboard-title">
        Potential Savings<span id="span-T">(PY)</span>
      </div>
      <div class="row-card-site-dashboard-value-2">
        <i-count-up
                :start="0"
                :endVal="currencyValue"
                :options="options"
        ></i-count-up>
        {{ symbol }}
      </div>
      <div v-if="difference < 0"
           class="row-card-site-dashboard-change appear green">
        {{difference}}% m/m
      </div>
      <div v-else-if="difference === 0"
           class="row-card-site-dashboard-change appear grey">
        {{difference}}% m/m
      </div>
      <div v-else class="row-card-site-dashboard-change appear red">
        {{difference}}% m/m
      </div>
    </div>
    <div v-else-if="value && difference < 0"
         @click="goToActions()"
         class="card card-site-dashboard card-site-dashboard-2 clickable"
         :style="{ 'background-image': 'url(' + 'https://saperapublicstorage.blob.core.windows.net/static/img/charts/chart-desc-positive-' + chart + '.png' +  ')' }"
    >
      <div class="row-card-site-dashboard-title">
        Potential Savings<span id="span-T">(PY)</span>
      </div>
      <div class="row-card-site-dashboard-value-2">
        <i-count-up
                :start="0"
                :endVal="currencyValue"
                :options="options"
        ></i-count-up>
        {{ symbol }}
      </div>
      <div v-if="difference < 0"
           class="row-card-site-dashboard-change appear green">
        {{difference}}% m/m
      </div>
      <div v-else-if="difference === 0"
           class="row-card-site-dashboard-change appear grey">
        {{difference}}% m/m
      </div>
      <div v-else class="row-card-site-dashboard-change appear red">
        {{difference}}% m/m
      </div>
    </div>
    <div v-else class="card card-site-dashboard">
      <div class="row-card-site-dashboard-title grey">
        No Data
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ICountUp from 'vue-countup-v2';

export default {
  name: "PotentialSavingsCardComponent",
  components: {
      ICountUp,
  },
  props: {
    value: {type: Number, required: true },
    difference: {type: Number, required: true },
    chart: {type: Number, required: true },
    multiplier: {type: Number, required: true },
    symbol: {type: String, required: true },
  },
  data() {
    return {
      options: {
            useEasing: true,
            useGrouping: true,
            duration: 1,
      },
    }
  },
  methods: {
    ...mapGetters([]),
    ...mapActions([]),
    callback: function (ins) {
        ins.update(ins.endVal + 100)
    },
    goToActions() {
        window.open(`/action-search`, '_blank');
    },

  },
  computed: {
    currencyValue() {
        return this.value * this.multiplier
    },

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>