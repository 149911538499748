<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getNestedSitesAndSeu()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'opportunity-search'}" class="horizontal-link">Opportunity Search
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'opportunity-create'}" class="horizontal-link">Create Opportunity</router-link>
        </li>
        <li v-if="getArchivedOpportunities().length > 0">
          <router-link :to="{name: 'opportunity-archive-search'}" class="horizontal-link underline-green">Archive</router-link>
        </li>
      </ul>
    </div>

    <div class="row plain-element">
      <div class="row row-opportunities">
        <div class="row plain-element">
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site </label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearSeu()">
                <option value="" selected> All Sites</option>
                <option v-for="(key, value, index) in getNestedSitesAndSeu()" :key="index" :value="value">
                  {{ value }} - {{getSiteNames()[value]}}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">SEU</label>
              </div>
              <select v-model="seu" name="seu" id="seu" @change="clearSearch()">
                <option value="" selected> All SEU</option>
                <option v-for="(element, index) in getNestedSitesAndSeu()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-1 col-lg-2 plain-element text-end">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Status</label>
              </div>
              <select v-model="status" name="status" id="status" @change="clearSearch()">
                <option value="active" selected> Active</option>
                <option value="rejected" selected> Rejected</option>
                <option value="" selected> All</option>
              </select>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-5 col-lg-4 plain-element text-end">
            <div v-if="getNestedSitesAndSeu()" class="input-field  col col-12 col-sm-12 col-form">

              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
        </div>
        <div v-if="getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
        </div>
        <p class="w-100"></p>
        <p class="w-100"></p>
        <div id="parent">
          <div id="child">
            <table v-if="getArchivedOpportunities() && getLoader() === false"  id="searchTable">
              <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
              <tr>
                <th  class="text-start" @click="sortTableNew(0)">Site
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th  class="text-start" @click="sortTableNew(1)">SEU
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th class="text-center th-description">Description</th>
                <th @click="sortTableNew(3)" class="text-start">Status
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableNew(4)" class="text-start">Climate Action
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(5)" class="text-end">Savings(€)
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(6)" class="text-end">Total Inv.
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(7)" class="text-end">IRR(%)
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(8)" class="text-end">CO2(T)
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(9)" class="text-end">CO2/1k€(T)
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(10)" class="text-end">MWHe
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(11)" class="text-end">MWHt
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>
                <th @click="sortTableCurrencyInt(12)" class="text-end">Payback
                  <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                </th>

              </tr>
              </thead>
              <thead v-else-if="keywordFilteredList.length > 10000">
              <tr>
               <th class="text-start">Site</th>
                <th class="text-start">SEU</th>
                <th class="text-center th-description">Description</th>
                <th class="text-start">Status</th>
                <th class="text-start">Climate Action</th>
                <th class="text-end">Savings(€)</th>
                <th class="text-end">Total Inv.</th>
                <th class="text-end">IRR(%)</th>
                <th class="text-end">CO2(T)</th>
                <th class="text-end">CO2/1k€(T)</th>
                <th class="text-end">MWHe</th>
                <th class="text-end">MWHt</th>
                <th class="text-end">Payback</th>

              </tr>
              </thead>
              <tbody>
                <tr v-for="opportunity in keywordFilteredList" :key="opportunity.id" @click="goToOpportunityView(opportunity.id.toString())">
                  <td class="text-start field-number">{{getSiteNames()[opportunity.site_code__site_code]}}</td>
                  <td class="text-start field-number">{{opportunity.seu}}</td>
                  <td class="text-start field-description">
                  <img v-if="!opportunity.proposed_by.includes('em3')"  :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'" class="img responsive img-icon">
                  {{opportunity.description | truncatechars(240)}}
                  </td>
                  <td class="text-start field-number">{{opportunity.status}}</td>
                  <td class="text-start field-text">{{opportunity.climate_action_phase}}</td>
                  <td class="text-end field-number">{{Math.round(opportunity.savings_eur).toLocaleString()}}</td>
                  <td class="text-end field-number">{{Math.round(opportunity.total_investment_eur).toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.irr_pct.toFixed(3)}}</td>
                  <td class="text-end field-number">{{Math.round(opportunity.co2_tonnes).toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.carbon_per_euro}}</td>
                  <td class="text-end field-number">{{Math.round(opportunity.mwhe).toLocaleString()}}</td>
                  <td class="text-end field-number">{{Math.round(opportunity.mwht).toLocaleString()}}</td>
                  <td class="text-end field-number">{{opportunity.payback_yrs}}</td>

                </tr>
              </tbody>
            </table>
            <div v-if="getArchivedOpportunities().length > 0" class="border-line"></div>
          </div>
        </div>

        <div v-if="resultCount && keywordFilteredList.length > 0" class="row plain-element">
          <button v-show="next" @click="submitQuery" class="btn btn-loading">
            Load More
          </button>
        </div>
        <div v-if="resultCount && keywordFilteredList.length === 0" class="row plain-element">
          <h6>No results match search criteria</h6>
        </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import { sortTableCurrencyInt, sortTableNew } from "@/common/tables.js";
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "ArchivedOpportunityStandardSearch",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      active: "opportunities",
      siteList: null,
      searchQuery: null,
      site: "",
      seu: "",
      status: "",
      savingsSum: null,
      co2TonnesSum: null,
      capitalInvestmentSum: null,
    }
  },

  methods: {
    ...mapGetters(["getFormError","getNext","getFormError", "getResultCount", "getLoader",
                  "getSavingsSum", "getCo2TonnesSum", "getCapitalInvestmentSum", "getSiteNames", "getArchivedOpportunities",
                  "getOpportunities","getUsername", "isLoggedIn", "getNestedSitesAndSeu", "getCompanyString" ]),
    ...mapActions(["fetchOpportunitiesQueryset", "fetchOpportunityData", "performUpdateCalculations",
                    "clearOpportunityList", "performSetFormError",
                    "fetchAllOpportunities","fetchNestedSitesAndSeu"]),
    sortTableNew: sortTableNew,
    sortTableCurrencyInt: sortTableCurrencyInt,
    getFullOpportunityList() {
      this.fetchAllOpportunities();
    },
    clearSeu() {
        if (this.site === "") {
          this.seu = ""
          this.search = ""
          this.status = ""
        } else {
          this.seu = ""
          this.search = ""
          this.status = ""
        }
        this.updateCalculations()

    },
    clearSearch() {
       this.search = "";
       this.updateCalculations();
    },
    updateCalculations() {
      this.savingsSum = (this.keywordFilteredList.map(a => a.savings_eur)).reduce((a, b) => a + b, 0);
      this.co2TonnesSum = (this.keywordFilteredList.map(a => a.co2_tonnes)).reduce((a, b) => a + b, 0);
      this.capitalInvestmentSum = (this.keywordFilteredList.map(a => a.capital_investment_eur)).reduce((a, b) => a + b, 0);
      this.performUpdateCalculations({"savingsSum": this.savingsSum, "co2TonnesSum" :this.co2TonnesSum, "capitalInvestmentSum": this.capitalInvestmentSum})
    },
    callback: function (ins) {
        ins.update(ins.endVal + 100)
    },
    goToOpportunityView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
//        this.$router.push({ name: 'opportunity-view', params: { id: id } });
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      }
    },
  },
  computed: {
    seuString() {
      if (this.seu) {
        return this.seu
      } else {
        return "None"
      }
    },
    searchString() {
      if (this.search) {
        return this.search
      } else {
        return "None"
      }
    },
    filteredList() {
        if (this.seu !== ""  && this.site !== "" && this.status === "active" ) {
            console.log("1")
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.seu.toLowerCase().includes(this.seu.toLowerCase()) &&
                      opportunity.status.toLowerCase() !== "rejected"
            })
        } else if (this.seu !== ""  && this.site !== "" && this.status === "rejected" ) {
            console.log("2")
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.seu.toLowerCase().includes(this.seu.toLowerCase()) &&
                      opportunity.status.toLowerCase().includes("rejected")
            })
        } else if (this.site !== ""  && this.seu === "" && this.status === "") {
            console.log("3")
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase()
            })
        } else if (this.site !== ""  && this.seu !== "" && this.status === "") {
            console.log("4")
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                     opportunity.seu.toLowerCase().includes(this.seu.toLowerCase())
            })
        } else if (this.site !== "" && this.seu === "" && this.status === "active") {
            console.log("5")
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.status.toLowerCase() !== "rejected"
            })
        }  else if (this.site !== "" && this.seu === "" && this.status === "rejected") {
            console.log("6")
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.status.toLowerCase().includes("rejected")
            })
        } else if (this.site === "" && this.seu === "" && this.status === "active") {
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.status.toLowerCase() !== "rejected"
            })
        } else if (this.site === "" && this.seu === "" && this.status === "rejected") {
            return this.getArchivedOpportunities().filter(opportunity => {
              return opportunity.status.toLowerCase().includes("rejected")
            })
        } else {
          console.log("9")
          return this.getArchivedOpportunities()
        }
    },

    keywordFilteredList() {
      return this.filteredList.filter(opportunity => {
        return opportunity.site_code__site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               opportunity.seu.toLowerCase().includes(this.search.toLowerCase()) ||
               opportunity.description.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {
    this.fetchNestedSitesAndSeu();
    this.performSetFormError(null);
    this.getFullOpportunityList();
    document.title = "Opportunity Search";
    window.scrollTo(0,0);
  },
  mounted() {

  },
  beforeCreated() {

  }
}
</script>
