<template>
  <div class="card card-site-dashboard card-site-dashboard-mid">
    <div class="row plain-element">
      <div class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
        <div class="event-container">
          <div class="row-day text-center">
            {{ today }}
          </div>
          <div class="row-upcoming-event text-center">
            No Upcoming Event
          </div>
        </div>
      </div>
      <div class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CalendarCardComponent",
  props: {
  },
  data() {
    return {
      attrs: [
        {
          key: 'today',
          highlight: 'teal',
          dates: new Date(),
        },
      ],
      weekdays: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
    }
  },
  methods: {
  },
  computed: {
    today() {
        var d = new Date();
        return this.weekdays[d.getDay()]
    }
  },
  filters: {

  },
  created() {

  },
}
</script>