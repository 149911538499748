<template>
  <div v-if="current_name" class="row plain-element">
    <div>
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png'"
                   class="img responsive img-icon">
            </div>
            <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
              <h3 class="font-dark-grey">Delete {{ getCorporatePlanData().name }}</h3>
            </div>
            <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
              <a  :href="'/api/corporate-plan-delete/' + parseInt(id) + '/'" class="btn btn-report" id="btn-light-red">
                Delete
              </a>
            </div>
          </div>
        </div>
        <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
                <div class="row plain-element text-start">
                    <h6 class="font-dark-red">• The Plan will be deleted</h6>


                </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";


export default {
  name: "CorporatePlanDelete",
  props: {
    id: { type: Number, required: true },
    current_name: { type: String, required: false },
  },
  data() {
    return {
      name: this.current_name,
    }
  },

  methods: {
    ...mapGetters(["getCorporatePlanData", "getUsername", "getCompanyString"]),
    ...mapActions(["performCorporatePlanDelete", "performSetFormError"]),
    deleteCorporatePlan() {
      this.performCorporatePlanDelete({
                               "id": this.id,
                              })
      }
    },
  computed: {
  },
  created() {

  }
}
</script>