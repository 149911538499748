<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
  <div v-if="getUsername() && getOpportunityData()" class="dashboard-site">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link  :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link  :to="{name: 'opportunity-search'}" class="horizontal-link">Opportunity Search</router-link>
        </li>
      </ul>
    </div>
    <div class="card card-details">
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img  alt="img"  :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'" class="img responsive img-icon">
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element text-start">
              <h3>{{ getOpportunityData().opportunity_number }}</h3>
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element col-buttons">
              <a target="_blank" :href="'/api/opportunity-details-download/' +  getOpportunityId() + '/'" class="btn btn-report">
                  <i class="fas fa-file-download"></i> Download
              </a>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
              <div  v-if="getOpportunityData().energy_audit === true" class="row plain-element text-start">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <label class="energy-audit">
                      <input class="filled-in font-red" type="checkbox" checked disabled>
                      <span class="label-checkbox">Energy Audit</span>
                    </label>
                    <p class="w-100"></p>
                  </div>
                </div>
              </div>
              <div class="row plain-element text-start"><h6>Site: </h6>
                <p>
                  {{ getOpportunityData().site_code.site_code }} - {{ getSiteNames()[getOpportunityData().site_code.site_code][0]}}
                </p>
              </div>
              <div class="row plain-element text-start"><h6>SEU: </h6>
                <p>
                  {{ getOpportunityData().seu }}
                </p>
              </div>
              <div class="row plain-element text-start"><h6>Area: </h6>
                <p>
                  {{ getOpportunityData().area }}
                </p>
              </div>
              <div class="row plain-element text-start"><h6>Climate Action Phase: </h6>
                <p>
                  {{ getOpportunityData().climate_action_phase }}
                </p>
              </div>
              <div class="row plain-element text-start"><h6>Created: </h6>
                <p>{{ getOpportunityData().date }}</p>
              </div>
              <div v-if="getOpportunityData().length_estimate > 0" class="row plain-element text-start"><h6>Project Length Estimate: </h6>
                <p>{{ getOpportunityData().length_estimate }}</p>
              </div>
              <div v-if="getOpportunityStatusDetails()" class="row plain-element text-start">
                <div v-if="getOpportunityData().status !== 'approved' && getOpportunityData().status !== 'in progress'" class="row plain-element text-start">
                  <h6>Status: </h6>
                  <p>{{ getOpportunityData().status }}</p>
                </div>
              </div>
              <div class="row plain-element text-start"><h6>Proposed by: </h6>
                <p>{{ getOpportunityData().proposed_by }}</p>
              </div>
              <div class="row plain-element text-start">
                <h6 class="">
                    Opportunity Link:
                </h6>
                <div v-if="getOpportunityData().link" class="row plain-element text-start">
                    <p v-if="getOpportunityData().link.length > 1">
                      <a :href="getOpportunityData().link" target="_blank"> Opportunity Link </a>
                    </p>
                    <p v-else>
                      -
                    </p>
                </div>
                <div v-else class="row plain-element text-start">
                    <p>
                         -
                    </p>
                </div>
              </div>
              <div class="row plain-element text-start">
                <h6 class="">
                    Proposal PDF:
                </h6>
                <div v-if="getOpportunityData().proposal_pdf" class="row plain-element text-start">
                    <p v-if="getOpportunityData().proposal_pdf.length > 1">
                      <a :href="getOpportunityData().proposal_pdf" target="_blank"> Proposal PDF </a>
                    </p>
                    <p v-else>
                      -
                    </p>
                </div>
                <div v-else class="row plain-element text-start">
                    <p>
                         -
                    </p>
                </div>
              </div>

              <div v-if="getOpportunityData().opportunity_predecessors.length > 0" class="row plain-element text-start"><h6>Predecessors: </h6>
                <router-link v-for="(element, index) in  getOpportunityData().opportunity_predecessors" class="cross-nav" :key="index"
                            :to="{ name: 'opportunity-view', params: {id: element.predecessor__id.toString() }}">
                  {{ element.predecessor__opportunity_number }}
                </router-link>
              </div>
              <div v-if="getOpportunityData().opportunity_conflicts.length > 0" class="row plain-element text-start"><h6>Conflicts: </h6>
                <router-link v-for="(element, index) in  getOpportunityData().opportunity_conflicts" class="cross-nav" :key="index"
                            :to="{ name: 'opportunity-view', params: {id: element.conflict__id.toString() }}">
                  {{ element.conflict__opportunity_number }}
                </router-link>
              </div>
              <div class="row plain-element opportunity-description text-start"><h6>Description: </h6>
                <p>{{ getOpportunityData().description }}</p>
              </div>
              <div v-if="getOpportunityData().opportunity_action_relation_links.length > 0" class="row plain-element opportunity-description text-start"><h6>Related Actions: </h6>
                <a class="cross-nav cursor-pointer" v-for="element in getOpportunityData().opportunity_action_relation_links" :key="element.action__id"  @click="goToActionView(element.action__id.toString())">
                    {{ element.action__action_number }}
                </a>
              </div>
            </div>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
              <div v-if="getOpportunityStatusDetails()" class="row plain-element">
                <div v-if="getOpportunityData().status === 'approved' || getOpportunityData().status === 'in progress'" class="row plain-element">
                   <div class="row plain-element text-start"><h6>Status: </h6>
                     <p>{{ getOpportunityData().status }}</p>
                   </div>
                   <div class="row plain-element text-start"><h6>Detailed Description: </h6>
                    <p>
                      {{ getOpportunityStatusDetails().description }}
                    </p>
                  </div>
                  <div class="row plain-element text-start"><h6>Completion Date: </h6>
                    <p>
                      {{ getOpportunityStatusDetails().completion_date }}
                    </p>
                  </div>
                  <div class="row plain-element text-start"><h6>Responsible Client: </h6>
                    <p>
                      {{ getOpportunityStatusDetails().client }}
                    </p>
                  </div>
                   <div class="row plain-element text-start"><h6>Verification Method: </h6>
                    <p>
                      {{ getOpportunityStatusDetails().verification_method }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="getOpportunityComments()" class="row plain-element">
                <div v-if="getOpportunityComments().length > 0" class="row plain-element text-start"><h6>Customer Comments: </h6></div>
                <div v-for="element in getOpportunityComments()" :key="element.id" class="row plain-element text-start">
                <p>{{element.entry_date.slice(0, 10)}} - {{ element.author }}</p>
                <p><span class="font-midnight">{{ element.comment }}</span> </p>
                </div>
              </div>
              <div v-if="getLoader() === true" class="row">
                  <div class="loader" id="loader"></div>
              </div>
            </div>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
                <div class="row plain-element text-start">
                  <h6>Energy Metrics: </h6>
                  <table  class="table-metrics">
                    <tr>
                      <td>MWHe:</td>
                      <td class="text-end"><b> {{ getOpportunityData().mwhe }} </b></td>
                    </tr>
                    <tr>
                      <td>MWHt:</td>
                      <td class="text-end"><b> {{ getOpportunityData().mwht }} </b></td>
                    </tr>
                    <tr>
                      <td>Water (m³):</td>
                      <td class="text-end"><b> {{ getOpportunityData().water_m3 }} </b></td>
                    </tr>

                    <tr>
                      <td>CO2 (Tonnes):</td>
                      <td class="text-end"><b> {{ getOpportunityData().co2_tonnes }} </b></td>
                    </tr>
                  </table>
                </div>
                <p class="w-100"></p>
                <div class="row plain-element text-start">
                  <h6>Financial Metrics: </h6>
                  <table  class="table-metrics">
                    <tr>
                      <td>IRR(%):</td>
                      <td class="text-end"><b class="font-green"> {{ Math.round(getOpportunityData().irr_pct * 10000) / 100 }} </b></td>
                    </tr>
                    <tr>
                      <td>Grant ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().grant_localised }} </b></td>
                    </tr>
                    <tr>
                      <td>Savings ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().savings_localised }} </b></td>
                    </tr>
                    <tr>
                      <td>Total Investment ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().total_investment_localised }} </b></td>
                    </tr>
                    <tr>
                      <td>Capital Investment ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().capital_investment_localised }} </b></td>
                    </tr>
                    <tr>
                      <td>Expenses ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().expenses_localised }} </b></td>
                    </tr>
                    <tr>
                      <td>NPV ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().npv_localised }} </b></td>
                    </tr>
                    <tr>
                      <td>Payback (Y):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().payback_yrs }} </b></td>
                    </tr>
                    <tr v-if="getOpportunityData().opportunity_eeos_payment">
                      <td>EEOS Payment ({{getOpportunityData().site_code.currency}}):</td>
                      <td class="text-end"><b class="font-green"> {{ getOpportunityData().opportunity_eeos_payment }} </b></td>
                    </tr>
                  </table>
                </div>
                <p class="w-100"></p>
                <div class="row plain-element text-start">
                  <h6>Electricity & Fuel: </h6>
                  <table  class="table-metrics">
                    <tr>
                      <td>Electricity Type</td>
                      <td class="text-end"><b> {{ getOpportunityData().elec_type }} </b></td>
                    </tr>
                    <tr>
                      <td>Electricity CO2 Rate</td>
                      <td class="text-end"><b> {{ getOpportunityData().elec_co2_rate }} </b></td>
                    </tr>
                    <tr>
                      <td>Fuel Type</td>
                      <td class="text-end"><b> {{ getOpportunityData().fuel_type }} </b></td>
                    </tr>
                    <tr>
                      <td>Fuel CO2 Rate</td>
                      <td class="text-end"><b> {{ getOpportunityData().fuel_co2_rate }} </b></td>
                    </tr>
                  </table>
                </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 plain-element text-end">
              <div v-if="getOpportunityData().opportunity_log" class="row plain-element opportunity-description text-start"><h6>Event Log: </h6>
                <table  class="table-log">
                    <tr v-for="element in getOpportunityData().opportunity_log.slice(0, 10)" :key="element.entry_date">
                      <td class="text-start">{{ formatDate (element.entry_date) }}</td>
                      <td class="text-start"> {{ element.author }}</td>
                      <td class="text-start"><div class="row plain-element" v-for="(element, index) in splitLogList(element.entry)" :key="index"> {{ element }} </div></td>
                    </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>


import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "OpportunityView",
  props: {
    opportunity_number: {
      type: String,
      required: true
    }
  },
  components: {
    NoPermissionComponent,
  },
  mounted() {

  },
  methods: {
    ...mapGetters(["getOpportunityData", "getUsername", "isLoggedIn", "getOpportunityStatusDetails", "getCompanyString", "getOpportunityComments","getLoader", "getSiteNames", "getOpportunityId"]),
    ...mapActions(["fetchOpportunityData", "performSetFormError", "fetchOpportunityStatusDetails", "performSetLoader", "fetchOpportunityIdData"]),

    getOpportunityDetails(id) {
      this.fetchOpportunityData({"id": id })
    },
    opportunityStatusDetails(id) {
      this.fetchOpportunityStatusDetails({"id": id})
    },
    hasHistory () {
      return window.history?.length > 1
    },
    performFetchOpportunityIdData(opportunity_number) {
      this.fetchOpportunityIdData(opportunity_number)
    },
    formatDate(value) {
      let val = (value).replace('T', ' at ')
      return val.toString().slice(0, -8)
    },
    splitLogList(entry) {
      return entry.split('; ')
    },
    goToActionView(id) {
      if (event.ctrlKey === true) {
//        let routeData = this.$router.resolve({ name: 'action-view', params: { id: id } });
//        window.open(routeData.href, '_blank');
        window.open(`/action-details/${id}`, '_blank');
      } else {
//        this.$router.push({ name: 'action-view', params: { id: id } });
        location.href=(`/action-details/${id}`);
      }
    },

  },
  computed: {
  },
  created() {
    this.performFetchOpportunityIdData(this.opportunity_number);
    this.performSetLoader(true);
    this.performSetFormError(null);
    document.title = "Opportunity Details";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenNumber = to.params.opportunity_number
    this.performFetchOpportunityIdData(this.chosenNumber);
    next();
  }

}
</script>
