import api from "../../api/api.js"

const state = {
  waterfallOpportunities: null,
  investmentWaterfallChartData: null,
  investmentChartData: null,
  carbonWaterfallChartData: null,
  carbonChartData: null,
  carbonPerEuroDict: null,
  carbonPerEuroChartData: null,
  costSavingsWaterfallChartData: null,
  costSavingsChartData: null,
  carbonClimateActionPhaseWaterfallChartData: null,
  financialClimateActionPhaseWaterfallChartData: null,
};


const getters = {
  getWaterfallOpportunities: state => state.waterfallOpportunities,
  getInvestmentChartData: state => state.investmentChartData,
  getInvestmentWaterfallChartData: state => state.investmentWaterfallChartData,
  getCarbonChartData: state => state.carbonChartData,
  getCarbonPerEuroDict: state => state.carbonPerEuroDict,
  getCarbonPerEuroChartData: state => state.carbonPerEuroChartData,
  getCarbonWaterfallChartData: state => state.carbonWaterfallChartData,
  getCostSavingsWaterfallChartData: state => state.costSavingsWaterfallChartData,
  getCostSavingsChartData: state => state.costSavingsChartData,
  getCarbonClimateActionPhaseWaterfallChartData: state => state.carbonClimateActionPhaseWaterfallChartData,
  getFinancialClimateActionPhaseWaterfallChartData: state => state.financialClimateActionPhaseWaterfallChartData,
};


const actions = {
  async fetchWaterfallOpportunities({commit}) {
    commit("setLoader", true);
    const response = await api.waterfallOpportunities();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setWaterfallOpportunities", response.data);

      }

    commit("setLoader", false);

  },
  async performUpdateCharts({commit}, payload) {
    const response = await api.updateWaterfallCharts(payload);
      commit("setLoader", false);
//      Investment Waterfall chart
      var investmentWaterfallChartDataset = {
                labels: response.total_investment_chart_data[0],
                datasets: [{
                              backgroundColor: response.total_investment_chart_data[2],
                              data: response.total_investment_chart_data[1],
                              tooltips: response.total_investment_chart_data[4],
                              currencies: response.total_investment_chart_data[5],
                          }]
      };
      commit("setInvestmentWaterfallChartData", investmentWaterfallChartDataset);

//      Carbon Chart
      var carbonChartDataset = {
                labels: response.total_carbon_chart_data[0],
                datasets: [{
                              backgroundColor: response.total_carbon_chart_data[2],
                              data: response.total_carbon_chart_data[1],
                              tooltips: response.total_carbon_chart_data[5],
                          }]
      }

      commit("setCarbonWaterfallChartData", carbonChartDataset);
      var costSavingsChartDataset = {
                labels: response.total_cost_chart_data[0],
                datasets: [{
                              backgroundColor: response.total_cost_chart_data[2],
                              data: response.total_cost_chart_data[1],
                              tooltips: response.total_cost_chart_data[5],
                              currencies: response.total_cost_chart_data[6],
                          }]
      }
      commit("setCostSavingsWaterfallChartData", costSavingsChartDataset);
  },
};


const mutations = {

  setWaterfallOpportunities: (state, waterfallOpportunities) => {
    state.waterfallOpportunities = waterfallOpportunities
  },
  setInvestmentChartData: (state, investmentChartData) => {
     state.investmentChartData = investmentChartData
  },
  setInvestmentWaterfallChartData: (state, investmentWaterfallChartData) => {
     state.investmentWaterfallChartData = investmentWaterfallChartData
  },
  setCarbonClimateActionPhaseWaterfallChartData: (state, carbonClimateActionPhaseWaterfallChartData) => {
     state.carbonClimateActionPhaseWaterfallChartData = carbonClimateActionPhaseWaterfallChartData
  },
  setFinancialClimateActionPhaseWaterfallChartData: (state, financialClimateActionPhaseWaterfallChartData) => {
     state.financialClimateActionPhaseWaterfallChartData = financialClimateActionPhaseWaterfallChartData
  },
  setCarbonChartData: (state, carbonChartData) => {
     state.carbonChartData = carbonChartData
  },
  setCarbonPerEuroDict: (state, carbonPerEuroDict) => {
    state.carbonPerEuroDict = carbonPerEuroDict
  },
  setCarbonWaterfallChartData: (state, carbonWaterfallChartData) => {
     state.carbonWaterfallChartData = carbonWaterfallChartData
  },
  setCostSavingsWaterfallChartData: (state, costSavingsWaterfallChartData) => {
     state.costSavingsWaterfallChartData = costSavingsWaterfallChartData
  },
  setCostSavingsChartData: (state, costSavingsChartData) => {
     state.costSavingsChartData = costSavingsChartData
  },
  setCarbonPerEuroChartData: (state, carbonPerEuroChartData) => {
     state.carbonPerEuroChartData = carbonPerEuroChartData
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}