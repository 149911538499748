<template>
    <div class="row plain-element">
        <div v-if="!isLoggedIn()" class="dashboard-cards">
            <NoPermissionComponent/>
        </div>
        <div v-if="getUsername()" class="dashboard">
            <div class="row plain-element top-menu">
                <ul>
                    <li>
                        <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'site-plan-search'}" class="horizontal-link">Site Plans
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'site-plan-create'}" class="horizontal-link underline-green">Create Site Plan</router-link>
                    </li>
                </ul>
            </div>
            <div class="card card-details">
                <p class="error" v-if="getFormError()">
                    <span id="formError">{{ getFormError() }}</span>
                </p>
                <p class="error" v-else>
                </p>
                <form @submit.prevent="createPlan()" enctype="multipart/form-data">
                    <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                            <div class="row row-functions text-start">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                                    <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                                         class="img responsive img-icon">
                                </div>
                                <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                                    <h3 class="font-dark-grey">Create Site Plan</h3>
                                </div>
                                <div  v-if="getLoader() === false" class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                                    <button type="submit" class="btn btn-report">
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="getLoader() === true" class="row">
                        <div class="loader" id="loader"></div>
                    </div>
                    <div v-if="getNestedCompanySites() && getLoader() === false" class="row plain-element text-start">
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
                            <div class="row plain-element opportunity-description text-start">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                    <div class="row text-start">
                                        <h6 class="font-dark-grey">Company<span class="font-14">*</span></h6>
                                        <div class=" col col-12 col-sm-12 col-md-11 col-lg-8 plain-element">
                                            <div class="input-field  col col-12 col-sm-12 col-form">
                                                <select v-model="company" name="company" id="company" required>
                                                    <option v-for="(sites, company) in getNestedCompanySites()" :value="company" :key="company">
                                                        {{ company }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row plain-element opportunity-description text-start">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                    <div v-if="getPermittedSites()" class="row text-start">
                                        <h6 class="font-dark-grey">Site<span class="font-14">*</span></h6>
                                        <div class=" col col-12 col-sm-12 col-md-11 col-lg-8 plain-element">
                                            <div class="input-field  col col-12 col-sm-12 col-form">
                                                <select v-model="site" name="site" id="site" required>
                                                    <option v-for="(site, index) in getNestedCompanySites()[company]" :value="site" :key="index">
                                                        {{ site }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row plain-element opportunity-description text-start">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                    <div v-if="getPermittedSites()" class="row text-start">
                                        <h6 class="font-dark-grey">Currency<span class="font-14">*</span></h6>
                                        <div class=" col col-12 col-sm-12 col-md-11 col-lg-8 plain-element">
                                            <div class="input-field  col col-12 col-sm-12 col-form">
                                                <select v-model="currency" name="currency" id="currency" required>
                                                    <option v-for="(element, index) in getCurrencies()" :value="element" :key="index">
                                                        {{ element }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row plain-element opportunity-description text-start">
                                <h6 class="font-dark-grey"> Name<span class="font-14">*</span></h6>
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                    <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="2" cols="40" name="name" v-model="name" required>

                    </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row plain-element opportunity-description text-start">
                                <h6 class="font-dark-grey">
                                    Description:

                                </h6>
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                                    <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="5" cols="40" name="description" v-model="description">

                    </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-8">
                            <div class="row plain-element">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-dark-grey">
                                            Cost Baseline
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                                {{ currency }}
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                                <input id="cost_baseline" type="number" name="cost_baseline" v-model="cost_baseline" class="validate" min="0" max="1000000000"
                                                       step="1" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="w-100"> </p>
                            <div class="row plain-element">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-cyan-blue">
                                            Prev. Year Annual CO2 Electricity Emission
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                                Tonnes:
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                                <input id="co2_electricity_tonnes_previous_year" type="number" name="co2_electricity_tonnes_previous_year" v-model="co2_electricity_tonnes_previous_year" class="validate"
                                                       min="-1000000000" max="1000000000"
                                                       step="01" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-cyan-blue">
                                            Electricity CO2 Reduction Year
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-4 ">
                                                Year:
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                                                <select v-model="co2_electricity_reductions_year" name="co2_electricity_reductions_year" id="co2_electricity_reductions_year"
                                                        required>
                                                    <option v-for="year in years" :value="year" :key="year">
                                                        {{ year }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="w-100"> </p>
                            <div class="row plain-element">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-lime-green">
                                            Prev. Year Annual CO2 Fuel Emission
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                                Tonnes:
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                                <input id="co2_fuel_tonnes_previous_year" type="number" name="co2_fuel_tonnes_previous_year" v-model="co2_fuel_tonnes_previous_year" class="validate" min="-1000000000"
                                                       max="1000000000"
                                                       step="01" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-lime-green">
                                            Fuel CO2 Reduction Year
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-4 ">
                                                Year:
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                                                <select v-model="co2_fuel_reductions_year" name="co2_fuel_reductions_year" id="co2_fuel_reductions_year"
                                                        required>
                                                    <option v-for="year in years" :value="year" :key="year">
                                                        {{ year }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="w-100"> </p>
                            <div class="row plain-element">
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-gold">
                                            Optional CO2 Baseline
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                                Tonnes
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                                <input id="cost_baseline" type="number" name="co2_baseline" v-model="co2_baseline" class="validate" min="0" max="1000000000"
                                                       step="1" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 text-start">
                                    <div class="row plain-element text-start">
                                        <h6 class="font-gold">
                                            Optional CO2 Baseline Year
                                            <span class="font-14">*</span>
                                        </h6>
                                        <div class="row">
                                            <div class="input-field col col-sm-8 col-md-6 col-lg-4 ">
                                                Year:
                                            </div>
                                            <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                                                <select v-model="co2_baseline_year" name="co2_baseline_year" id="co2_baseline_year"
                                                        required>
                                                    <option v-for="year in previous_years" :value="year" :key="year">
                                                        {{ year }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="w-100"> </p>

                        </div>
                    </div>
            </form>
        </div>
    </div>
    </div>
</template>


<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "SitePlanCreate",
  components: {
    NoPermissionComponent,
  },
  data() {
    return {
      company: null,
      site : null,
      name: null,
      description: null,
      co2_electricity_tonnes_previous_year: null,
      co2_fuel_tonnes_previous_year: null,
      currency: null,
      cost_baseline: null,
      co2_baseline: 0,
      co2_baseline_year: "2022",
      co2_electricity_reductions_year: "2024",
      co2_fuel_reductions_year: "2024",
      years: ["2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"],
      previous_years: ["2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"]
    }
  },
  methods: {
    ...mapGetters(["getNestedCompanySites","getSitePlanData","getFormError", "getUsername", "isLoggedIn", "getCompanies", "getCompanyString", "getPermittedSites", "getCurrencies", "getLoader"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError", "fetchPermittedSites", "fetchCompanies", "performCreateSitePlan", "fetchNestedCompanySites"]),
    createPlan() {
      if (this.description === null) {
        this.description = this.name
      }
      this.performCreateSitePlan({
        "company" : this.company,
        "site_code" : this.site,
        "name": this.name,
        "description": this.description,
        "co2_electricity_tonnes_previous_year": this.co2_electricity_tonnes_previous_year,
        "co2_fuel_tonnes_previous_year": this.co2_fuel_tonnes_previous_year,
        "cost_baseline": this.cost_baseline,
        "currency": this.currency,
        "co2_electricity_reductions_year": this.co2_electricity_reductions_year,
        "co2_fuel_reductions_year": this.co2_fuel_reductions_year,
        "co2_baseline": this.co2_baseline,
        "co2_baseline_year": this.co2_baseline_year,
      })
    },
  },

  created() {
    document.title = "Create Site Plan";
    this.fetchPermittedSites();
    this.fetchNestedCompanySites();
    window.scrollTo(0,0);
  }
}


</script>
