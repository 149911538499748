<template>
<div v-if="expenses_eur" class="row plain-element">
  <form @submit.prevent="updateOpportunity()" enctype="multipart/form-data">
    <div class="row plain-element">
      <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
        <div class="row row-functions text-start">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
            <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'" class="img responsive img-icon">
          </div>
          <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
            <h3 class="font-dark-grey">Update {{ getOpportunityData().opportunity_number }} </h3>
          </div>
          <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
            <button type="submit" class="btn btn-report">
              Submit Update
            </button>
          </div>
        </div>
      </div>
      <div v-if="getSitesAndSeu()" class="row plain-element text-start">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-8 col-lg-6 plain-element">
                <div class="row plain-element">
                  <div class="input-field  col col-12 col-sm-12">
                      <label class="energy-audit">
                        <input id="energy_audit" name="energy_audit" v-model="energy_audit" :value="energy_audit" type="checkbox">
                        <span class="label-checkbox font-dark-grey"> Energy Audit </span>
                      </label>
                  </div>
                </div>
              </div>
              <p class="w-100"></p>

            </div>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Update Status:

              </h6>

                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <select v-model="status">
                    <option value="opportunity">opportunity</option>
                    <option value="approved">approved</option>
                    <option value="in progress">in progress</option>
                    <option value="completed">completed</option>
                    <option value="rejected">rejected</option>
                  </select>

                </div>

            </div>
            <p class="w-100"></p>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Update Project Length Estimate:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="1" cols="40" name="length_estimate" v-model="length_estimate">

                    </textarea>
                </div>
              </div>
            </div>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey tooltiped">
                Update Opportunity Link:
                <span class="tooltiptext">Copy link to SharePoint folder containing calculation sheet</span>
              </h6>
              <div class=" col col-12 col-sm-12 col-sm-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-sm-10 col-form">
                  <textarea rows="1" cols="40" name="link" v-model="link">
                  </textarea>
                </div>
              </div>
            </div>

            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Update Description:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="3" cols="40" name="description" v-model="description">

                    </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-8 plain-element">
            <div class="row plain-element">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 plain-element text-end">
                <div class="row plain-element text-start">
                  <h6 class="font-dark-grey">
                    Update Energy Metrics:

                  </h6>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                      MWHE:

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="mwhe" type="number" name="mwhe" v-model="mwhe" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().mwhe">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                      MWHT:

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="mwht" type="number" name="mwht" v-model="mwht" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().mwht">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                      Water (m³):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="water_m3" type="number" name="water_m3" v-model="water_m3" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().water_m3">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                      CO2(Tonnes):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="co2_tonnes" type="number" name="co2_tonnes" v-model="co2_tonnes" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().co2_tonnes">
                    </div>
                  </div>
                </div>
                </div>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 plain-element text-end">
                <div class="row plain-element text-start">
                  <h6 class="font-dark-grey">
                    Financial Metrics:

                  </h6>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                      Grant (EUR):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="grant_eur" type="number" name="grant_eur" v-model="grant_eur" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().grant_eur">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                      Savings (EUR):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="savings_eur" type="number" name="savings_eur" v-model="savings_eur" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().savings_eur">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                      Total Investment (EUR):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="total_investment_eur" type="number" name="total_investment_eur" v-model="total_investment_eur" class="validate" min="-10000000"
                             max="10000000"
                             step=".01" value="getOpportunityData().total_investment_eur">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                      Capital Investment (EUR):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="capital_investment_eur" type="number" name="capital_investment_eur" v-model="capital_investment_eur" class="validate"
                             min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().capital_investment_eur">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                      Expenses (EUR):

                    </div>
                    <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                      <input id="expenses_eur" type="number" name="expenses_eur" v-model="expenses_eur" class="validate" min="-10000000" max="10000000"
                             step=".01" value="getOpportunityData().expenses_eur">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

    </div>
  </form>
</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";


export default {
  name: "UpdateOpportunityComponent",
  props: {
    id: { type: String, required: true },
    current_site_code: { type: Number, required: false },
    current_area: { type: Number, required: false },
    current_length_estimate: { type: String, required: false },
    current_description: { type: String, required: false },
    current_mwhe: { type: String, required: false },
    current_mwht: { type: String, required: false },
    current_water_m3: { type: String, required: false },
    current_irr_pct: { type: String, required: false },
    current_co2_tonnes: { type: String, required: false },
    current_grant_eur: { type: String, required: false },
    current_savings_eur: { type: String, required: false },
    current_total_investment_eur: { type: String, required: false },
    current_capital_investment_eur : { type: String, required: false },
    current_expenses_eur : { type: String, required: false },
    current_energy_audit : {type: Boolean, required: false },
    current_status: { type: String, required: false },
    current_link: { type: String, required: false },
  },
  data() {
    return {
      length_estimate: this.current_length_estimate,
      description: this.current_description,
      mwhe: this.current_mwhe,
      mwht: this.current_mwht,
      water_m3: this.current_water_m3,
      co2_tonnes: this.current_co2_tonnes,
      grant_eur: this.current_grant_eur,
      savings_eur: this.current_savings_eur,
      total_investment_eur: this.current_total_investment_eur,
      capital_investment_eur: this.current_capital_investment_eur,
      expenses_eur: this.current_expenses_eur,
      energy_audit: this.current_energy_audit,
      status: this.current_status,
      link: this.current_link,
    }
  },

  methods: {
    ...mapGetters(["getOpportunityData", "getSitesAndSeu", "getUsername", "getCompanyString"]),
    ...mapActions(["performOpportunityUpdate", "performOpportunityLogEntryCreate"]),

    updateOpportunity() {
      this.performOpportunityUpdate({
                                     "id": this.id,
                                     "length_estimate": this.length_estimate,
                                     "description": this.description,
                                     "mwhe": this.mwhe,
                                     "mwht": this.mwht,
                                     "water_m3": this.water_m3,
                                     "co2_tonnes": this.co2_tonnes,
                                     "grant_eur": this.grant_eur,
                                     "savings_eur": this.savings_eur,
                                     "total_investment_eur": this.total_investment_eur,
                                     "capital_investment_eur" : this.capital_investment_eur,
                                     "expenses_eur" : this.expenses_eur,
                                     "energy_audit" : this.energy_audit,
                                     "recalculate_irr" : this.recalculate_irr,
                                     "entry" : this.findChanges(),
                                     "status": this.status,
                                     "link": this.link,
                                    })
    },
    findChanges() {
      var updateLogEntry = []
      if (this.description !== this.current_description) {
        updateLogEntry.push("Description updated")
      }
      if (this.link !== this.current_link) {
        updateLogEntry.push("Link updated")
      }
      if (this.mwhe !== this.current_mwhe) {
        updateLogEntry.push("MWHE: " + this.current_mwhe + " - " + this.mwhe)
      }
      if (this.mwht !== this.current_mwht) {
        updateLogEntry.push("MWHT: " + this.current_mwht + " - " + this.mwht)
      }
      if (this.water_m3 !== this.current_water_m3) {
        updateLogEntry.push("Water(m3): " + this.current_water_m3 + " - " + this.water_m3)
      }
      if (this.co2_tonnes !== this.current_co2_tonnes) {
        updateLogEntry.push("CO2(Tonnes): " + this.current_co2_tonnes + " - " + this.co2_tonnes)
      }
      if (this.grant_eur !== this.current_grant_eur) {
        updateLogEntry.push("Grant(EUR): " + this.current_grant_eur + " - " + this.grant_eur)
      }
      if (this.savings_eur !== this.current_savings_eur) {
        updateLogEntry.push("Savings(EUR): " + this.current_savings_eur + " - " + this.savings_eur)
      }
      if (this.status !== this.current_status) {
        updateLogEntry.push("Status: " + this.current_status + " - " + this.status)
      }
      if (this.total_investment_eur !== this.current_total_investment_eur) {
        updateLogEntry.push("Total Investment(EUR): " + this.current_total_investment_eur + " - " + this.total_investment_eur)
      }
      if (this.capital_investment_eur !== this.current_capital_investment_eur) {
        updateLogEntry.push("Capital Investment(EUR): " + this.current_capital_investment_eur + " - " + this.capital_investment_eur)
      }
      if (this.expenses_eur !== this.current_expenses_eur) {
        updateLogEntry.push("Expenses(EUR): " + this.current_expenses_eur + " - " + this.expenses_eur)
      }
      if (this.energy_audit !== this.current_energy_audit) {
        updateLogEntry.push("Energy Audit: " + this.current_energy_audit + " - " + this.energy_audit)
      }
      if (updateLogEntry.length === 0) {
        updateLogEntry.push("Opportunity revised")
      }
      return updateLogEntry.join('; ')
    },
  },
  computed: {
  },
  created() {

  }


}
</script>