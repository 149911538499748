<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getAlarmData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link"  href="/alarm-search">
          <i class="fas fa-chevron-left"></i> Back To Alarms
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <UpdateAlarmComponent
              :id = id
              :current_site_code = getAlarmData().site_code
              :current_seu = getAlarmData().seu
              :current_enpi = getAlarmData().enpi
              :current_creator = getAlarmData().creator
              :current_threshold = getAlarmData().threshold
              :current_email = getAlarmData().email
              :current_above = getAlarmData().above
              :current_status = getAlarmData().status
      />
    </div>
  </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import UpdateAlarmComponent from "@/components/alarms/UpdateAlarmComponent.vue";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "AlarmUpdate",
  components: {
    UpdateAlarmComponent,
    NoPermissionComponent,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getFormError", "getUsername", "isLoggedIn","getAlarmData"]),
    ...mapActions(["fetchAlarmData","performSetFormError", "fetchIsoChapters"]),
    getAlarmDetails() {
      this.fetchAlarmData({"id": this.id })


    },
    hasHistory () {
      return window.history?.length > 1
    },

  },
  created() {
    this.getAlarmDetails();
    this.performSetFormError(null);
    document.title = "Update Alarm";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.getAlarmDetails();
    next();
  }
}
</script>
