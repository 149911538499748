
function validateString(s) {
    if (s) {
        if (s.length === 1) {
            return s
        } else {
            if (s.charAt(0) === "=" || s.charAt(0) === "+" || s.charAt(0) === "-" || s.charAt(0) === "@") {
              return "error"
            } else {
              return s
            }
        }
    }
}



export { validateString, };