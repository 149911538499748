<template>
  <div class="row plain-element">
<p class="w-100"></p>
        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Total Investment Breakdown<span>({{currency_details[1]}})</span>:</h6>
            </div>
            <div v-if="investmentWaterfallChartData" class="plan-chart">
              <investment-waterfall-graph :chart-data="investmentWaterfallChartData"></investment-waterfall-graph>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Annual Savings Breakdown<span>({{currency_details[1]}})</span>:</h6>
            </div>
            <div v-if="costSavingsWaterfallChartData" class="plan-chart">
              <cost-savings-waterfall-graph :chart-data="costSavingsWaterfallChartData"></cost-savings-waterfall-graph>
            </div>
          </div>
        </div>
        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-2 col-md-1 col-lg-1 text-start"></div>
          <div class=" col col-12 col-sm-2 col-md-2 col-lg-2 text-start" v-for="key,value in climateActionLegend" :key="value">
            <div class="legend-square" :style="'background-color:' + key"> </div>
            <div id="climate-action-block">
              {{ value }}
            </div>
          </div>
        </div>
        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Investment by Opportunity<span>({{currency_details[1]}})</span>:</h6>
            </div>
            <div v-if="investmentChartData" class="plan-chart">
              <investment-graph :chart-data="investmentChartData"></investment-graph>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Cost Savings by Opportunity<span>({{currency_details[1]}})</span>:</h6>
            </div>
            <div v-if="costSavingsChartData" class="plan-chart">
              <cost-savings-graph :chart-data="costSavingsChartData"></cost-savings-graph>
            </div>
          </div>
        </div>

        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Investment per Climate Action Phase<span>({{currency_details[1]}})</span>:</h6>
            </div>
            <div v-if="financialClimateActionPhaseWaterfallChartData" class="plan-chart">
              <financial-climate-action-phase-waterfall-graph :chart-data="financialClimateActionPhaseWaterfallChartData"></financial-climate-action-phase-waterfall-graph>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Carbon Per Investment<span>(T/1k{{currency_details[1]}})</span>:</h6>
            </div>
            <div v-if="carbonPerEuroChartData" class="plan-chart">
              <carbon-per-euro-graph :chart-data="carbonPerEuroChartData"></carbon-per-euro-graph>
            </div>
          </div>
        </div>
  </div>

</template>

<script>

import InvestmentGraph from "@/common/graphs/InvestmentGraph.js";
import InvestmentWaterfallGraph from "@/common/graphs/InvestmentWaterfallGraph.js";
import CarbonPerEuroGraph from "@/common/graphs/CarbonPerEuroGraph.js";
import CostSavingsWaterfallGraph from "@/common/graphs/CostSavingsWaterfallGraph.js";
import CostSavingsGraph from "@/common/graphs/CostSavingsGraph.js";
import FinancialClimateActionPhaseWaterfallGraph from "@/common/graphs/FinancialClimateActionPhaseWaterfallGraph.js";

export default {
  name: "CorporatePlanClimateGraphsComponent",
  props: {
    opportunity_plan_relations_length: {
      type: Number,
      required: true,
    },
    investmentWaterfallChartData: {
      type: Object,
      required: true,
    },
    costSavingsWaterfallChartData: {
      type: Object,
      required: true,
    },
    investmentChartData: {
      type: Object,
      required: true,
    },
    financialClimateActionPhaseWaterfallChartData: {
      type: Object,
      required: true,
    },
    carbonPerEuroChartData: {
      type: Object,
      required: true,
    },
    costSavingsChartData: {
      type: Object,
      required: true,
    },
    currency_details: {
      type: Array,
      required: true,
    },
  },
  components: {
    InvestmentGraph,
    InvestmentWaterfallGraph,
    CostSavingsWaterfallGraph,
    CostSavingsGraph,
    FinancialClimateActionPhaseWaterfallGraph,
    CarbonPerEuroGraph,
  },
  data() {
    return {
      climateActionLegend: {
        "1 - Low/No Cost": "#ff9d9a",
        "2 - Energy Efficiency": "#86bcb6",
        "3 - Renewable Electricity": "#f1ce63",
        "4 - Electrification of Heat": "#a0cbe8",
        "5 - Renewable Fuels": "#d4a6c8",
      },
    }
  },
  methods: {
    isSumGreaterThanZero(obj) {
      let sum = 0;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          sum += obj[key];
        }
      }
      return sum > 0;
    },
  },

  computed: {


  },
  created() {


  },
}
</script>
