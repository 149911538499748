import { render, staticRenderFns } from "./AddOpportunityCommentComponent.vue?vue&type=template&id=597f04aa&"
import script from "./AddOpportunityCommentComponent.vue?vue&type=script&lang=js&"
export * from "./AddOpportunityCommentComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports