import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  data(){
    return {
      options: {
        indexAxis: 'y',
        plugins : {
            legend: {
                display: false,
                position: "bottom",
                padding: 25,
                labels: {
                    font: {

                    }

                }
            },
            tooltip: {

                callbacks: {
                    title: () => "",
                    label: function(context) {
                        let label = context.dataset.label || '';
                        let label2 = "";
                        let index = (context.dataIndex)
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.x !== null) {
                            label += context.dataset.tooltips[index];
                            label2 += (context.parsed.x).toLocaleString() + context.dataset.currencies[index]
                        }
                        if (context.dataset.tooltips[index] === "") {
                          return [label2];
                        } else {
                          return [label, label2];
                        }

                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
              position: 'left',
              grid: {
                display: true,
                color: "#e4e6e8",

              },
              ticks: {
                maxTicksLimit: 20,
                font: {
                  size: 10
                },
              }
            },
            x: {
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 10
                },
                maxRotation: 0,
                minRotation: 0,
              },

            },

        }

      }
    }
  },
  mounted () {
    this.renderChart(
      this.chartData,
      this.options
      )
  }
}
