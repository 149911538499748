<template>
<form @submit.prevent="updateSiteServices()" enctype="multipart/form-data">
  <div class="row plain-element">
    <div  class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="row row-functions text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
          <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
               class="img responsive img-icon">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
          <h3> Change Site Services for {{ site_code }}</h3>
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element col-buttons">
          <button type="submit" class="btn btn-report">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <p class="w-100"></p>
  <div class="row plain-element text-start">
    <div class="col col-12 col-sm-12 col-md-6 col-lg-12 text-start">
      <div class="row plain-element">
        <div v-if="getSiteServices()" class="row plain-element">
          <div class="input-field  col col-12 col-sm-12 col-md-2 col-form">
            <p v-for="(value, service) in getSiteServices()" :key="service">
                <label>
                  <input
                    type="checkbox"
                    v-model="newSiteServices[service]"
                    :value="service"
                  />
                  <span>{{ service }}</span>
                </label>
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>
</template>

<script>

import { mapGetters, mapActions } from "vuex";


export default {
  name: "ChangeSiteServicesComponent",
  props: {
    site_services: { type: Object, required: true },
    site_code: { type: String, required: true },
  },
  data() {
    return {
        newSiteServices: this.site_services,
      }
  },

  methods: {
    ...mapGetters(["getProfile", "getCompanyString", "getUsername", "getSiteServices"]),
    ...mapActions(["performSetFormError", "performUpdateSiteServices"]),
    updateSiteServices() {
      this.performUpdateSiteServices(
        { "site_services": this.newSiteServices,
          "site_code": this.site_code
        }
      )
    },

  },
  computed: {
  },

  beforeRouteUpdate (to, from, next) {
    next();
  }
}
</script>


