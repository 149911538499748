<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getNestedSitesAndAlarms()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'alarm-search'}" class="horizontal-link underline-green">Meter Alarms
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'alarm-create'}" class="horizontal-link">Create Alarm
          </router-link>
        </li>
      </ul>
    </div>

    <div class="row plain-element">
      <div class="row row-opportunities">
        <div class="row plain-element">
          <div v-if="getNestedSitesAndAlarms()" class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site Code</label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearProjects()">
                <option value="" selected> All Sites</option>
                <option v-for="(key, value, index) in getNestedSitesAndAlarms()" :key="index">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
          <div  class=" col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">

          </div>

        </div>
        <p class="w-100"></p>
        <div v-if="getUsername() && getLoader() == true" class="row">
          <div class="loader" id="loader"></div>
        </div>
        <div v-if="getAlarmList()  && getLoader() == false" class="row plain-element">
          <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
            <h6> {{ filteredList.length }} Results</h6>
          </div>
        </div>
        <p class="w-100"></p>
        <div  v-if="getAlarmList() && getLoader() == false" class="row plain-element">
          <div v-if="getAlarmList().length > 0" id="parent">
            <div id="child">
              <table name="text" id="searchTable">

                <thead>
                <tr>
                  <th class="text-start">Site Code
                  </th>
                  <th class="text-start">SEU
                  </th>
                  <th class="text-start">EnPI
                  </th>
                  <th class="text-start">Creator
                  </th>
                  <th class="text-start">Threshold
                  </th>
                  <th class="text-start">Email To
                  </th>
                  <th class="text-start">On/Off
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="alarm in filteredList" :key="alarm.id" @click="goToAlarmUpdateView(alarm.id.toString())">
                  <td class="text-start field-number">{{alarm.site_code}}</td>
                  <td class="text-start field-text" >{{alarm.seu}}</td>
                  <td class="text-start field-text" >{{alarm.enpi}}</td>
                  <td class="text-start field-text" >{{alarm.creator}}</td>
                  <td class="text-start field-text" >
                  <span v-if="alarm.above == true"> Above</span>
                  <span v-else> Below</span>
                  {{alarm.threshold}}
                  </td>
                  <td class="text-start field-text" >{{alarm.email}}</td>
                  <td class="text-start field-text" ><span v-if="alarm.status === true" class="font-green"> On</span><span v-else> Off</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            No alarm present
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { sortTableCurrencyInt, sortTableNew } from "@/common/tables.js";
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "AlarmStandardSearch",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      siteList: null,
      searchQuery: null,
      site: "",
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getNestedSitesAndAlarms", "isLoggedIn", "getLoader", "getResultCount", "getAlarmList", "getIsoChapters" ]),
    ...mapActions([ "performSetFormError", "fetchAllAlarms", "fetchNestedSitesAndAlarms", "performSetFormError", "fetchIsoChapters", "clearAlarmData"]),
    sortTableNew: sortTableNew,
    sortTableCurrencyInt: sortTableCurrencyInt,
    getFullAlarmList() {
      this.fetchAllAlarms();
    },
    hasHistory () {
      return window.history?.length > 1
    },
    clearProjects() {
        if (this.site == "") {
          this.project_number = ""
          this.status = ""
          this.search = ""
        } else {
          this.project_number = ""
          this.status = ""
          this.search = ""
        }
    },
    clearSearch() {
       this.search = "";
    },
//    TABLE_SORTING FUNCTIONS

    goToAlarmUpdateView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'alarm-update', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'alarm-update', params: { id: id } });
//        let routeData = this.$router.resolve({ name: 'pdf-report-update', params: { id: id } });
//        window.open(routeData.href, '_blank');
      }
    },
  },
  computed: {
    filteredList() {
        if (this.project_number != ""  && this.site != "") {
            return this.getAlarmList().filter(report => {
              return report.project_number.toLowerCase().includes(this.project_number.toLowerCase())
            })
        } else if (this.site != ""  && this.project_number == "") {
            return this.getAlarmList().filter(report => {
              return report.site_code.toLowerCase() == this.site.toLowerCase()
            })
        } else {
          return this.getAlarmList()
        }
    },
  },
  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit) + "...";
        }
        return value
      }
    },
    normalize(value) {
      if (value) {
        value = value.split('_').splice(3, value.length).join(" ").replaceAll(".pdf", "")
//        value = value.replaceAll("_", " ").replaceAll(".pdf", "")
      }
      return value
    }
  },
  created() {
    this.fetchNestedSitesAndAlarms();
    this.performSetFormError(null);
    this.getFullAlarmList();
    this.clearAlarmData();
    document.title = "Alarm Search";
    window.scrollTo(0,0);
  },

}
</script>

