<template>
<form @submit.prevent="updateSitePlanList()" enctype="multipart/form-data">
  <div v-if="getCorporateSitePlans()" class="row plain-element">
    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="row row-functions text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
          <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
               class="img responsive img-icon">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
          <h3> Attach/Remove Site Plan for {{ name }} </h3>
        </div>
        <div  v-if="getLoader() === false" class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element col-buttons">

          <button type="submit" class="btn btn-report">
            Update Site Plans
          </button>
        </div>
      </div>
    </div>
  </div>
    <div class="row plain-element">

        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-5 col-lg-3 plain-element text-end">
            <div v-if="getCorporateSitePlans()" class="input-field  col col-12 col-sm-12 col-form">
              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
          <div v-if="getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
          </div>
          <div v-if="getCorporateSitePlans()  && getLoader() === false" class="row plain-element">
            <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
              <h6 class="font-cyan-blue font-12"> {{ keywordFilteredList.length }} Site Plans</h6>
            </div>
            <div class="col col-sm-6 col-md-6 col-lg-6 plain-element">
            </div>
          </div>
          <p class="w-100"></p>
          <div id="parent">
            <div id="child">
              <table v-if="getCorporateSitePlans() && getLoader() === false" id="searchTable">
                <thead v-if="keywordFilteredList.length > 0">
                <tr>
                  <th class="text-center th-description">Attach</th>
                  <th class="text-center" @click="sortTableNew(1)">Site Code
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>
                  <th class="text-center" @click="sortTableNew(2)">Name
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>

                  <th class="text-center th-description">Description</th>
                  <th class="text-center" @click="sortTableNew(4)">Plan Manager
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="plan in keywordFilteredList" :key=plan.id @click="checkBox(plan.name)">
                    <td class="text-center field-number">
                      <label v-if="newSitePlansArray.includes(plan.name)" @click="checkBox(plan.name)">
                        <input type="checkbox" v-model="newSitePlansArray" :value="plan.name" :id="plan.name" checked="checked"/>
                      </label>
                      <label v-else @click="checkBox(plan.name)">
                        <input type="checkbox" v-model="newSitePlansArray" :value="plan.name" :id="plan.name"/>
                      </label>
                    </td>
                    <td class="text-center field-number">{{plan.site_code}}</td>
                    <td class="text-center field-description">{{plan.name}}</td>
                    <td class="text-center field-description">{{truncatechars(plan.description, 240) }}</td>
                    <td class="text-center field-description">{{ plan.plan_manager }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="getCorporateSitePlans().length > 0" class="border-line"></div>
            </div>
          </div>
          <div v-if="resultCount && keywordFilteredList.length === 0" class="row plain-element">
            <h6>No results match search criteria</h6>
          </div>
        </div>
        <div v-if="getCorporateSitePlans().length === 0" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <p class="w-100"></p>
          <span class="span-info"> No Site Plans available</span>
        </div>
    </div>
</form>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { sortTableNew } from "@/common/tables.js";
import { truncatechars } from "@/common/general.js";

export default {
  name: "AttachRemoveCorporateSitePlanComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: true,
    },
    sitePlansArray: {
      type: Array,
      required: true,
    }


  },
  components: {

  },
  data() {
    return {
      newSitePlansArray: this.sitePlansArray,
      seu: "",
      resultCount: null,
      search: "",
    }
  },
  methods: {
    ...mapGetters(["getCorporateSitePlans",  "getCompanyString", "getResultCount", "getLoader", "getNestedSitesAndSeu", "getCompanySites"]),
    ...mapActions(["performSetFormError", "performCreateCorporateSitePlanRelation", "fetchCorporateSitePlansQueryset",
                  "fetchPlansSites","fetchNestedSitesAndSeu", "fetchCompanySites"]),
    truncatechars: truncatechars,
    sortTableNew: sortTableNew,
    getSitePlanList() {
      this.fetchCorporateSitePlansQueryset({"id": this.id, "company": this.company});
    },
    updateSitePlanList() {
        this.performCreateCorporateSitePlanRelation({"id": this.id,  "site_plan_array" : this.newSitePlansArray})
    },
    checkBox(sitePlan) {
        {
        var box = document.getElementById(sitePlan);
        box.click();
        }
    },

    //  Just in case if some strings would be too long and would destroy a layout

    clearSearch() {
       this.search === "";
    },

  },
  
  computed: {
    filteredList() {
          return this.getCorporateSitePlans()
    },
      keywordFilteredList() {
      return this.filteredList.filter(sitPlan => {
        return sitPlan.site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               sitPlan.name.toLowerCase().includes(this.search.toLowerCase()) ||
               sitPlan.description.toLowerCase().includes(this.search.toLowerCase())
      })
    }

  },
  created() {
    this.getSitePlanList();
    this.performSetFormError(null);
    this.fetchPlansSites();
    this.fetchCompanySites(this.company);
    this.fetchNestedSitesAndSeu(this.company);
    document.title = "Attach/Remove Site Plan";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.chosenCompany = to.params.company;
    this.fetchCompanySites(this.chosenCompany);
    this.getSitePlanList(this.chosenId);

    next();
  }

}
</script>
