<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>

  <div v-if="getUsername()" class="dashboard-site">
    <div v-if="getLoader() === true" class="row">
      <div class="loader" id="loader"></div>
    </div>
    <div class="row row-break"></div>
    <div v-if="getUserSites() && getProfile()" class="row plain-element">
      <div v-if="getUserSites().length > 0" class="row plain-element">
        <div v-if="getUserOverallView()" class="card card-dashboard-details">
          <div class="row plain-element">
            <div class=" col col-12 col-sm-12 order-2 order-sm-2 order-md-2 order-lg-1 col-md-12 col-lg-3 plain-element">
              <div class="row plain-element">
                <div class="card card-events">
                  <div class="row row-company">
                    <div class=" col col-12 col-sm-12 col-md-2 col-lg-4 text-start col-tabs-logo">
                      <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                           class="img responsive">
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-10 col-lg-8 text-start col-company-name text-start">
                        <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-6 text-end">
                            Currency:
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-6 text-center">
                          <select v-model="dashboardCurrency" name="dashboardCurrency" id="dashboardCurrency" class="currency-select">
                            <option value="EUR" selected> EUR</option>
                            <option value="USD"> USD</option>
                            <option value="GBP"> GBP</option>
                            <option value="CAD"> CAD</option>
                            <option value="CNY"> CNY</option>
                            <option value="KRW"> KRW</option>
                            <option value="CHF"> CHF</option>
                            <option value="JPY"> JPY</option>
                          </select>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div v-if="getUserOverallView().site_events_dict" class="row plain-element">
                    <div v-if="getUserOverallView() && getUserOverallView().site_events_dict.Overall" class="row plain-element">
                      <RowEventsComponent
                              :eventsArray=getUserOverallView().site_events_dict.Overall
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div v-if="getUserOverallView()" class=" col col-12 col-sm-12 order-sm-1 order-md-1 order-lg-2 col-md-12 col-lg-9 plain-element">
              <div v-if="getUserOverallView().dashboard_sites" class="row plain-element">
                <RowTabsComponent
                  :sitesArray = getUserOverallView().site_names_dict
                />
              </div>
              <div class="row plain-element">
                <div v-if="getUserOverallView().site_actions_count_dict[getActiveSite()] > 0" class="card card-dashboard">
                  <div v-if="getActiveSite() !== 'Overall' && getUserOverallView().site_kpis_dict[getActiveSite()]" class="row plain-element">
                    <div v-if="getUserOverallView().site_kpis_dict" class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getUserOverallView().site_kpis_dict[getActiveSite()].length > 0" class="row plain-element">
                        <PerformanceKpiCardComponent
                          :siteKpiArray = getUserOverallView().site_kpis_dict[getActiveSite()][0]
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard card-site-dashboard-1">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="getUserOverallView().site_kpis_dict" class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getUserOverallView().site_kpis_dict[getActiveSite()].length > 1" class="row plain-element">
                        <PerformanceKpiCardComponent
                          :siteKpiArray = getUserOverallView().site_kpis_dict[getActiveSite()][1]
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard card-site-dashboard-1">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="getUserOverallView().site_kpis_dict" class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getUserOverallView().site_kpis_dict[getActiveSite()].length > 2" class="row plain-element">
                        <PerformanceKpiCardComponent
                          :siteKpiArray = getUserOverallView().site_kpis_dict[getActiveSite()][2]
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard card-site-dashboard-1">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="getActiveSite() === 'Overall' && getUserOverallView().site_kpis_dict[getActiveSite()]" class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getUserOverallView().site_kpis_dict" class="row plain-element">
                        <OverallKpiCardComponent
                          :title = "'Followed KPIs'"
                          :value = getUserOverallView().site_kpis_dict[getActiveSite()].kpis_watched
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getUserOverallView().site_kpis_dict" class="row plain-element">
                        <OverallKpiCardComponent
                          :title = "'User Actions'"
                          :value = getUserOverallView().site_kpis_dict[getActiveSite()].user_actions
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                      <div v-if="getUserOverallView().site_kpis_dict" class="row plain-element">
                        <OverallKpiCardComponent
                          :title = "'User Opportunities'"
                          :value = getUserOverallView().site_kpis_dict[getActiveSite()].user_opportunities
                        />
                      </div>
                      <div v-else class="row plain-element">
                        <div class="card card-site-dashboard">
                          <div class="row-card-site-dashboard-title grey">
                            No Data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <OpenActionsCardComponent
                          :value = getUserOverallView().site_actions_count_dict[getActiveSite()]
                          :difference = getUserOverallView().site_open_action_difference_dict[getActiveSite()]
                          :chart = getUserOverallView().chart_numbers[getUserOverallView().dashboard_sites.indexOf(getActiveSite())][0]
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <TimeToCloseCardComponent
                          :value = getUserOverallView().sites_closing_times_dict[getActiveSite()]
                          :difference = getUserOverallView().difference_closing_times_dict[getActiveSite()]
                          :chart = getUserOverallView().chart_numbers[getUserOverallView().dashboard_sites.indexOf(getActiveSite())][1]
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <PotentialSavingsCardComponent
                          :value = getUserOverallView().site_actions_total_value_dict[getActiveSite()]
                          :difference = getUserOverallView().site_actions_value_difference_dict[getActiveSite()]
                          :chart = getUserOverallView().chart_numbers[getUserOverallView().dashboard_sites.indexOf(getActiveSite())][2]
                          :multiplier = getDashboardCurrencies()[dashboardCurrency].multiplier
                          :symbol = getDashboardCurrencies()[dashboardCurrency].symbol
                        />
                    </div>
                    <div class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                        <Co2SavingsCardComponent
                          :value = getUserOverallView().site_total_co2_savings_dict[getActiveSite()]
                          :difference = getUserOverallView().site_actions_co2_difference_dict[getActiveSite()]
                          :chart = getUserOverallView().chart_numbers[getUserOverallView().dashboard_sites.indexOf(getActiveSite())][3]
                        />
                    </div>
                  </div>
                  <div v-if="getUserOverallView()" class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-8 col-lg-8 plain-element">
                      <div class="row plain-element">
                        <div v-if="getUserOverallView().sites_latest_actions_dict[getActiveSite()]" class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                            <LatestActionCardComponent
                              :latestActionArray = getUserOverallView().sites_latest_actions_dict[getActiveSite()]
                              :multiplier = getDashboardCurrencies()[dashboardCurrency].multiplier
                              :symbol = getDashboardCurrencies()[dashboardCurrency].symbol
                            />
                        </div>
                        <div v-else class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                          <div class="card card-site-dashboard card-site-dashboard-3 clickable">
                            <div class="row-card-site-dashboard-title grey">
                              No Data
                            </div>
                          </div>
                        </div>
                        <div v-if="getUserOverallView().sites_top_opportunities_dict[getActiveSite()]" class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                            <TopOpportunityCardComponent
                              :topOpportunityArray = getUserOverallView().sites_top_opportunities_dict[getActiveSite()]
                              :multiplier = getDashboardCurrencies()[dashboardCurrency].multiplier
                              :symbol = getDashboardCurrencies()[dashboardCurrency].symbol
                            />
                        </div>
                        <div v-else class="col col-12 col-sm-12 col-md-6 col-lg-6 plain-element">
                          <div class="card card-site-dashboard card-site-dashboard-3 clickable">
                            <div class="row-card-site-dashboard-title grey">
                              No Data
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row plain-element">
                        <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                            <CalendarCardComponent/>
                        </div>
                      </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element">
                          <SiteInfoCardComponent
                            :sitesDict = getUserOverallView().site_dict
                          />
                    </div>
                  </div>
                </div>
                <div v-else class="card card-dashboard card-dashboard-empty">
                  <div class="row row-break"></div>
                  <div class="row row-break"></div>
                  <div class="row row-break"></div>

                  <div class="row plain-element text-center">

                    <span class="span-info"> No Actions Data</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div v-else class="row plain-element">
        <div class="card login-card">
          <div class="row plain-element text-center">
            <p class="text-info"> You don't have a dashboard yet</p>
          </div>
        </div>
      </div>
    </div>
    <div v-cloak>
      <div v-if="getNoProfile() === 'None' && isLoggedIn()" class="row plain-element">
        <div class="card login-card">
          <div v-if="getProfile() === null" class="row plain-element text-center">
            <div v-if="getLoader() === true" class="row">
              <div class="loader" id="loader"></div>
            </div>
            <div v-if="getLoader() === false" class="row plain-element text-center">
              <p class="text-info"> You don't have a profile yet</p>
              <a @click="createProfile()" class="btn btn-login-card">Create Profile</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div>
      <span class="footer"><p><a target="_blank" href="https://em3-support.atlassian.net/servicedesk/customer/portal/3" class="font-blue">
          Need Help?</a> • <a target="_blank" href="https://em3.ie/privacy-policy/">Privacy Policy</a> • © 2024 EM3 SAPERA - All Rights Reserved </p>
      </span>
  </div>
</div>



</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";
import RowEventsComponent from "@/components/dashboards/RowEventsComponent.vue";
import RowTabsComponent from "@/components/dashboards/RowTabsComponent.vue";
import PerformanceKpiCardComponent from "@/components/dashboards/PerformanceKpiCardComponent.vue";
import OverallKpiCardComponent from "@/components/dashboards/OverallKpiCardComponent.vue";
import OpenActionsCardComponent from "@/components/dashboards/OpenActionsCardComponent.vue";
import TimeToCloseCardComponent from "@/components/dashboards/TimeToCloseCardComponent.vue";
import PotentialSavingsCardComponent from "@/components/dashboards/PotentialSavingsCardComponent.vue";
import Co2SavingsCardComponent from "@/components/dashboards/Co2SavingsCardComponent.vue";
import LatestActionCardComponent from "@/components/dashboards/LatestActionCardComponent.vue";
import TopOpportunityCardComponent from "@/components/dashboards/TopOpportunityCardComponent.vue";
import CalendarCardComponent from "@/components/dashboards/CalendarCardComponent.vue";
import SiteInfoCardComponent from "@/components/dashboards/SiteInfoCardComponent.vue";

export default {
  name: "UserDashboard",
  components: {
      NoPermissionComponent,
      RowEventsComponent,
      RowTabsComponent,
      PerformanceKpiCardComponent,
      OverallKpiCardComponent,
      OpenActionsCardComponent,
      TimeToCloseCardComponent,
      PotentialSavingsCardComponent,
      Co2SavingsCardComponent,
      LatestActionCardComponent,
      TopOpportunityCardComponent,
      CalendarCardComponent,
      SiteInfoCardComponent
  },
  data() {
    return {
        dashboardCurrency: "EUR"
    }
  },
  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getLoader", "getUserSites", "getActiveSite", "getUserOverallView", "getCompanyString", "getUserSites", "getProfile", "getNoProfile", "getDashboardCurrencies"]),
    ...mapActions([ "performSetFormError", "activateSite", "fetchUserOverallView", "fetchUserSites", "performCreateProfile", "fetchUserProfile", "performSetLoader"]),
    createProfile() {
      this.performSetLoader(true);
      this.performCreateProfile()
    },
    userProfile() {
      this.fetchUserProfile()
    },
  },
  filters: {
  },
  created() {
    document.title = "EM3 Portal";
    this.fetchUserSites();
    this.userProfile();
    this.performSetLoader(false);
    this.fetchUserOverallView(this.company);
    this.performSetFormError(null);
    window.scrollTo(0,0);
  },
}
</script>


