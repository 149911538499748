<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Opportunity
        </a>
      </div>
    </div>
    <div v-if="getOpportunityData()" class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <AddOpportunityCommentComponent
        :current_opportunity_number = getOpportunityData().opportunity_number
        :id = id
      />
    </div>
  </div>
</div>


</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import AddOpportunityCommentComponent from "@/components/opportunities/AddOpportunityCommentComponent.vue"

export default {
  name: "OpportunityCommentCreate",
  components: {
      NoPermissionComponent,
      AddOpportunityCommentComponent,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      comment : null,
    }
  },
  methods: {
    ...mapGetters(["getOpportunityData", "getFormError", "getUsername", "isLoggedIn", "getCompanyString"]),
    ...mapActions(["fetchOpportunityData"]),
    getOpportunityDetails(id) {
      this.fetchOpportunityData({"id": id })
    },
    hasHistory () {
      return window.history?.length > 1
    },
  },
  computed: {

  },
  created() {
    this.getOpportunityDetails(this.id);
    document.title = "Add Opportunity Comment";
    window.scrollTo(0,0);

  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getOpportunityDetails(this.chosenId);
    next();
  },
}

</script>