import api from "../../api/api.js"


const state = {
  dashboard: null,
  dashboardCurrencies: {
        "EUR": {"symbol": "€", "multiplier": 1},
        "USD": {"symbol": "$", "multiplier": 1.08},
        "GBP": {"symbol": "£", "multiplier": 0.86},
        "CAD": {"symbol": "$", "multiplier": 1.45},
        "CNY": {"symbol": "¥", "multiplier": 7.65},
        "KRW": {"symbol": "₩", "multiplier": 1425},
        "CHF": {"symbol": "₣", "multiplier": 0.98},
        "JPY": {"symbol": "¥", "multiplier": 150},
  },
  companyDashboardSites: null,
  activeSite: null,
  userOverallView: null,
};

const getters = {
  getDashboard: state => state.dashboard,
  getDashboardCurrencies: state => state.dashboardCurrencies,
  getActiveSite: state => state.activeSite,
  getUserOverallView: state => state.userOverallView,
  getDashboardCurrency: state => state.dashboardCurrency,
};


const actions = {
  async fetchCompanyDashboardSites({commit}, company) {
    const response = await api.companyDashboardSites(company);
    commit("setCompanyDashboardSites", response.sites);
    commit("setActiveSite", "Overall");
  },
  activateSite({commit}, site) {
    commit("setActiveSite", site);
  },
  async fetchUserOverallView({commit}) {
    commit("setLoader", true);
    const response = await api.userOverallView();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setUserOverallView", response);
      commit("setLoader", false);
      commit("setActiveSite", "Overall");
    }
  },
  async getSEUDashboard({ commit }, payload) {
    const response = await api.SEUDashboard(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      commit('setDashboard', response.dashboard);
    }
  },
};

const mutations = {
  setDashboardCurrency: (state, currency) => {
    state.currency = currency
  },
  setDashboard: (state, dashboard) => {
    state.dashboard = dashboard
  },
  setActiveSite: (state, activeSite) => {
    state.activeSite = activeSite
  },
  setUserOverallView: (state, userOverallView) => {
    state.userOverallView = userOverallView
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}