<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getCorporatePlanData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Plan
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <UpdateCorporatePlanComponent
              :id = id
              :current_name = getCorporatePlanData().name
              :current_company = getCorporatePlanData().company
              :current_description = getCorporatePlanData().description
              :current_currency = getCorporatePlanData().currency
      />
    </div>
  </div>
</div>
</template>


<script>

import { mapGetters, mapActions } from "vuex";
import { hasHistory } from "@/common/general.js";
import UpdateCorporatePlanComponent from "@/components/corporate_plans/UpdateCorporatePlanComponent.vue";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "CorporatePlanUpdate",
  components: {
    UpdateCorporatePlanComponent,
    NoPermissionComponent,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getCorporatePlanData","getFormError", "getUsername", "isLoggedIn"]),
    ...mapActions(["fetchCorporatePlanData", "performSetFormError"]),
    hasHistory: hasHistory,
    getCorporatePlanDetails() {
      this.fetchCorporatePlanData({"id": this.id })
    },
  },

  created() {
    this.getCorporatePlanDetails();
    this.performSetFormError(null);
    document.title = "Update Corporate Plan";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.getCorporatePlanDetails();
    next();
  }
}


</script>
