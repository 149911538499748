<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getSitePlanData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Plan
        </a>
      </div>
    </div>
    <div v-if="getSitePlanData().plan_manager === getUsername()" class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <UpdateSitePlanComponent
              :id = id
              :current_name = getSitePlanData().name
              :current_company = getSitePlanData().company
              :current_site_code = getSitePlanData().site_code
              :current_description = getSitePlanData().description
              :current_co2_electricity_tonnes_previous_year = getSitePlanData().co2_electricity_tonnes_previous_year
              :current_co2_fuel_tonnes_previous_year = getSitePlanData().co2_fuel_tonnes_previous_year
              :current_cost_baseline = getSitePlanData().cost_baseline
              :current_currency = getSitePlanData().currency
              :current_co2_electricity_reductions_year = getSitePlanData().co2_electricity_reductions_year
              :current_co2_fuel_reductions_year = getSitePlanData().co2_fuel_reductions_year
              :current_co2_baseline = getSitePlanData().co2_baseline
              :current_co2_baseline_year = getSitePlanData().co2_baseline_year
      />
    </div>
  </div>
</div>
</template>


<script>

import { mapGetters, mapActions } from "vuex";
import UpdateSitePlanComponent from "@/components/site_plans/UpdateSitePlanComponent.vue";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "SitePlanUpdate",
  components: {
    UpdateSitePlanComponent,
    NoPermissionComponent,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getSitePlanData","getFormError", "getUsername", "isLoggedIn"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError"]),
    getPlanDetails() {
      this.fetchSitePlanData({"id": this.id })
    },
    hasHistory () {
      return window.history?.length > 1
    },
  },

  created() {
    this.getPlanDetails();
    this.performSetFormError(null);
    document.title = "Update Plan";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.getPlanDetails();
    next();
  }
}


</script>
