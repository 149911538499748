<template>
    <div v-if="id" class="row plain-element">
        <form @submit.prevent="updateCorporatePlanCarbonTracker()" enctype="multipart/form-data">
            <div class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                    <div class="row row-functions text-start">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                            <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png'"
                                 class="img responsive img-icon">
                        </div>
                        <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                            <h3 class="font-dark-grey">Update Carbon Tracker (Leave 0 if N/A) </h3>
                        </div>
                        <div  v-if="getLoader() === false" class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                            <button type="submit" class="btn btn-report">
                                Submit Update
                            </button>

                        </div>
                    </div>
                </div>
                <br>
                <p class="error" v-if="getFormError()">
                    <span id="formError">{{ getFormError() }}</span>
                </p>
                <p class="error" v-else>
                </p>
            </div>
            <div v-if="getLoader() === true" class="row">
                <div class="loader" id="loader"></div>
            </div>
            <div v-if="getLoader() === false" class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-3 text-start">
                    <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                            <div class="row plain-element">
                                <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                    2022:
                                </div>
                                <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                    <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2022"
                                           v-model="new_2022" class="validate" min="-10000000" max="1000000000"
                                           step="01" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="w-100"></p>
                    <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                            <div class="row plain-element">
                                <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                    2023:
                                </div>
                                <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                    <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2023"
                                           v-model="new_2023" class="validate" min="-10000000" max="1000000000"
                                           step="01" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="w-100"></p>
                    <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                            <div class="row plain-element">
                                <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                    2024:
                                </div>
                                <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                    <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2024"
                                           v-model="new_2024" class="validate" min="-10000000" max="1000000000"
                                           step="01" required>
                                </div>
                            </div>
                        </div>

                        <p class="w-100"></p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                                <div class="row plain-element">
                                    <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                        2025:
                                    </div>
                                    <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                        <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2025"
                                               v-model="new_2025" class="validate" min="-10000000" max="1000000000"
                                               step="01" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"></p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                                <div class="row plain-element">
                                    <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                        2026:
                                    </div>
                                    <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                        <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2026"
                                               v-model="new_2026" class="validate" min="-10000000" max="1000000000"
                                               step="01" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-3 text-start">
                    <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                            <div class="row plain-element">
                                <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                    2027:
                                </div>
                                <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                    <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2027"
                                           v-model="new_2027" class="validate" min="-10000000" max="1000000000"
                                           step="01" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="w-100"></p>
                    <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                            <div class="row plain-element">
                                <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                    2028:
                                </div>
                                <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                    <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2028"
                                           v-model="new_2028" class="validate" min="-10000000" max="1000000000"
                                           step="01" required>
                                </div>
                            </div>
                        </div>

                        <p class="w-100"></p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                                <div class="row plain-element">
                                    <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                        2029:
                                    </div>
                                    <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                        <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2029"
                                               v-model="new_2029" class="validate" min="-10000000" max="1000000000"
                                               step="01" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"></p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-11 text-start">
                                <div class="row plain-element">
                                    <div class="input-field col col-sm-8 col-md-6 col-lg-3">
                                        2030:
                                    </div>
                                    <div class="input-field col col-sm-4 col-md-6 col-lg-6 col-value">
                                        <input id="co2_electricity_tonnes_previous_year" type="number" name="new_2030"
                                               v-model="new_2030" class="validate" min="-10000000" max="1000000000"
                                               step="01" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import { validateString, } from "@/common/validation.js";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "UpdateCorporatePlanComponent",
  props: {
    id: { type: Number, required: true },
    current_active: { type: Boolean, required: false },
    current_2022: { type: Number, required: false },
    current_2023: { type: Number, required: false },
    current_2024: { type: Number, required: false },
    current_2025: { type: Number, required: false },
    current_2026: { type: Number, required: false },
    current_2027: { type: Number, required: false },
    current_2028: { type: Number, required: false },
    current_2029: { type: Number, required: false },
    current_2030: { type: Number, required: false },

  },
  data() {
    return {
      active: this.current_active,
      new_2022: this.current_2022 || 0,
      new_2023: this.current_2023 || 0,
      new_2024: this.current_2024 || 0,
      new_2025: this.current_2025 || 0,
      new_2026: this.current_2026 || 0,
      new_2027: this.current_2027 || 0,
      new_2028: this.current_2028 || 0,
      new_2029: this.current_2029 || 0,
      new_2030: this.current_2030 || 0
    }
  },

  methods: {
    ...mapGetters([ "getUsername", "getCompanyString", "getFormError", "getLoader"]),
    ...mapActions(["performCorporatePlanCarbonTrackerUpdate", "performSetFormError"]),
    validate: validateString,
    updateCorporatePlanCarbonTracker() {
      this.performCorporatePlanCarbonTrackerUpdate({
                               "id": this.id,
                               "2022": this.new_2022,
                               "2023": this.new_2023,
                               "2024": this.new_2024,
                               "2025": this.new_2025,
                               "2026": this.new_2026,
                               "2027": this.new_2027,
                               "2028": this.new_2028,
                               "2029": this.new_2029,
                               "2030": this.new_2030,
                              })

    },
  },
  computed: {
  },
  created() {

  }


}
</script>