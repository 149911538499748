<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row plain-element top-menu">
          <ul>
            <li>
              <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
            </li>
            <li>
              <router-link :to="{name: 'energy-guide-search'}" class="horizontal-link underline-green">Energy Guides
              </router-link>
            </li>
          </ul>
      </div>
    </div>
    <p class="w-100"></p>
    <div class="card card-details">
      <div v-if="!getIsoChapters()" class="row">
          <div class="loader" id="loader"></div>
      </div>
      <p class="error" v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error" v-else>
      </p>
      <form v-if="getIsoChapters()" @submit.prevent="createPdfReport()" enctype="multipart/form-data">
        <div  class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
            <div class="row row-functions text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                <img alt="img" src="https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png"
                     class="img responsive img-icon">
              </div>
              <div class="col col-sm-6 col-md-8 col-lg-5 plain-element text-start">
                <h3 class="font-dark-grey">Upload Energy Guide</h3>
              </div>
              <div class="col col-sm-6 col-md-4 col-lg-6 plain-element text-end">
                <button type="submit" class="btn btn-report">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
            <div class="row text-start"><h6 class="font-dark-grey">PDF Report<span class="font-14">*</span></h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 text-start">
                <div class="row plain-element text-start">
                  <input class="form-control file-input" v-on:change="handleFileUpload()" type="file" name="file"
                         id="file" ref="file" accept=".pdf" required="True">
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 text-start">
                <div v-if="getPermittedSites()" class="row text-start">
                  <h6 class="font-dark-grey">Site<span class="font-14">*</span>
                    <span v-if="no_match_error" id="no-match-error" >Site doesn't match PDF Report.</span>
                  </h6>
                  <div class=" col col-12 col-sm-12 col-md-11 col-lg-11 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-form">
                      <select v-on:change="checkSiteMatch()" v-model="site_code" name="site_code" id="site_code"
                              required>
                        <option v-for="(site, index) in getPermittedSites()" :value="site[1]" :key="index">
                          {{ getSiteNames()[site][0] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 text-start">

              </div>
            </div>

          </div>
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
              <div class="row plain-element text-start">
              <div class="row plain-element opportunity-description text-start"><h6 class="font-dark-grey">Author<span class="font-14">*</span></h6>
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-11 col-form">
                    <textarea rows="1" cols="15" name="author" v-model="author" required></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class="row plain-element opportunity-description text-start"><h6 class="font-dark-grey">Project
                number<span class="font-14">*</span></h6>
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-11 col-form">
                    <textarea rows="1" cols="15" name="project_number" v-model="project_number" required></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element text-start">
              <div class="row plain-element opportunity-description text-start"><h6 class="font-dark-grey">Document Title<span class="font-14">*</span></h6>
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-12 col-lg-11 col-form">
                    <textarea rows="1" cols="15" name="document_title" v-model="document_title" required></textarea>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </form>
    </div>
  </div>
</div>




</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "EnergyGuideUpload",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      site_code: null,
      pdf_report: null,
      filename: null,
      iso_chapter: "000",
      author: null,
      project_number: null,
      document_title: null,
      no_match_error: null,
    }
  },
  methods: {
    ...mapGetters([
                    "getPermittedSites", "getFormError", "getUsername", "isLoggedIn", "getActionTypes", "getCompanyString", "getIsoChapters", "getSiteNames",
                  ]),
    ...mapActions([
                    "fetchSites", "performCreatePdfReport", "performSetFormError", "fetchIsoChapters", "fetchPermittedSites", "fetchSiteNames",
                   ]),
    createPdfReport() {
      this.performCreatePdfReport({
        "site_code" : this.site_code,
        "project_number": this.project_number,
        "pdf_report": this.pdf_report,
        "filename": this.filename,
        "iso_chapter": this.iso_chapter,
        "author": this.author,
        "document_title": this.document_title,
      })
    },
    handleFileUpload() {
          this.pdf_report = this.$refs.file.files[0];
          this.filename = this.$refs.file.files[0].name;

        },
    hasHistory () {
      return window.history?.length > 1
    },
    checkSiteMatch() {
      if (this.project_number && !this.project_number.includes(this.site_code)) {
        this.no_match_error = "Site doesn't match Project Number.";
      } else {
        this.no_match_error = null;
      }
    },

  },
  computed: {

  },
  created() {
    document.title = "Upload Energy Guide";
    this.fetchPermittedSites();
    this.fetchSiteNames();
    this.fetchIsoChapters();
    window.scrollTo(0,0);
  },
}

</script>