<template>
<form @submit.prevent="updateUserPermissions()" enctype="multipart/form-data">
  <div v-if="getSiteAdmin()" class="row plain-element">
    <div v-if="getUserList()" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="row row-functions text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
          <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
               class="img responsive img-icon">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
          <h3> Change Site Permissions for {{ username }}</h3>
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element col-buttons">
          <button type="submit" class="btn btn-report">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <p class="w-100"></p>
  <div  v-if="getSiteAdmin()" class="row plain-element text-start">
    <div class="col col-12 col-sm-12 col-md-6 col-lg-12 text-start">
      <div class="row plain-element">
        <div v-if="getSiteCompanyNames()" class="row plain-element">
          <div class="input-field  col col-12 col-sm-12 col-md-2 col-form">
            <p v-for="(siteName, site) in getSiteCompanyNames()" class="enpi" :key="site">
              <label v-if="getUserPermissionsArray().includes(site)">
                <input type="checkbox" v-model="newSitePermissions" :value="site" checked="checked" />
                <span>{{ siteName[1] }} - {{ site }} - {{ siteName[0] }}</span>
              </label>
              <label v-else>
                <input type="checkbox" v-model="newSitePermissions" :value="site" checked="checked" />
                <span>{{ siteName[1] }} - {{ site }} -  {{ siteName[0] }}</span>
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row plain-element">
    <div  class="row plain-element text-center">
      <p class="text-info"> Required privilege missing</p>

    </div>
  </div>
</form>
</template>

<script>

import { mapGetters, mapActions } from "vuex";


export default {
  name: "ChangeUserPermissionsComponent",
  props: {
    user_permissions: { type: Array, required: true },
    user_id: { type: String, required: true },
    username: { type: String, required: true },
  },
  data() {
    return {
        newSitePermissions: this.user_permissions,
      }
  },

  methods: {
    ...mapGetters(["getProfile", "getCompanyString", "getUsername", "getUserPermissionsArray", "getSiteCompanyNames", "getUserList", "getSiteAdmin"]),
    ...mapActions(["performSetFormError","fetchUserList", "performUpdateSitePermissions"]),
    updateUserPermissions() {
      this.performUpdateSitePermissions(
        { "permissions": this.newSitePermissions,
          "user_id": this.user_id
        }
      )
    },

  },
  computed: {
  },
  created() {
    this.fetchUserList();
  },
  beforeRouteUpdate (to, from, next) {
    next();
  }
}
</script>


