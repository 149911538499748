<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
  <div v-if="getUsername() && getActionData()" class="dashboard-site">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'action-search'}" class="horizontal-link">Action Search
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="getActionData().action_log" class="card card-details">

      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'" class="img responsive img-icon">
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element text-start">
              <h3>{{ getActionData().action_number }}</h3>
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element col-buttons">
              <router-link :to="{name: 'action-comment-create', params: {id: id.toString()}}" class="btn btn-report">
                  Add Comment
              </router-link>
              <a target="_blank" :href="'/api/action-details-download/' +  id + '/'" class="btn btn-report">
                   Download
              </a>
            </div>
          </div>
        </div>
        <div class="row plain-element text-start">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
            <div class=" col col-12 col-sm-12 col-md-8 col-lg-8 plain-element text-end">
              <div class="row plain-element text-start">
                <h6>Action Details: </h6>
                <table  class="table-metrics">
                  <tr>
                    <td>Site Code:</td>
                    <td class="text-end"><b> {{ getActionData().site_code.site_code }} </b></td>
                  </tr>
                  <tr>
                    <td>SEU:</td>
                    <td class="text-end"><b> {{ getActionData().seu.seu }} </b></td>
                  </tr>
                  <tr>
                    <td>Area:</td>
                    <td class="text-end"><b> {{ getActionData().seu.area.area }} </b></td>
                  </tr>
                  <tr>
                    <td>Action Type:</td>
                    <td class="text-end"><b> {{ getActionData().action_type.action_type }} </b></td>
                  </tr>

                  <tr>
                    <td>Event Type:</td>
                    <td class="text-end"><b> {{ getActionData().event_type.event_type }} </b></td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td class="text-end"><b> {{ getActionData().status }} </b></td>
                  </tr>
                  <tr>
                    <td>Created:</td>
                    <td class="text-end"><b> {{ getActionData().created_date }} </b></td>
                  </tr>
                  <tr>
                    <td>Closed:</td>
                    <td class="text-end"><b> {{ getActionData().closed_date }} </b></td>
                  </tr>
                  <tr>
                    <td>Action By:</td>
                    <td class="text-end"><b> {{ getActionData().action_by }} </b></td>
                  </tr>
                  <tr>
                    <td>Action To:</td>
                    <td class="text-end"><b> {{ getActionData().action_to }} </b></td>
                  </tr>
                </table>
              </div>
              <p class="w-100"></p>
              <div v-if="getActionData()" class="row plain-element text-start">
                <h6>Cost Metrics: </h6>
                <table  class="table-metrics">
                  <tr v-if="getActionData().cost_interval">
                    <td>Cost Interval:</td>
                    <td class="text-end"><b class="font-dark-grey"> {{ getActionData().cost_interval.cost_interval }} </b></td>
                  </tr>
                  <tr>
                    <td>Cost (EUR):</td>
                    <td class="text-end"><b class="font-dark-grey"> {{ getActionData().cost }} </b></td>
                  </tr>
                  <tr>
                    <td>CO2 (kg):</td>
                    <td class="text-end"><b class="font-dark-grey"> {{ getActionData().co2_cost }} </b></td>
                  </tr>

                  <tr>
                    <td>Start Timestamp:</td>
                    <td class="text-end"><b class="font-dark-grey"> {{ getActionData().start_timestamp }} </b></td>
                  </tr>
                  <tr>
                    <td>End Timestamp:</td>
                    <td class="text-end">
                      <b v-if="getActionData().end_timestamp" class="font-dark-grey"> {{ getActionData().end_timestamp }}</b>
                      <b v-else class="font-dark-grey"> - </b>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
            <div class=" col col-12 col-sm-12 col-md-8 col-lg-8 plain-element text-end">
              <div class="row plain-element text-start">
                <h6>Attached Enpi: </h6>
                <table v-if="getActionData().enpi_action_relations.length > 0" class="table-metrics">
                  <tr v-for="(element, index) in getActionData().enpi_action_relations" :key="index">
                    <td class="text-start">
                       {{ element }}
                    </td>
                  </tr>
                </table>
                <table v-else class="table-metrics">
                  <tr>
                    <td class="text-start">
                       -
                    </td>
                  </tr>
                </table>
              </div>
              <p class="w-100"></p>
              <div class="row plain-element text-start">
                <h6>Attached Opportunities: </h6>
                <table  class="table-metrics">
                  <tr v-for="(element, index) in getActionData().opportunity_action_relation_links" :key="index" @click="goToOpportunityView(element.opportunity__id.toString())">
                    <td class="text-start">
                       {{ element.opportunity__opportunity_number }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element text-end">
            <div class="row plain-element text-start"><h6>Comment: </h6>
              <p>{{ getActionData().comment }}</p>
            </div>
            <div class="row plain-element text-start"><h6>Response: </h6>
              <p>{{ getActionData().response }}</p>
            </div>
            <div class="row plain-element text-start"><h6>Engineer Comment: </h6>
              <p v-if="getActionData().engineer_comment_internal" >{{ getActionData().engineer_comment_internal }}</p>
              <p v-else >-</p>
            </div>
            <div class="row plain-element text-start"><h6>Reviewer Comment: </h6>
              <p>{{ getActionData().reviewer_comment }}</p>
            </div>
            <div class="row plain-element text-start"><h6>Project Link: </h6>
              <div v-if="getActionData().project_link" class="row plain-element text-start">
                <p v-if="getActionData().project_link.length > 1">
                  <a :href="getActionData().project_link" target="_blank"> Project Link </a>
                </p>
              </div>
              <div v-else class="row plain-element text-start">
                <p>
                     -
                </p>
              </div>
            </div>
            <div class="row plain-element text-start"><h6>Calculation Sheet: </h6>
              <div v-if="getActionData().calc_sheet_link" class="row plain-element text-start">
                <p v-if="getActionData().calc_sheet_link.length > 1">
                  <a :href="getActionData().calc_sheet_link" target="_blank"> Calculation Sheet Link </a>
                </p>
              </div>
              <div v-else class="row plain-element text-start">
                <p>
                     -
                </p>
              </div>
            </div>

          </div>
        </div>
        <p class="w-100"></p>
        <div class="row plain-element text-start">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 plain-element text-end">
              <div v-if="getActionData().action_log" class="row plain-element opportunity-description text-start"><h6>Event Log: </h6>
              <table  class="table-log">
                  <tr v-for="element in getActionData().action_log" :key="element.entry_date">
                    <td class="text-start">{{ formatDate (element.entry_date) }}</td>
                    <td class="text-start"> {{ element.author }}</td>
                    <td class="text-start"><div class="row plain-element" v-for="(element, index) in splitLogList(element.entry)" :key="index"> {{ element }} </div></td>
                  </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"


export default {
  name: "ActionView",
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  components: {
    NoPermissionComponent,
  },
  mounted() {

  },
  methods: {
    ...mapGetters(["getActionData", "getUsername", "isLoggedIn", "getCompanyString"]),
    ...mapActions(["fetchActionData", "performSetFormError"]),

    getActionDetails(id) {
      this.fetchActionData({"id": id })
    },
    hasHistory () {
      return window.history?.length > 1
    },

    formatDate(value) {
      let val = (value).replace('T', ' at ')
      return val.toString().slice(0, -8)
    },
    splitLogList(entry) {
      return entry.split('; ')
    },
    goToOpportunityView(id) {

      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'opportunity-view', params: { id: id } });
      }
    },
  },
  computed: {

  },
  created() {
    this.getActionDetails(this.id);
    this.performSetFormError(null);
    document.title = "Action Details";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getActionDetails(this.chosenId);
    next();
  }

}
</script>

<style scoped>
    .table-metrics tr.pointer-style {
      cursor: pointer !important;
    }
</style>
