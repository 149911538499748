<template>
  <div v-if="sitesDict" class="row plain-element">
    <div v-if="getActiveSite() !== 'Overall'" class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="card card-site-dashboard card-site-dashboard-long">
        <div class="row-card-site-dashboard-title">
          Site Info
        </div>
        <div class="row-break">
        </div>
        <div class="row row-card-site-dashboard-info text-start">
          <div class="col col-12 col-sm-5 col-md-5 col-lg-5 text-end">
            <span class="site-info-title">Company Name:</span>
          </div>
          <div class="col col-12 col-sm-1 col-md-1 col-lg-1 text-end">
          </div>
          <div class="col col-12 col-sm-5 col-md-5 col-lg-6">
            {{sitesDict[getActiveSite()].company_name}}
          </div>
        </div>
        <div class="row row-card-site-dashboard-info text-start">
          <div class="col col-12 col-sm-5 col-md-5 col-lg-5 text-end">
            <span class="site-info-title">Location:</span>
          </div>
          <div class="col col-12 col-sm-1 col-md-1 col-lg-1 text-end">
          </div>
          <div class="col col-12 col-sm-5 col-md-5 col-lg-6">
            {{sitesDict[getActiveSite()].site_name}}
          </div>
        </div>
        <div class="row row-card-site-dashboard-info text-start">
          <div class="col col-12 col-sm-5 col-md-5 col-lg-5 text-end">
            <span class="site-info-title">Country:</span>
          </div>
          <div class="col col-12 col-sm-1 col-md-1 col-lg-1 text-end">
          </div>
          <div class="col col-12 col-sm-5 col-md-5 col-lg-6">
            {{sitesDict[getActiveSite()].country}}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div v-if="sitesDict" class="card card-site-dashboard card-site-dashboard-long card-site-dashboard-long-overflow-hidden">
        <div class="row-card-site-dashboard-title">
          User Sites
        </div>
        <div class="row-break">
        </div>
        <div v-for="(site, index) in sitesArray.slice(0,8)" class="row plain-element" :key="index">
          <div class="row row-card-site-dashboard-info text-start">
            <div class="col col-12 col-sm-5 col-md-5 col-lg-4 text-end">
              <span class="site-info-title">{{site.site_code}}</span>
            </div>
            <div class="col col-12 col-sm-1 col-md-1 col-lg-1 text-end"></div>
            <div v-if="site.site_name !== 'Not Specified'" class="col col-12 col-sm-5 col-md-5 col-lg-6">
              {{site.site_name}} ({{site.country}})
            </div>
            <div v-else class="col col-12 col-sm-5 col-md-5 col-lg-6">
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SiteInfoCardComponent",
  props: {
    sitesDict: { type: Object, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUserOverallView", "getActiveSite"]),
    ...mapActions([]),
  },
  computed: {
    today() {
        var d = new Date();
        return this.weekdays[d.getDay()]
    },
    sitesArray() {
      return Object.values(this.sitesDict);
    },
  },
  filters: {

  },
  created() {

  },
}
</script>