import api from "../../api/api.js"
import router from "../../router.js"

const state = {
  divisionPlans: [],
  divisionPlanData: null,
  divisionSitePlans: [],
  divisionPlanCarbonTracker: null,
  siteSpecificClimateWaterfall: null,
  siteSpecificAnnualWaterfall: null,
};


const getters = {
  getDivisionPlans: state => state.divisionPlans,
  getDivisionPlanData: state => state.divisionPlanData,
  getDivisionSitePlans: state => state.divisionSitePlans,
  getDivisionPlanCarbonTracker: state => state.divisionPlanCarbonTracker,
  getSiteSpecificClimateWaterfall: state => state.siteSpecificClimateWaterfall,
  getSiteSpecificAnnualWaterfall: state => state.siteSpecificAnnualWaterfall,
};


const actions = {
// DIVISION
  async fetchAllDivisionPlans({commit}) {
    commit("setLoader", true);
    const response = await api.getAllDivisionPlans();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setDivisionPlans", response.queryset);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
// DIVISION
  async fetchDivisionPlanData({commit, dispatch}, payload) {
    const response = await api.divisionPlanData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setDivisionPlanData", response);
      if(response.site_names.length > 0) {
        var firstSite = response.site_names[0]["site_code"]
        var siteSpecificClimateWaterfallDataset = {
            labels: response.site_carbon_reduction_per_climate_action_phase[firstSite][0],
            datasets:  [{
                        backgroundColor: response.site_carbon_reduction_per_climate_action_phase[firstSite][2],
                        data: response.site_carbon_reduction_per_climate_action_phase[firstSite][1],
                        tooltips: response.site_carbon_reduction_per_climate_action_phase[firstSite][5],
                      }]
      };
        dispatch ("performSetSiteSpecificClimateWaterfall", siteSpecificClimateWaterfallDataset);

        var siteSpecificAnnualWaterfallDataset = {
            labels: response.site_total_carbon_chart_data[firstSite][0],
            datasets:  [{
                        backgroundColor: response.site_total_carbon_chart_data[firstSite][2],
                        data: response.site_total_carbon_chart_data[firstSite][1],
                        tooltips: response.site_total_carbon_chart_data[firstSite][5],
                      }]
      };
        dispatch ("performSetSiteSpecificAnnualWaterfall", siteSpecificAnnualWaterfallDataset);
    }

// DIVISION
//    Carbon  Climate Action Phase Waterfall
      var carbonClimateActionPhaseWaterfallChartDataset = {
                labels: response.carbon_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.carbon_climate_action_phase_chart_data[2],
                            data: response.carbon_climate_action_phase_chart_data[1],
                            tooltips: response.carbon_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setCarbonClimateActionPhaseWaterfallChartData", carbonClimateActionPhaseWaterfallChartDataset);
// DIVISION
//    Financial  Climate Action Phase Waterfall
      var financialClimateActionPhaseWaterfallChartDataset = {
                labels: response.financial_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.financial_climate_action_phase_chart_data[2],
                            data: response.financial_climate_action_phase_chart_data[1],
                            tooltips: response.financial_climate_action_phase_chart_data[4],
                            currencies: response.financial_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setFinancialClimateActionPhaseWaterfallChartData", financialClimateActionPhaseWaterfallChartDataset);
// DIVISION
//    Total investment Waterfall Chart
      var investmentWaterfallChartDataset  =  {
          labels: response.total_investment_chart_data[0],
          datasets: [{
                        backgroundColor: response.total_investment_chart_data[2],
                        data: response.total_investment_chart_data[1],
                        tooltips: response.total_investment_chart_data[4],
                        currencies: response.total_investment_chart_data[5],
                    }]
      };
      commit("setInvestmentWaterfallChartData", investmentWaterfallChartDataset);
// DIVISION
//      Cost Savings Waterfall Waterfall
      var costSavingsWaterfallChartDataset = {
           labels: response.total_cost_savings_data[0],
           datasets: [{
                         backgroundColor: response.total_cost_savings_data[2],
                         data: response.total_cost_savings_data[1],
                         tooltips: response.total_cost_savings_data[5],
                         currencies: response.total_cost_savings_data[6],
                     }],
      };
      commit("setCostSavingsWaterfallChartData", costSavingsWaterfallChartDataset);
// DIVISION
//    Cost Savings per Project chart
      var costSavingsChartDataset = {
                labels: response.cost_savings_per_project_data[0],
                datasets: [{
                              backgroundColor: response.cost_savings_per_project_data[2],
                              data: response.cost_savings_per_project_data[1],
                              tooltips: response.cost_savings_per_project_data[3],
                              currencies: response.cost_savings_per_project_data[4],
                          }]
            };
      commit("setCostSavingsChartData", costSavingsChartDataset);
// DIVISION
//      Investment per Project Chart
      var investmentChartDataset = {
                labels: response.investment_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.investment_per_project_chart_data[2],
                              data: response.investment_per_project_chart_data[1],
                              tooltips: response.investment_per_project_chart_data[3],
                              currencies: response.investment_per_project_chart_data[4],
                          }]
            };
      commit("setInvestmentChartData", investmentChartDataset);
// DIVISION
//      Carbon Waterfall Chart
      var carbonWaterfallChartDataset = {
           labels: response.total_carbon_chart_data[0],
           datasets: [{
                          data: response.carbon_tracker,
                          borderColor: "#ff7f0f",
                          borderWidth: 4,
                          type: 'line',
                          order: 0,
                          pointRadius: 1,
                        },
                        {
                         backgroundColor: response.total_carbon_chart_data[2],
                         data: response.total_carbon_chart_data[1],
                         tooltips: response.total_carbon_chart_data[5],
                        }
                     ],
      };
      commit("setCarbonWaterfallChartData", carbonWaterfallChartDataset);
// DIVISION
//      Carbon per project chart
      var carbonChartDataset = {
                labels: response.carbon_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.carbon_per_project_chart_data[2],
                              data: response.carbon_per_project_chart_data[1],
                              tooltips: response.carbon_per_project_chart_data[3],
                          }]
            };
      commit("setCarbonChartData", carbonChartDataset);
// DIVISION
//      Carbon per Euro chart
      var carbonPerEuroChartDataset = {
                labels: response.carbon_per_euro_data[0],
                datasets: [{
                              backgroundColor: response.carbon_per_euro_data[2],
                              data: response.carbon_per_euro_data[1],
                              tooltips: response.carbon_per_euro_data[3],
                              currencies: response.carbon_per_euro_data[4],
                          }]
            };
      commit("setCarbonPerEuroChartData", carbonPerEuroChartDataset);
// DIVISION
//      Carbon per Euro
      if (response.carbon_per_euro_data.length[5] > 0) {
        var carbonPerEuroDict = response.carbon_per_euro_data[5];
        commit("setCarbonPerEuroDict", carbonPerEuroDict);
      } else {
        commit("setCarbonPerEuroDict", {});
      }
    }

  },
// DIVISION
  async performCreateDivisionPlan({commit}, payload) {
    commit("setLoader", true);
    const response = await api.divisionPlanCreate(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setLoader", false);
        commit("setFormError", response.error);
      } else {
        commit("setLoader", false);
        router.push(`/division-plan-search`);
      }
    }
  },
// DIVISION
  performDivisionPlanUpdate({commit}, payload) {
    const response = api.divisionPlanUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/division-plan-success/${payload.id}`);
      }
  },
// DIVISION
  performDivisionPlanPublish({commit}, payload) {
    const response = api.divisionPlanPublish(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/division-plan-success/${payload.id}`);
      }
  },
// DIVISION
  async fetchDivisionSitePlansQueryset({commit, dispatch}, payload) {
    dispatch ("clearDivisionSitePlanList");
    commit("setLoader", true);
    const response = await api.divisionSitePlans(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setDivisionSitePlans", response.site_plans);
      commit("setResultCount", response.site_plans.length);
      commit("setLoader", false);
    }
  },
// DIVISION
  async performCreateDivisionSitePlanRelation({commit}, payload) {
    commit("setLoader", true);
    const response = await api.createDivisionSitePlanRelation(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setLoader", false);
      router.push(`/division-plan-success/${payload.id}`);
    }
  },
// DIVISION
  async fetchDivisionPlanCarbonTracker({commit}, payload) {
    const response = await api.divisionPlanCarbonTracker(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setDivisionPlanCarbonTracker", response.carbon_trackers);
      commit("setLoader", false);
    }
  },
// DIVISION
  async performDivisionPlanCarbonTrackerUpdate({commit}, payload) {
    commit("setLoader", true);
    const response = await api.divisionPlanCarbonTrackerUpdate(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setLoader", false);
        commit("setFormError", response.error);
      } else {
        commit("setLoader", false);
        router.push(`/division-plan-update-success/${payload.id}`);
      }
    }
  },
  clearDivisionSitePlanList({commit}) {
    commit("setDivisionSitePlans", []);
    commit("setNext", null);
    commit("setCurrentSearch", null);
    commit("setResultCount", null);
  },
  performSetSiteSpecificClimateWaterfall({commit}, data) {
    commit("setSiteSpecificClimateWaterfall", data)
  },
  performSetSiteSpecificAnnualWaterfall({commit}, data) {
    commit("setSiteSpecificAnnualWaterfall", data)
  }
};


const mutations = {
  setDivisionPlans:  (state, divisionPlans) => {
    state.divisionPlans = divisionPlans;
  },
  setDivisionPlanData: (state, divisionPlanData) => {
    state.divisionPlanData = divisionPlanData
  },
  setDivisionSitePlans:  (state, divisionSitePlans) => {
    state.divisionSitePlans = divisionSitePlans;
  },
  setDivisionPlanCarbonTracker: (state, divisionPlanCarbonTracker) => {
    state.divisionPlanCarbonTracker = divisionPlanCarbonTracker;
  },
  setSiteSpecificClimateWaterfall: (state, siteSpecificClimateWaterfall) => {
    state.siteSpecificClimateWaterfall = siteSpecificClimateWaterfall;
  },
  setSiteSpecificAnnualWaterfall: (state, siteSpecificAnnualWaterfall) => {
    state.siteSpecificAnnualWaterfall = siteSpecificAnnualWaterfall;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}