import api from "../../api/api.js"

const state = {
  evaDialogues: [],
  evaAnnouncements: null,
  evaSession: null,
  sessionId: null,
  showQuestion: false,
};

const getters = {
  getEvaDialogues: (state) => state.evaDialogues,
  getEvaSession: (state) => state.evaSession,
  getSessionId: (state) => state.sessionId,
  getShowQuestion: (state) => state.showQuestion,
  getEvaAnnouncements: (state) => state.evaAnnouncements,
};

const actions = {
  clearEvaDialogues({ commit }) {
    commit("setEvaDialogues", []);
  },
  async fetchEvaAnnouncements({commit}) {
    const response = await api.evaAnnouncements();
    commit("setEvaAnnouncements", response.announcements);
  },
  async fetchEvaAnswer({ commit }, payload) {
    commit("setLoader", true);
    commit("setShowQuestion", true);
    const response = await api.askEvaQuestion(payload);
    if (!response) {
      commit("setFormError", "Session not initiated correctly(001). Please shift+refresh the browser.");
      commit("setLoader", false);
      commit("setShowQuestion", false);
    } else {
      commit('pushEvaDialogues', [
        { type: 'question', content: payload.question },
        { type: 'answer', content: response.answer },
      ]);
      commit("setLoader", false);
      commit("setShowQuestion", false);
    }
  },
  async fetchEvaSession({ commit }) {
    commit("setLoader", true);
    const response = await api.startEvaSession();
    if (!response) {
      commit("setFormError", "Session not initiated correctly(002). Please shift+refresh the browser.");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
      commit("setLoader", false);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
        commit("setLoader", false);
      } else {
        commit("setEvaSession", response.message);
        commit("setSessionId", response.sessionId);
        commit("setLoader", false);
      }
    }
  },
  async fetchEvaOpportunitiesSession({ commit }) {
    commit("setLoader", true);
    const response = await api.startEvaOpportunitiesSession();
    if (!response) {
      commit("setFormError", "Session not initiated correctly(003). Please shift+refresh the browser.");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
      commit("setLoader", false);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
        commit("setLoader", false);
      } else {
        commit("setEvaSession", response.message);
        commit("setSessionId", response.sessionId);
        commit("setLoader", false);
      }
    }
  },
};

const mutations = {
  pushEvaDialogues: (state, dialogues) => {
    state.evaDialogues.push(...dialogues);
  },
  setEvaSession:  (state, evaSession) => {
    state.evaSession = evaSession;
  },
  setSessionId:  (state, sessionId) => {
    state.sessionId = sessionId;
  },
  setShowQuestion:  (state, showQuestion) => {
    state.showQuestion = showQuestion;
  },
  setEvaAnnouncements:  (state, evaAnnouncements) => {
    state.evaAnnouncements = evaAnnouncements;
  },
  setEvaDialogues: (state, evaDialogues) => {
    state.evaDialogues = evaDialogues;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
