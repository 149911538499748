<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getCorporatePlanData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Plan
        </a>
      </div>
    </div>

    <div v-if="getCorporatePlanCarbonTracker()" class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
          <UpdateCorporatePlanCarbonTrackerComponent
              :id = parseInt(id)
              :current_2022 = getCorporatePlanCarbonTracker().current_2022
              :current_2023 = getCorporatePlanCarbonTracker().current_2023
              :current_2024 = getCorporatePlanCarbonTracker().current_2024
              :current_2025 = getCorporatePlanCarbonTracker().current_2025
              :current_2026 = getCorporatePlanCarbonTracker().current_2026
              :current_2027 = getCorporatePlanCarbonTracker().current_2027
              :current_2028 = getCorporatePlanCarbonTracker().current_2028
              :current_2029 = getCorporatePlanCarbonTracker().current_2029
              :current_2030 = getCorporatePlanCarbonTracker().current_2030

        />
    </div>
  </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { truncatechars, hasHistory,  } from "@/common/general.js";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import UpdateCorporatePlanCarbonTrackerComponent from "@/components/corporate_plans/UpdateCorporatePlanCarbonTrackerComponent.vue";

export default {
  name: "CorporatePlanCarbonTracker",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    NoPermissionComponent,
    UpdateCorporatePlanCarbonTrackerComponent,
  },
  data() {
    return {
      opportunity_list: true,
      climate_graphs: true,
      financial_graphs: true,
      opportunity_details: false

    }
  },
  methods: {
    ...mapGetters(["getCorporatePlanData", "getCorporatePlanCarbonTracker", "getUsername", "isLoggedIn","getFormError"]),
    ...mapActions(["fetchCorporatePlanData", "performSetFormError", "fetchCorporatePlanCarbonTracker"]),
    truncatechars: truncatechars,
    hasHistory: hasHistory,
    getCorporatePlanDetails() {
      this.fetchCorporatePlanData({"id": this.id})
    },
    getCorporatePlanCarbonTrackerDetails() {
      this.fetchCorporatePlanCarbonTracker({"id": this.id})
    }
  },
  computed: {

  },
  created() {
    this.performSetFormError(null);
    this.getCorporatePlanDetails();
    this.getCorporatePlanCarbonTrackerDetails();
    document.title = "Carbon Tracker";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getDivisionPlanDetails();
    this.getCorporatePlanCarbonTrackerDetails();
    next();
  }

}
</script>

//              :current_active = getCorporatePlanCarbonTracker().active
