<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="$router.push('/')">
          <i class="fas fa-chevron-left"></i> Back to HomePage
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <div v-if="getProfile()"  class="row plain-element">
        <ChangeUserProfileComponent
          :id = getProfile().id
          :username = getUsername()
          :current_position = getProfile().position
        />
      </div>
      <div v-if="getProfile() === null" class="card login-card">
        <div class="row plain-element text-center">
            <a @click="createProfile()" class="btn btn-login-card">Create Profile</a>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import ChangeUserProfileComponent from "@/components/users/ChangeUserProfileComponent.vue"

export default {
  name: "UserProfileView",
  components: {
    NoPermissionComponent,
    ChangeUserProfileComponent,
  },
  data() {
    return {
        position: "Energy Engineer"
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getCompanyString", "getProfile", "isLoggedIn", "getFormError"]),
    ...mapActions(["performSetFormError", "fetchUserProfile", "performCreateProfile"]),
    userProfile() {
      this.fetchUserProfile()
    },
    createProfile() {
      this.performCreateProfile()
    },
    hasHistory () {
      return window.history?.length > 1
    },
  },
  computed: {
  },
  created() {
    this.performSetFormError(null);
    this.userProfile();
    document.title = "User Profile";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.userProfile();
    next();
  }
}
</script>
