import api from "../../api/api.js"
import router from "../../router.js";

const state = {
  pdfReportList: [],
  isoChapters: null,
  pdfReportData: null,
  pdfReportId: "",
  nestedSitesAndPdfReportProjects: null,
  
};


const getters = {
  getPdfReportList: state => state.pdfReportList,
  getPdfReportData: state => state.pdfReportData,
  getPdfReportId: state => state.pdfReportId,
  getIsoChapters: state => state.isoChapters,
  getNestedSitesAndPdfReportProjects: state => state.nestedSitesAndPdfReportProjects,
};


const actions = {
  async fetchAllPdfReports({commit}) {
    commit("setLoader", true);
    const response = await api.getAllPdfReports();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setPdfReportList", response.queryset.results);
      commit("setIsoChapters", response.iso_chapters);
      commit("setResultCount", response.queryset.length);
      commit("setNestedSitesAndPdfReportProjects", response.site_code_dict);
      commit("setLoader", false);
    }
  },
  async performCreatePdfReport({commit}, payload) {
    const response = await api.pdfReportCreate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
      } else {
        router.push(`/search/pdf-reports`);
      }
    }
  },
};

const mutations = {
  setPdfReportList:  (state, pdfReportList) => {
    state.pdfReportList = pdfReportList;
  },
  setIsoChapters: (state, isoChapters) => {
    state.isoChapters = isoChapters
  },
  setPdfReportData: (state, pdfReportData) => {
    state.pdfReportData = pdfReportData;
  },
  setPdfReportId: (state, pdfReportId) => {
    state.pdfReportId = pdfReportId;
  },
  setNestedSitesAndPdfReportProjects: (state, nestedSitesAndPdfReportProjects) => {
    state.nestedSitesAndPdfReportProjects = nestedSitesAndPdfReportProjects;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}