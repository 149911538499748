<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Action
        </a>
      </div>
    </div>
    <div v-if="getActionData()" class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <AddActionCommentComponent
        :current_action_number = getActionData().action_number
        :id = id
      />
    </div>
  </div>
</div>


</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import AddActionCommentComponent from "@/components/actions/AddActionCommentComponent.vue"

export default {
  name: "ActionCommentCreate",
  components: {
      NoPermissionComponent,
      AddActionCommentComponent,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      comment : null,
    }
  },
  methods: {
    ...mapGetters(["getActionData", "getFormError", "getUsername", "isLoggedIn", "getCompanyString"]),
    ...mapActions(["fetchActionData"]),
    getActionDetails(id) {
      this.fetchActionData({"id": id })
    },
    hasHistory () {
      return window.history?.length > 1
    },
  },
  computed: {

  },
  created() {
    this.getActionDetails(this.id);
    document.title = "Add Action Comment";
    window.scrollTo(0,0);

  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getActionDetails(this.chosenId);
    console.log(this.chosenId)
    next();
  },
}

</script>