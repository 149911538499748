<template>
<div class="row plain-element">
    <div v-if="getActiveInitialLoginTab() === 'password'" class="row plain-element">
        <div v-if="getInitialLoginCredentials()" class="form-container">

            <div class="row plain-element">
              <p v-if="getFormError()" id="form-container-mfa-error-p" class="error">{{ getFormError() }}</p>
              <p v-else id="form-container-mfa-error-p" class="error"></p>
            </div>
            <div class="form-user">

                <fieldset class="form-box">
                    <div class="row plain-element center-align">
                        <h4>Change Password for</h4>
                        <h4>{{ getInitialLoginCredentials().username }}</h4>
                    </div>
                    <div class="row text-center row-form">
                        <div class="row plain-element">
                            <div class="input-field  col col-12 col-sm-12">
                                <input type="password" name="password1" id="id_password1" v-model="password1" placeholder="Password" required>
                            </div>
                        </div>
                        <div class="row plain-element">
                            <div class="input-field  col col-12 col-sm-12">
                                <input type="password" name="password2" id="id_password2" v-model="password2" placeholder="Confirm Password" required>
                            </div>
                        </div>
                    </div>
                    <button v-if="password1 === password2" type="submit" class="btn-login" @click="switchTab('mfa')"><span>Confirm</span>
                    </button>
                    <button v-else type="submit" class="btn-login" id="btn-grey" disabled><span>Confirm</span>
                    </button>
                </fieldset>

            </div>
        </div>
    </div>
    <div v-if="getActiveInitialLoginTab() === 'mfa'" class="row plain-element">
      <div class="col col-4 col-sm-4">
        <div id="container-instruction" class="container-instruction">
          <div class="row plain-element text-center">
            <h5>Get verification codes with Google Authenticator</h5>
          </div>
          <br>
          <div class="row plain-element">
            <div class="col col-12 col-sm-12">
              <p>1) Install <b>Google Authenticator</b> app on your phone from the <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">App Store</a> or
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1" target="_blank" >Google Play Store</a>.
               If you prefer, you can also choose another authenticator app you like.
              </p>
            </div>
            <div class="col col-12 col-sm-12">
              <div id="mfa-img-div"><img  alt="img" src="https://saperapublicstorage.blob.core.windows.net/companymfastatic/img/ga.png" class="img-logo"></div>
            </div>
          </div>
          <br>
          <div class="row plain-element">
            <div class="col col-12 col-sm-12">
              <p>2) Open the <b>Google Authenticator</b> app on your phone and tap the "+" button, then tap "Scan a QR code".</p>
            </div>
            <div class="col col-12 col-sm-12">
              <div id="mfa-img-div"><img id="mfa-img-logo" alt="img" src="https://saperapublicstorage.blob.core.windows.net/companymfastatic/img/plus.png" class="img-logo"></div>
            </div>
          </div>
          <br>
          <div class="row plain-element">
            <div class="col col-12 col-sm-12">
              <p>3) Scan the QR code displayed on your computer screen with your phone's camera.</p>
            </div>
            <div class="col col-12 col-sm-12">
              <div id="mfa-img-div"><img id="mfa-img-logo-md" alt="img" src="https://saperapublicstorage.blob.core.windows.net/companymfastatic/img/scan.png" class="img-logo"></div>
            </div>

          </div>
          <br>
          <p hidden>4) Enter the verification code generated by the Google Authenticator app into the box on your computer screen.</p>
        </div>
      </div>
      <div class="col col-4 col-sm-4">
        <div class="row row-break"></div>
        <div class="row plain-element">
          <p v-if="getFormError()" id="form-container-mfa-error-p" class="error">{{ getFormError() }}</p>
          <p v-else id="form-container-mfa-error-p" class="error"></p>
        </div>
        <div class="form-container form-container-mfa">
          <div id="mfa-form-user" class="form-user">
            <fieldset class="form-box">
              <div class="row plain-element center-align">
                <h5 class="underline-green">Scan QR code with Authenticator App</h5>
              </div>
              <div id="mfa-row-form" class="row text-center row-form">
                <div class="row plain-element">
                  <div class="input-field  col col-12 col-sm-12">
                    <img alt="img" :src="getQrCode()" class="w-10"/>
                  </div>
                </div>

              </div>
              <div class="row plain-element center-align">
                <h5>Then confirm by entering 6 digit code <br> from the Google Authenticator:</h5>
              </div>
              <div id="mfa-row-authenticator" class="row text-center row-form">
                <div class="row plain-element">
                  <div class="input-field  col col-12 col-sm-12">
                    <input type="text" v-model="initial_code" name="initial_code" id="initial_code" placeholder="6 digits code" minlength="6" maxlength="6" required>
                  </div>
                </div>
              </div>
              <button v-if="initial_code" @click="initialSetupCompleted()" type="submit" class="btn-login"><span>Ready</span>
              </button>
              <button v-else  type="submit" id="btn-grey" class="btn-login" disabled><span>Ready</span>
              </button>
            </fieldset>
          </div>
          <div v-if="image">
          </div>

        </div>
      </div>
      <div class="input-field  col col-4 col-sm-4">
      </div>
    </div>

</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


export default {
  name: "InitialLoginView",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      idd: 777,
      initial_code: null,
      code: null,
      password1: null,
      password2: null,
      username: null,
      image: null,

      activeTab: "password",
    }
  },
  methods: {
    ...mapGetters(["getLoginData", "getUsername", "getQrCode", "getInitialLoginCredentials", "getFormError","getActiveInitialLoginTab"]),
    ...mapActions(["fetchInitialLoginCredentials","performSetFormError", "performCompleteInitialSetup", "activateInitialLoginTab"]),

    switchTab(tab) {
      if(this.password1 === this.password2) {
        console.log("OK")
        this.activateInitialLoginTab(tab)
      } else {
        this.performSetFormError("Passwords didn't match")
      }
    },
    initialSetupCompleted() {
        if(this.password1 === this.password2) {
            this.performCompleteInitialSetup({
                "password1": this.password1,
                "password2": this.password2,
                "initial_login_id": this.id,
            })
        } else {
            this.performSetFormError("Passwords didn't match")
        }

    }
  },
  created() {
    this.fetchInitialLoginCredentials(this.id);
    this.performSetFormError(null);
    document.title = "Initial Login";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.fetchInitialLoginCredentials(this.chosenId);

    next();
  }
}
</script>