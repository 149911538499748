<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="$router.push('/')">
          <i class="fas fa-chevron-left"></i> Back to HomePage
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>


      <div  v-if="getSiteAdmin() === true" class="row plain-element">
        <div class="row plain-element">
          <div class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
              <div class="row row-functions text-start">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                  <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                       class="img responsive img-icon">
                </div>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
                  <h3> Choose User</h3>
                </div>

              </div>
            </div>
          </div>
          <p class="w-100"></p>
          <div class="row plain-element text-start">
            <div class="col col-12 col-sm-12 col-md-6 col-lg-12 text-start">
              <div class="row plain-element">
                <div v-if="getUserList()" class="row plain-element">
                  <div v-for="(name, id) in getUserList()" class="col col-12 col-sm-12 col-md-12 username-card" :key="id">
                    <div v-if="name.includes('.com')" @click="goToUserPermissions(name.split('com')[1])" class="card menu-card" id="menu-card-320">
                      <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ name.split('.com')[0] }}.com</h5>
                    </div>
                    <div v-else-if="name.includes('.ie')" @click="goToUserPermissions(name.split('ie')[1])" class="card menu-card" id="menu-card-320">
                      <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ name.split('.ie')[0] }}.ie</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row plain-element">
        <div class="row plain-element text-center">
          <p class="text-info"> Required privilege missing</p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "UserChoiceView",
  components: {
    NoPermissionComponent,

  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUsername", "getUsers", "getCompanyString", "isLoggedIn", "getFormError", "getUserPermissionsArray", "getUserList", "getSiteAdmin" ]),
    ...mapActions(["performSetFormError", "fetchUserList"]),
    hasHistory () {
      return window.history?.length > 1
    },
    goToUserPermissions(id) {
        location.href=(`/user-permissions/${id}`);
    },
  },
  computed: {
  },
  created() {
    this.performSetFormError(null);
    this.fetchUserList();
    document.title = "User Permissions";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchUserList();
    next();
  }
}
</script>
