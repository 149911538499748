<template>
  <div>
    <div v-if="topOpportunityArray[0]"
         @click="goToTopOpportunity(topOpportunityArray['id'])"
         class="card card-site-dashboard card-site-dashboard-3 clickable">
      <div class="row-card-site-dashboard-title">
        Top Opportunity
      </div>
      <div class="row-card-site-dashboard-date">
        {{topOpportunityArray[0]["seu__seu"]}}
      </div>
      <div class="row row-card-site-details-title">
        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 text-start">
          <div class="row-card-site-opportunity-details">
            CO2(T): <span class="span-opportunity-detail">{{topOpportunityArray[0]["co2_tonnes"].toLocaleString()}}</span>
          </div>
          <div class="row-card-site-opportunity-details">
            IRR(%): <span class="span-opportunity-detail">{{topOpportunityArray[0]["irr_pct"].toLocaleString()}}</span>
          </div>
        </div>
        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 text-end">
          <div class="row-card-site-opportunity-details text-end">
            Total Inv({{ symbol }}): <span class="span-opportunity-detail">{{Math.round(topOpportunityArray[0]["total_investment_eur"] * multiplier).toLocaleString()}}k</span>
          </div>
          <div class="row-card-site-opportunity-details text-end">
            Savings({{ symbol }}): <span class="span-opportunity-detail">{{Math.round(topOpportunityArray[0]["savings_eur"] * multiplier).toLocaleString()}}k</span>
          </div>
        </div>
      </div>
      <div class="row row-card-site-details-description text-start opportunity-description">
        {{topOpportunityArray[0]["description"] | truncatechars(84)}}
      </div>
    </div>
    <div v-else class="card card-site-dashboard card-site-dashboard-3">
      <div class="row-card-site-dashboard-title grey">
        No Data
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TimeToCloseCardComponent",
  props: {
    topOpportunityArray: { type: Array, required: true },
    multiplier: {type: Number, required: true },
    symbol: {type: String, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUserOverallView", "getActiveSite"]),
    ...mapActions([]),
    goToTopOpportunity(id) {
        window.open(`/opportunity/${id}`, '_blank');
    },
  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>