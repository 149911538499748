<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getSitePlanData()" class="dashboard-site">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'site-plan-search'}" class="horizontal-link">Site Plans
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'division-plan-search'}" class="horizontal-link">Division Plan Search
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'corporate-plan-search'}" class="horizontal-link">Corporate Plan Search
          </router-link>
        </li>
      </ul>
    </div>
    <div class="card card-details">
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                   class="img responsive img-icon">
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 plain-element text-start">
              <h3>{{ getSitePlanData().name }}</h3>
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 plain-element col-buttons">
              <router-link v-if="getSitePlanData()" :to="{name: 'site-plan-copy', params: {id: id}}" class="btn btn-report">
                 Copy Plan
              </router-link>
              <a :href="'/api/full-download-site-plan/' + id" class="btn btn-report">
                  Excel
              </a>
              <router-link :to="{name: 'site-plan-pdf', params: {id: id}}" class="btn btn-report">
                  PDF
              </router-link>
              <router-link v-if="getSitePlanData().opportunity_plan_relations.length > 0" :to="{name: 'kanban-board', params: {id: id}}" class="btn btn-report" hidden>
                 Kanban Board
              </router-link>
              <a v-if="getSitePlanData().plan_manager.indexOf('em3') !== -1" :href="'mailto:' + getSitePlanData().plan_manager" class="btn btn-report">
                Request Changes
              </a>
              <router-link v-if="getSitePlanData().plan_manager.indexOf('em3') === -1 && getSitePlanData().plan_manager == getUsername() " :to="{name: 'site-plan-update', params: {id: id}}" class="btn btn-report">
                Update
              </router-link>
              <router-link v-if="getSitePlanData().plan_manager.indexOf('em3') === -1 && getSitePlanData().plan_manager == getUsername() " :to="{name: 'attach-remove-opportunity', params: {id: id}}" class="btn btn-report">
                Manage Opps.
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row plain-element">
        <SitePlanTabsComponent
          :id = getSitePlanData().id.toString()
          :tab = tab
          :opportunity_plan_relations_length = getSitePlanData().opportunity_plan_relations.length
        />
      </div>
      <p v-if="tab === 'plan-details'" class="w-100"></p>
      <div v-if="tab === 'plan-details'" class="row plain-element">
        <SitePlanDetailsComponent
          :sitePlanData = getSitePlanData()
          :siteName = getSiteNames()[getSitePlanData().site_code]
        />
      </div>
      <div  v-if="tab === 'opportunities' " class="row plain-element">
        <SitePlanOpportunitiesComponent
          :id = getSitePlanData().id.toString()
          :plan_manager = getSitePlanData().plan_manager
          :seus = getSitePlanData().additional_details.seus
          :opportunities = getSitePlanData().opportunity_plan_relations
          :currency_details = getSitePlanData().currency_details
          :siteName = getSiteNames()[getSitePlanData().site_code]
          :carbonPerEuroDict = getCarbonPerEuroDict()
        />
      </div>
      <div v-if="tab === 'climate-graphs'" class="row plain-element">
        <SitePlanClimateGraphsComponent
          :id = getSitePlanData().id
          :plan_manager = getSitePlanData().plan_manager
          :opportunity_plan_relations_length = getSitePlanData().opportunity_plan_relations.length
          :carbonClimateActionPhaseWaterfallChartData = getCarbonClimateActionPhaseWaterfallChartData()
          :carbonWaterfallChartData = getCarbonWaterfallChartData()
          :carbonChartData = getCarbonChartData()
          :sitePlanCarbonTracker = getSitePlanCarbonTracker()

        />

      </div>
      <div v-if="tab === 'financial-graphs'" class="row plain-element">
        <SitePlanFinancialGraphsComponent
          :opportunity_plan_relations_length = getSitePlanData().opportunity_plan_relations.length
          :investmentWaterfallChartData = getInvestmentWaterfallChartData()
          :costSavingsWaterfallChartData = getCostSavingsWaterfallChartData()
          :investmentChartData = getInvestmentChartData()
          :financialClimateActionPhaseWaterfallChartData = getFinancialClimateActionPhaseWaterfallChartData()
          :costSavingsChartData = getCostSavingsChartData()
          :carbonPerEuroChartData = getCarbonPerEuroChartData()
          :currency_details = getSitePlanData().currency_details
        />
      </div>
      <div v-if="tab === 'custom-graphs'" class="row plain-element">
        <p class="w-100"></p>
        <div v-if="getSitePlanData().custom_graphs.length > 0" class="row plain-element">
            <div v-for="(graph, index) in getSitePlanData().custom_graphs" class=" col col-12 col-sm-12 col-md-12 col-lg-6" :key="index">
                <div class="row plain-element text-start">
                    <div id="div-width-98">
                    <div class="row plain-element text-start">
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                            <h6>{{ graph.title }}:</h6>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-end">
                            <a :href="'/graph-manage/' + graph.id"
                               class="btn btn-reorder">Manage Graph</a>
                        </div>
                        </div>
                        <div class="plan-chart">
                            <custom-waterfall-graph
                                    :chart-data='{"labels": graph.labels, "datasets":[{"backgroundColor": graph.colors, "data":graph.y, "tooltips": graph.tooltips}] }'></custom-waterfall-graph>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>


import { mapGetters, mapActions } from "vuex";

import { truncatechars } from "@/common/general.js";
import SitePlanTabsComponent from "@/components/site_plans/SitePlanTabsComponent.vue";
import SitePlanDetailsComponent from "@/components/site_plans/SitePlanDetailsComponent.vue";
import SitePlanOpportunitiesComponent from "@/components/site_plans/SitePlanOpportunitiesComponent.vue";
import SitePlanClimateGraphsComponent from "@/components/site_plans/SitePlanClimateGraphsComponent.vue";
import SitePlanFinancialGraphsComponent from "@/components/site_plans/SitePlanFinancialGraphsComponent.vue";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";


export default {
  name: "SitePlanView",
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
    }
  },


  components: {
    SitePlanTabsComponent,
    SitePlanDetailsComponent,
    SitePlanOpportunitiesComponent,
    SitePlanClimateGraphsComponent,
    SitePlanFinancialGraphsComponent,
    NoPermissionComponent,
  },
  mounted() {

  },
  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "isLoggedIn", "getCompanyString","getInvestmentWaterfallChartData",
                    "getInvestmentChartData", "getCarbonChartData","getCarbonWaterfallChartData", "getSitePlanCarbonTracker",
                    "getCostSavingsWaterfallChartData", "getCostSavingsChartData", "getCarbonClimateActionPhaseWaterfallChartData",
                    "getFinancialClimateActionPhaseWaterfallChartData", "getSiteNames", "getCarbonPerEuroChartData", "getCarbonPerEuroDict"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError","fetchSiteNames", "fetchSitePlanCarbonTracker"]),

    truncatechars: truncatechars,
    getSitePlanDetails(id) {
      this.fetchSitePlanData({"id": id })
    },
  },

  created() {
    this.fetchSiteNames();
    this.fetchSitePlanCarbonTracker({"id":this.id});
    this.getSitePlanDetails(this.id);
    this.performSetFormError(null);
    document.title = "EM3 Portal";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getSitePlanDetails(this.chosenId);
    this.fetchSitePlanCarbonTracker({"id":this.chosenId});
    next();
  }

}
</script>