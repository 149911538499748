function formatDate(value) {
      let val = (value).replace('T', ' at ')
      return val.toString().slice(0, -8)
}

function hasHistory() {
  return window.history?.length > 1
}

function truncatechars(value, limit) {
  if (value) {
    if (value.length > limit) {
      value = value.substring(0, limit) + "...";
      }
    return value
  }
}


export { formatDate, hasHistory, truncatechars };