<template>
  <div class="row plain-element">
    <div v-if="eventsArray" class="row row-events">
      <div v-if="eventsArray.length > 0" class="row plain-element">
        <div v-for="(event, index) in eventsArray" class="row plain-element" :key="index">
          <div class="card card-event" @click="goToPage(event.type, event.id)">
            <div class="row row-card-event-title">
              <div class=" col col-12 col-sm-12 col-md-7 col-lg-7 text-start">
                {{ event.identifier }}
              </div>
              <div class=" col col-12 col-sm-12 col-md-5 col-lg-5 text-end">
                {{ event.entry_date }}
              </div>
            </div>
            <div class="row row-card-event-value text-start">
              <div class=" col col-12 col-sm-12 col-md-11 col-lg-11 text-start">
                SEU: {{ event.seu }} - {{ event.entry | truncatechars(60) }}
              </div>
              <div class=" col col-12 col-sm-12 col-md-1 col-lg-1 text-end col-event-icon">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row plain-element text-center">
        <span class="margin-top-56 span-info"> No Event Data</span>
      </div>
    </div>
    <div class="row plain-element">
      <div v-if="eventsArray" class="row plain-element">
        <div v-if="eventsArray.length > 0" class="row row-scroll">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RowEventsComponent",
  props: {
    eventsArray: { type: Array, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUserOverallView", "getActiveSite"]),
    ...mapActions([]),

    goToPage(type, id) {
        if (type === "action") {
            window.open(`/action-details/${id}`, '_blank');
        } else {
            window.open(`/opportunity/${id}`, '_blank');
        }
    },
  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + "...";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>