<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
    <div v-if="getUsername()" class="row plain-element" v-cloak>
        <div class="col col-menu">
            <div class="row row-break-40"></div>
            <div class="row text-start">
                <h3> Options </h3>
            </div>
            <div class="row row-break-40"></div>
            <div class="row text-start">
                <h5> EVA Model </h5>
            </div>
            <div class="row text-start">
                <p id="eva-go-to" @click="goToActionsEva()"  onmouseover="this.style.color='#ffffff'" onmouseout="this.style.color='#5d7c99'"> Actions </p>
            </div>
            <div class="row text-start">
                <p> Opportunities </p>
            </div>
            <div class="row row-break"></div>
            <div class="row text-start">
                <h5> Parameters </h5>
            </div>
            <div class="row text-start">
                <p id="toggle-thoughts" @click="toggleThoughts()">
                    <span v-if="showThoughts">Hide Thoughts</span>
                    <span v-else>Show Thoughts</span>
                </p>
            </div>
        </div>
        <div class="col col-chat" id="dialogueContainer">
            <div class="row plain-element">
                <div class="row row-break-24"></div>
                <div class="row-model">
                    <p id="eva-form-error" class="error" v-if="getFormError()">
                        <span id="formError">{{ getFormError() }}</span>
                    </p>
                    <p class="error" v-else>
                    </p>
                    <span v-if="getSessionId() && !getFormError()">EVA Opportunities - {{  getEvaSession() }}</span>
                </div>
                <div v-if="getEvaAnnouncements()" class="row plain-element">
                  <div v-if="getEvaAnnouncements().length > 0 && getEvaDialogues().length === 0 && getLoader() == false" class="announcement-container">
                      <p v-for="(entry, index) in getEvaAnnouncements()" :key="index">{{ entry }}</p>
                  </div>
                </div>
                <div class="row plain-element">
                    <div class="row row-dialogue" v-for="(dialogue, index) in getEvaDialogues()" :key="index">
                        <div v-if="dialogue.type === 'question'" class="row row-question">
                            <div class=" col col-2 col-sm-2 col-md-1 col-lg-1 col-icon text-start">
                                <i class="fa-solid fa-user"></i>
                            </div>
                            <div class=" col col-10 col-sm-10 col-md-11 col-lg-11 col-question text-start">
                                {{ dialogue.content }}
                            </div>
                        </div>
                        <div v-if="dialogue.type === 'answer'" class="row row-answer">

                            <div class=" col col-2 col-sm-2 col-md-1 col-lg-1 col-icon text-start">
                                <img
                                        alt="img"
                                        src="https://saperapublicstorage.blob.core.windows.net/static/img/em3-small.png"
                                        class="img img-login"
                                />
                            </div>
                            <div class=" col col-10 col-sm-10 col-md-11 col-lg-11 col-question text-start">

                                <div id="eva-final-answer" v-html="convertToLinks(dialogue.content['Final Answer'])"></div>
                                <div v-if="showThoughts" class="row plain-element">
                                    <p v-for="element, index in dialogue.content['Thoughts']" class="gpt-thoughts" :key="index">{{ element }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="displayQuestion && getShowQuestion()" class="row row-dialogue">
                        <div class="row row-question">

                            <div class=" col col-2 col-sm-2 col-md-1 col-lg-1 col-icon text-start">
                                <i class="fa-solid fa-user"></i>
                            </div>
                            <div class=" col col-10 col-sm-10 col-md-11 col-lg-11 col-question text-start">
                                {{ displayQuestion }}
                            </div>
                        </div>
                    </div>
                    <div class="row" id="rowScroll"></div>
                </div>
                <div v-if="getUsername() && getLoader() === true" class="row row-gpt">
                        <div class="loader" id="loader"></div>
                    </div>
                <div v-if="getUsername() && getLoader() === false" class="row row-gpt">
                    <form @submit.prevent="askQuestion()">
                        <input class="form-control" id="gptbox" type="text" placeholder="How much total carbon can I save if I complete all the proposed opportunities?"
                               aria-label="How much total carbon can I save if I complete all the proposed opportunities?" v-model="question">
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "EvaOpportunities",
  components: {
    NoPermissionComponent,
  },
  data() {
    return {
      question: "",
      dialogues: [],
      showThoughts: true,
      displayQuestion: null,
      company: "None",
    }
  },
  methods: {
    ...mapGetters([ "getAdmin", "isLoggedIn", "getUsername", "getFormError", "getLoader", "getEvaDialogues", "getEvaSession", "getSessionId", "getShowQuestion", "getEvaAnnouncements"]),
    ...mapActions(["getUserInfo","fetchEvaAnswer", "fetchEvaOpportunitiesSession", "fetchEvaAnnouncements", "clearEvaDialogues"]),
    goToActionsEva() {
      let routeData = this.$router.resolve({ name: 'eva' });
      if (event.ctrlKey === true) {
        window.open(routeData.href, '_blank');
      } else {
        window.location.href = routeData.href;
      }
    },
    askQuestion() {
      const container = document.getElementById('dialogueContainer');
      container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      const scroller = document.getElementById('rowScroll');
      scroller.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      if (this.question.length > 8) {
        if (this.dialogues.length === 0) {
          this.displayQuestion = this.question
        } else {
          this.displayQuestion = null
        }
        this.fetchEvaAnswer({ question: this.question, sessionId: this.getSessionId() });
        this.question = ""; // Clear the input field after asking a question.
      } else {
        this.question = "";
      }

    },
    toggleThoughts() {
      if (this.showThoughts) {
        this.showThoughts = false
      } else {
        this.showThoughts = true
      }
    },
    convertToLinks(text) {
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Regular expression to match trailing punctuation
      const trailingPunctuationPattern = /[.,;:!?]$/;

      return text.replace(urlPattern, (match) => {
        // Remove trailing punctuation if it exists
        const url = match.replace(trailingPunctuationPattern, '');

        // Create the hyperlink with the cleaned URL
        return `<a href="${url}" target="_blank">${url}</a>`;
      });
    },
  },
  computed: {

  },
  mounted() {
  },
  created() {
    document.title =  "EM3 Portal";
    window.scrollTo(0,0);
    this.clearEvaDialogues();
    this.getUserInfo();
    this.fetchEvaAnnouncements();
    this.fetchEvaOpportunitiesSession();
  },
  beforeRouteUpdate (to, from, next) {
    window.scrollTo(0,0);
    this.clearEvaDialogues();
    this.getUserInfo();
    this.fetchEvaAnnouncements();
    this.fetchEvaOpportunitiesSession();
    next();
  }
}

</script>

