<template>
<form @submit.prevent="createActionComment()" enctype="multipart/form-data">
    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
        <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                     class="img responsive img-icon">
            </div>
            <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                <h3 class="font-dark-grey">Add Comment for {{ action_number }} </h3>
            </div>
            <div v-if="getLoader() === false" class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                <button type="submit" class="btn btn-report">
                    Add Comment
                </button>
            </div>
        </div>
    </div>
    <div v-if="getLoader() === true" class="row">
        <div class="loader" id="loader"></div>
    </div>
    <div v-else class="row plain-element text-start">
        <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 text-start">
            <div class="row plain-element">
                <h6 class="font-dark-grey">Comment</h6>
                <div class="row plain-element text-start">
                    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-form">
                            <textarea rows="5" cols="40" name="comment" v-model="comment"  maxlength="1000">

                              </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</template>

<script>

import { mapGetters, mapActions } from "vuex";



export default {
  name: "AddActionCommentComponent",
  components: {

  },
  props: {
    current_action_number: { type: String, required: false },
    id: { type: String, required: false },
  },
  data() {
    return {
        action_number: this.current_action_number,
        comment: null,
      }
  },

  methods: {
    ...mapGetters(["getCompanyString", "getLoader"]),
    ...mapActions([ "performCreateActionComment","fetchActionData", "performSetLoader"]),
    getActionDetails(id) {
      this.fetchActionData({"id": id })
    },
    createActionComment() {
      this.performSetLoader(true);
      this.performCreateActionComment({
        "id": this.id,
        "action_number" : this.action_number,
        "comment": this.comment,
      })
    },
  },
  computed: {

  },
  created() {

  }


}
</script>


