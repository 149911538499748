import api from "../../api/api.js"

const state = {
  company_name: null,
  company_string: null,
};


const getters = {
  getCompanyName: state => state.company_name,
  getCompanyString: state => state.company_string
};


const actions = {
  async getCompanyInfo({ commit }) {
    const response = await api.companyInfo();
    if (!response)  {
      console.log("No company details present")
    }
    else {
      commit('setCompanyName', response.company_name);
      commit('setCompanyString', response.company_string);
    }
  },
};

const mutations = {
  setCompanyName: (state, company_name) => {
    state.company_name = company_name
  },
  setCompanyString: (state, company_string) => {
    state.company_string = company_string
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}