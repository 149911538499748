<template>
<form @submit.prevent="updateOpportunityList()" enctype="multipart/form-data">
  <div v-if="getOpportunities()" class="row plain-element">
    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="row row-functions text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
          <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
               class="img responsive img-icon">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
          <h3> Attach/Remove Opportunity for {{ name }} </h3>
        </div>
        <div  v-if="getLoader() === false" class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element col-buttons">

          <button type="submit" class="btn btn-report">
            Update Opportunities
          </button>
        </div>
      </div>
    </div>
  </div>
    <div class="row plain-element">

        <div class="row plain-element">
          <div v-if="getNestedSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">SEU</label>
              </div>
              <select v-model="seu" name="seu" id="seu" @change="clearSearch()">
                <option value="" selected> All SEU</option>
                <option v-for="(element, index) in getNestedSitesAndSeu()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>

          <div class=" col col-12 col-sm-12 col-md-5 col-lg-3 plain-element text-end">
            <div v-if="getOpportunities()" class="input-field  col col-12 col-sm-12 col-form">
              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
          <div v-if="getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
          </div>
          <div v-if="getOpportunities()  && getLoader() === false" class="row plain-element">
            <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
              <h6 style="color: #005591; font-size:12px"> {{ keywordFilteredList.length }} Opportunities</h6>
            </div>
            <div class="col col-sm-6 col-md-6 col-lg-6 plain-element">
            </div>
          </div>
          <p class="w-100"></p>
          <div id="parent">
            <div id="child">
              <table v-if="getOpportunities() && getLoader() === false"  id="searchTable">
                <thead v-if="keywordFilteredList.length > 0">
                <tr>
                  <th class="text-center th-description">Attach</th>
                  <th  class="text-start" @click="sortTableNew(1)">Opportunity No.
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th  class="text-start" @click="sortTableNew(2)">Site
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th  class="text-start" @click="sortTableNew(3)">SEU
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th  class="text-start" @click="sortTableNew(4)">Climate Action Phase
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th class="text-center th-description">Description</th>
                  <th @click="sortTableNew(6)" class="text-center">Status
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th @click="sortTableCurrencyInt(7)" class="text-end">Savings(€)
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th @click="sortTableCurrencyInt(8)" class="text-end">Total Inv.
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th @click="sortTableCurrencyInt(9)" class="text-end">CO2
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                  <th @click="sortTableCurrencyInt(10)" class="text-end">Payback
                    <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="opportunity in keywordFilteredList" :key="opportunity.id" @click="checkBox(opportunity.opportunity_number)">
                    <td class="text-center field-number">
                      <label v-if="newOpportunityArray.includes(opportunity.opportunity_number)" @click="checkBox(opportunity.opportunity_number)">
                        <input type="checkbox" v-model="newOpportunityArray" :value="opportunity.opportunity_number" :id="opportunity.opportunity_number" checked="checked"/>
                      </label>
                      <label v-else @click="checkBox(opportunity.opportunity_number)">
                        <input type="checkbox" v-model="newOpportunityArray" :value="opportunity.opportunity_number" :id="opportunity.opportunity_number" />
                      </label>
                    </td>

                    <td class="text-start field-number">{{opportunity.opportunity_number}}</td>
                    <td class="text-start field-number">{{opportunity.site_code.site_code}}</td>
                    <td class="text-start field-number">{{opportunity.seu}}</td>
                    <td class="text-start field-text">{{opportunity.climate_action_phase}}</td>
                    <td class="text-start field-description">{{truncatechars(opportunity.description, 240)}}</td>
                    <td class="text-center field-number">{{opportunity.status}}</td>
                    <td class="text-end field-number">{{opportunity.savings_eur.toLocaleString()}}</td>
                    <td class="text-end field-number">{{opportunity.total_investment_eur.toLocaleString()}}</td>
                    <td class="text-end field-number">{{opportunity.co2_tonnes.toLocaleString()}}</td>
                    <td class="text-end field-number">{{opportunity.payback_yrs}}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="getOpportunities().length > 0" class="border-line"></div>
            </div>
          </div>
          <div v-if="resultCount && keywordFilteredList.length > 200" class="row plain-element">
            <button v-show="next" @click="submitQuery" class="btn btn-loading">
              Load More
            </button>
          </div>
          <div v-if="resultCount && keywordFilteredList.length === 0" class="row plain-element">
            <h6>No results match search criteria</h6>
          </div>
        </div>
        <div v-if="getOpportunities().length === 0" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <p class="w-100"></p>
          <span class="span-info"> No Opportunities available</span>
        </div>
    </div>
</form>
</template>

<script>
import { sortTableCurrencyInt, sortTableNew } from "@/common/tables.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AttachRemovePlanOpportunityComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    site: {
      type: String,
      required: true,
    },
    opportunityArray: {
      type: Array,
      required: true,
    }


  },
  components: {

  },
  data() {
    return {
      newOpportunityArray: this.opportunityArray,
      seu: "",
      resultCount: null,
      search: "",
    }
  },
  methods: {
    ...mapGetters(["getOpportunities",  "getCompanyString","getOpportunitiesSites",
                  "getResultCount", "getLoader", "getNestedSitesAndSeu"]),
    ...mapActions(["performSetFormError", "performCreateOpportunityPlanRelation", "fetchOpportunitiesQueryset",
                  "fetchPlansSites","fetchNestedSitesAndSeu"]),
    sortTableNew: sortTableNew,
    sortTableCurrencyInt: sortTableCurrencyInt,
    getOpportunityList(site) {
      this.fetchOpportunitiesQueryset({"searchQuery": site, "queryType": "site_code"});
    },
    updateOpportunityList() {
      this.performCreateOpportunityPlanRelation({"id": this.id,  "opportunity_array" : this.newOpportunityArray})
    },
    checkBox(opportunity) {
      console.log(this.opportunityArray);
      {
        var box = document.getElementById(opportunity)
        box.click()
      }
    },

    //  Just in case if some strings would be too long and would destroy a layout
    truncatechars(value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit) + "...";
          }
          return value
      }
    },
    clearSeu() {
        if (this.site === "") {
          this.seu = ""
          this.search = ""
        } else {
          this.seu = ""
          this.search = ""
        }
    },
    clearSearch() {
       this.search = "";
    },
  },
  
  computed: {
    filteredList() {
        if (this.seu !== ""  && this.site !== "" ) {
            return this.getOpportunities().filter(opportunity => {
              return opportunity.site_code.site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.seu.toLowerCase().includes(this.seu.toLowerCase())
            })
        } else if (this.seu === "" && this.site === "") {
            return this.getOpportunities().filter(opportunity => {
              return opportunity.site_code.site_code.toLowerCase().includes("")
            })
        } else if (this.seu === "" && this.site !== "" ) {
            return this.getOpportunities().filter(opportunity => {
              return opportunity.site_code.site_code.toLowerCase() === this.site.toLowerCase()
            })
        } else {
          return this.getOpportunities()
        }
    },
      keywordFilteredList() {
      return this.filteredList.filter(opportunity => {
        return opportunity.site_code.site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               opportunity.seu.toLowerCase().includes(this.search.toLowerCase()) ||
               opportunity.description.toLowerCase().includes(this.search.toLowerCase())
      })
      }

  },
  created() {
    this.getOpportunityList(this.site);
    this.performSetFormError(null);
    this.fetchPlansSites();
    this.fetchNestedSitesAndSeu(this.site);
    document.title = "Attach/Remove Opportunity";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenSite = to.params.site;
    this.chosenSiteId = to.params.site_id;
    this.getOpportunityList(this.chosenSite);
    next();
  }

}
</script>
