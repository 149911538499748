import api from "../../api/api.js"

const state = {
  eeos_rates: null,
};


const getters = {
  getEeosRates: state => state.eeos_rates,
};


const actions = {
  async fetchEeosRates({ commit }, payload) {
    const response = await api.eeosRates(payload);
    if (!response)  {
      console.log("No eeos rates present")
    }
    else {
      commit('setEeosRates', response.results);
    }
  },
};

const mutations = {
  setEeosRates: (state, eeos_rates) => {
    state.eeos_rates = eeos_rates
  },

};


export default {
  state,
  getters,
  actions,
  mutations
}