<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
    <div id="capacity-tool-dashboard" v-if="getUsername() && getNestedRatedCapacities()" class="dashboard">
        <div class="row text-start row-back">
            <a href="/">
                <i class="fas fa-chevron-left"></i> Back To Homepage
            </a>
        </div>
        <div class="row row-opportunities row-capacity">
            <div class="row plain-element row-break-24">
                <p class="error" v-if="getFormError()">
                    <span id="formError">{{ getFormError() }}</span>
                </p>
            </div>
            <form @submit.prevent="displayData()">
                <div class="row plain-element">
                    <div v-if="getNestedRatedCapacities()"
                         class=" col col-12 col-sm-12 col-md-6 col-lg-2 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-8 col-form">
                            <div class="row plain-element text-start">
                                <label class="label-search">Site Code</label>
                            </div>
                            <select v-model="site_code" name="site_code" id="site_code" @change="clearMeters()"
                                    required>
                                <option value="" selected> Select Site</option>
                                <option v-for="(key, value, index) in getNestedRatedCapacities()" :value="value" :key="index">
                                    {{ getSiteNames()[value] }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="getNestedRatedCapacities()"
                         class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-md-10 col-form">
                            <div class="row plain-element text-start">
                                <label class="label-search">Meter</label>
                            </div>
                            <select v-model="meter_tag" name="meter_tag" id="meter_tag" required>
                                <option value="" selected> Select Meter</option>
                                <option v-for="(element, index) in getNestedRatedCapacities()[site_code]" :key="index">
                                    {{ element }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="getNestedRatedCapacities()"
                         class=" col col-12 col-sm-12 col-md-6 col-lg-2 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-md-10 col-form">
                            <div class="row plain-element text-start">
                                <label class="label-search">Start Date</label>
                            </div>
                            <datepicker v-model="start_date" :format="customFormatter"></datepicker>
                        </div>
                    </div>
                    <div v-if="getNestedRatedCapacities()"
                         class=" col col-12 col-sm-12 col-md-6 col-lg-2 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-md-10 col-form">
                            <div class="row plain-element text-start">
                                <label class="label-search">End Date</label>
                            </div>
                            <datepicker v-model="end_date" :format="customFormatter"></datepicker>
                        </div>
                    </div>
                    <div v-if="getNestedRatedCapacities()" class="col col-sm-6 col-md-6 col-lg-2 plain-element">
                        <div class="input-field  col col-12 col-sm-12 col-md-6 col-lg-12 col-form">
                            <button type="submit" class="btn btn-dashboard">
                                Load
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <br/>
        <div v-if="getUsername() && getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
        </div>
        <div v-if="getCapacityTableData() && getLoader() === false" class="row row-opportunities row-capacity">
            <div class=" col col-12 col-sm-12 col-md-8 col-lg-8 plain-element">
                <table class="table-metrics">
                    <thead>
                        <tr>
                            <th>Equipment</th>
                            <th class="text-center">Rated Capacity (n+1)</th>
                            <th class="text-center">Current Max Load</th>
                            <th class="text-center">New Max Load</th>
                            <th class="text-center">Free Capacity</th>
                        </tr>
                    </thead>

                    <tbody v-if="getCapacityTankTable()">
                        <tr v-for="row in getCapacityTableData()" :key="row.equipment_name">
                            <td class="text-start field-opportunity">{{ row.equipment_name }}</td>
                            <td class="text-center field-number">{{ row.rated_capacity }}</td>
                            <td class="text-center field-number">{{ row.current_max }}</td>
                            <td class="text-center field-number">{{ calculateNewMaxLoad() }}</td>
                            <td class="text-center field-number">
                                <span v-if="(row.rated_capacity - calculateNewMaxLoad()) < 0" class="font-red">{{ row.rated_capacity - calculateNewMaxLoad() }}</span>
                                <span v-else class="font-green">{{ row.rated_capacity - calculateNewMaxLoad() }}</span>
                            </td>
                        </tr>
                        <tr v-for="row in getCapacityTankTable()" :key="row.equipment_name">
                            <td class="text-start field-opportunity">{{ row.equipment_name }}</td>
                            <td class="text-center field-number">{{ row.rated_capacity }}</td>
                            <td class="text-center field-number">{{ row.current_max }}%</td>
                            <td class="text-center field-number">{{ calculateNewMaxTankLoad() }}%</td>
                            <td class="text-center field-number">
                                <span v-if="(row.rated_capacity - calculateNewMaxLoad()) < 0" class="font-red">{{ row.rated_capacity - calculateNewMaxLoad() }}</span>
                                <span v-else class="font-green">{{ row.rated_capacity - calculateNewMaxLoad() }}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="row in getCapacityTableData()" :key="row.equipment_name">
                            <td class="text-start field-opportunity">{{ row.equipment_name }}</td>
                            <td class="text-center field-number">{{ row.rated_capacity }}</td>
                            <td class="text-center field-number">{{ row.current_max }}</td>
                            <td class="text-center field-number">{{ maximumLoad }}</td>
                            <td class="text-center field-number">
                                <span v-if="(row.rated_capacity - calculateNewMaxLoad()) < 0" class="font-red">{{ row.rated_capacity - maximumLoad }}</span>
                                <span v-else class="font-green">{{ row.rated_capacity - maximumLoad }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class=" col col-12 col-sm-12 col-md-1 col-lg-1 plain-element">

            </div>
            <div class=" col col-12 col-sm-12 col-md-3 col-lg-2 plain-element">
                <div class="row">
                    <div class="row plain-element text-start"><label class="label-search">Add Capacity Change</label></div>
                        <div class="input-field col col-sm-4 col-md-8 col-lg-9 col-value">
                            <input id="change" type="number" name="change" min="-10000" max="10000" step="1" v-model="change"
                                   required="required" class="validate"  @change="calculateNewMaxLoadAndUpdateChart(); ">
                        </div>
                        <div id="capacity-tool-input" class="input-field col col-sm-12 col-md-12 col-lg-1 col-value"></div>
                        <div class="input-field col col-sm-8 col-md-6 col-lg-2">
                            <div  v-if="getCapacityUnits()" class="input-field col col-sm-8 col-md-6 col-lg-3">
                                <div class="form-check">
                                  <input class="form-check-input" type="radio" name="capacityLabel" id="capacityLabel" @change="changeLabel('%')" checked>
                                  <label class="form-check-label" for="capacityLabel1">
                                    %
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input  @change="changeLabel(getCapacityUnits())" class="form-check-input" type="radio" name="capacityLabel" id="capacityLabel">
                                  <label class="form-check-label" for="capacityLabel2">
                                    {{ getCapacityUnits() }}
                                  </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <br/>
        <div v-if="getCurrentMax() && getLoader() === false" class="row row-opportunities row-capacity text-start">
            <div class="row plain-element">
                <button @click="downloadGraph()" class="btn btn-report" id="capacity-tool-download">
                    Download Graph
                </button>
            </div>
            <div v-if="getCapacityTankData()" class="chart">
                <capacity-tank-graph :chart-data="getCapacityChartData()" :styles="capacityChartStyles" id="chartCanvas"></capacity-tank-graph>
            </div>
            <div v-else class="chart">
                <capacity-graph :chart-data="getCapacityChartData()" :styles="capacityChartStyles" id="chartCanvas"></capacity-graph>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

import CapacityGraph from "@/common/graphs/CapacityGraph.js";
import CapacityTankGraph from "@/common/graphs/CapacityTankGraph.js";


export default {
  name: "CapacityTool",
  components: {
    NoPermissionComponent,
    Datepicker,
    CapacityGraph,
    CapacityTankGraph,
  },
  data() {
    return {
      error: null,
      site_code: "",
      meter_tag: "",
      start_date: "",
      end_date: "",
      format: "yyyy-MM-dd",
      selectedLabel: "%",
      change: 0,
    }
  },

  methods: {
    ...mapGetters([ "getUsername", "isLoggedIn", "getNestedRatedCapacities","getLoader", "getCapacityTankData",
                    "getFormError", "getCapacityChartData", "getCapacityChartDataset", "getCapacityTableData", "getCapacityTankTable",
                    "getCapacityUnits", "getCurrentMax","getSiteNames",

                    ]),
    ...mapActions(["getUserInfo", "fetchNestedRatedCapacities", "performSetFormError", "performGetCapacity", "updateCapacityChart", "fetchSiteNames"]),
    updateChart(val) {
        this.updateCapacityChart({"dataset": this.getCapacityChartDataset(), "change": val, "change_type": this.selectedLabel, "change_factor": this.change })
    },
    changeLabel(newValue) {
      this.selectedLabel = newValue;
      this.calculateNewMaxLoad();
      this.updateChart(this.maximumLoad);

    },
    clearMeters() {
        if (this.site_code === "") {
          this.meter_tag = "";
        } else {
          this.meter_tag = ""
        }
    },
    clearChange() {
        this.change = 0;
    },
    customFormatter(date) {
      return moment(date).format('yyyy-MM-DD');
    },
    downloadGraph() {
        let canvas = document.getElementById('line-chart').toDataURL('image/png')
        let link = document.createElement('a')
        link.download = 'image'
        link.href = canvas
        link.click()
    },
    displayData() {
        if ( !this.start_date || !this.end_date ) {
            window.console.log("Date is missing")
            this.performSetFormError("Date is missing")
        } else {
           this.clearChange();
           this.performGetCapacity({
                "site_code": this.site_code,
                "meter_tag": this.meter_tag,
                "start_date": this.parseStartDate,
                "end_date": this.parseEndDate,
           })
        }
    },
    calculateNewMaxLoad() {
        if (this.change !== 0) {
            if (this.selectedLabel === "%") {
                return Math.round((parseInt(this.getCurrentMax()) * parseInt(this.change)) / 100 +  parseInt(this.getCurrentMax()))
            } else {
               return parseInt(this.getCurrentMax()) + parseInt(this.change)
            }
        }  else {
            return this.getCurrentMax()
        }

    },
    calculateNewMaxTankLoad() {
        if (this.change !== 0) {
            if (this.selectedLabel === "%") {
                return Math.round((parseInt(this.getCurrentMax()) * parseInt(this.change)) / 100 +  parseInt(this.getCurrentMax())) / 10
            } else {
               return (parseInt(this.getCurrentMax()) + parseInt(this.change)) / 10
            }
        }  else {
            return this.getCurrentMax() / 10
        }
    },
    calculateNewMaxLoadAndUpdateChart() {
        this.calculateNewMaxLoad();
        this.updateChart(this.maximumLoad);
    }
  },
  computed: {
    maximumLoad() {
        return this.calculateNewMaxLoad();
    },
    capacityChartStyles () {
      return {
            position: "relative",

        }
    },
    date() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    parseStartDate() {
      var d = this.start_date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return year.toString() + "-" + month + "-" + day;
    },
    parseEndDate() {
      var d = this.end_date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return year.toString() + "-" + month + "-" + day;
    },

  },
  created() {
    document.title =  "Capacity  Tool";
    window.scrollTo(0,0);
    this.fetchSiteNames();
    this.fetchNestedRatedCapacities();

  },

}

</script>