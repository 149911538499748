<template>
  <div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
    <div v-if="getUsername() && getSitesAndSeuAndOpp()" class="dashboard" v-cloak>
      <div class="row plain-element top-menu">
        <ul>
          <li>
            <router-link  :to="{name: 'home'}" class="horizontal-link">Home</router-link>
          </li>
          <li>
            <router-link  :to="{name: 'opportunity-search'}" class="horizontal-link">Opportunity Search</router-link>
          </li>
        </ul>
      </div>
    <p class="w-100"></p>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error" v-else>

      </p>
      <form @submit.prevent="createOpportunity()" enctype="multipart/form-data">
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
            <div class="row row-functions text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'" class="img responsive img-icon">
              </div>
              <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                <h3 class="font-dark-grey">Create Opportunity</h3>
              </div>
              <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                <button type="submit" class="btn btn-report">
                  Create
                </button>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-start">
              <div class="row plain-element text-start">
                <div class=" col col-12 col-sm-12 col-md-8 col-lg-6 plain-element">
                  <div class="row plain-element">
                    <div class="input-field  col col-12 col-sm-12">
                        <label class="energy-audit">
                          <input id="energy_audit" name="energy_audit" v-model="energy_audit" value="energy_audit" type="checkbox">
                          <span class="label-checkbox font-dark-grey"> Energy Audit </span>
                        </label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="getNestedSitesAndSeu()" class="row plain-element text-start">
                <h6 class="font-dark-grey">
                  Site
                  <span class="font-14">*</span>

                </h6>
                <div class=" col col-12 col-sm-12 col-md-8 col-lg-6 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <select v-model="site_code" name="site_code" id="site_code" required>
                      <option v-for="(seus, site) in getNestedSitesAndSeu()" :value="site" :key="site">
                        {{ site }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <div v-if="getNestedSitesAndSeu()" class="row plain-element text-start">
                <h6 class="font-dark-grey">
                  SEU
                  <span class="font-14">*</span>

                </h6>
                <div class=" col col-12 col-sm-12 col-md-8 col-lg-6 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <select v-model="seu" name="seu" id="seu" required>
                      <option v-for="(element, index) in getNestedSitesAndSeu()[site_code]" :value="element" :key="index">
                        {{ element }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row plain-element opportunity-description text-start">
                <h6 class="font-dark-grey">
                  Project Length Estimate

                </h6>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form">
                        <textarea rows="1" cols="30" name="length_estimate" v-model="length_estimate"></textarea>
                  </div>
                </div>
              </div>
              <div class="row plain-element opportunity-description text-start">
                <h6 class="font-dark-grey tooltiped">
                  Opportunity Link
                  <span class="font-14">*</span>
                  <span class="tooltiptext">Copy link to SharePoint folder containing calculation sheet</span>
                </h6>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form">
                        <textarea rows="1" cols="30" name="link" v-model="link" required></textarea>
                  </div>
                </div>
              </div>

              <div class="row plain-element opportunity-description text-start">
                <h6 class="font-dark-grey">
                  Description
                  <span class="font-14">*</span>

                </h6>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                  <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form">
                        <textarea rows="4" cols="30" name="description" v-model="description" required></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-8">
              <div class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                  <div class="row plain-element text-start">
                    <h6 class="font-dark-grey">
                      Energy Metrics
                      <span class="font-14">*</span>

                    </h6>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                        MWHE:

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="mwhe" type="number" name="mwhe" v-model="mwhe" class="validate" min="-10000000" max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                        MWHT:

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="mwht" type="number" name="mwht" v-model="mwht" class="validate" min="-10000000" max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                        Water (m³):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="water_m3" type="number" name="water_m3" v-model="water_m3" class="validate" min="-10000000" max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-6 ">
                        CO2(Tonnes):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="co2_tonnes" type="number" name="co2_tonnes" v-model="co2_tonnes" class="validate" min="-10000000"
                               max="10000000"
                               step=".01">
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-end">
                  <div class="row plain-element text-start">
                    <h6 class="font-dark-grey">
                      Financial Metrics
                      <span class="font-14">*</span>

                    </h6>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                        Grant (EUR):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="grant_eur" type="number" name="grant_eur" v-model="grant_eur" class="validate" min="-10000000"
                               max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                        Savings (EUR):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="savings_eur" type="number" name="savings_eur" v-model="savings_eur" class="validate" min="-10000000"
                               max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                        Total Investment (EUR):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="total_investment_eur" type="number" name="total_investment_eur" v-model="total_investment_eur"
                               class="validate" min="-10000000"
                               max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                        Capital Investment (EUR):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="capital_investment_eur" type="number" name="capital_investment_eur"
                               v-model="capital_investment_eur" class="validate"
                               min="-10000000" max="10000000"
                               step=".01">
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col col-sm-8 col-md-6 col-lg-8 ">
                        Expenses (EUR):

                      </div>
                      <div class="input-field col col-sm-4 col-md-6 col-lg-4 col-value">
                        <input id="expenses_eur" type="number" name="expenses_eur" v-model="expenses_eur" class="validate" min="-10000000"
                               max="10000000"
                               step=".01">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";



export default {
  name: "OpportunityCreate",
  components: {
    NoPermissionComponent,
  },
  data() {
    return {
      opportunity_number: null,
      energy_audit: false,
      site_code : null,
      seu : null,
      length_estimate: null,
      description: null,
      link: null,
      mwhe: null,
      mwht: null,
      water_m3: null,
      irr_pct: null,
      co2_tonnes: null,
      grant_eur: null,
      savings_eur: null,
      total_investment_eur: null,
      capital_investment_eur: null,
      expenses_eur: null,
    }
  },

  methods: {
    ...mapGetters(["getSitesAndSeuAndOpp", "getNestedSitesAndSeu","getFormError", "getUsername", "isLoggedIn", "getCompanyString"]),
    ...mapActions(["fetchSitesAndSeuAndOpp", "fetchNestedSitesAndSeu", "performOpportunityCreate", "performSetFormError"]),

    createOpportunity() {


//    Check for duplicate
      if ((this.getSitesAndSeuAndOpp().opportunity_list).includes(this.opportunity_number)) {
        window.console.log("Opportunity number '" + this.opportunity_number + "' already exists.")
        this.performSetFormError("Opportunity number '" + this.opportunity_number + "' already exists.")

      } else {
//      Check if Financials and Energies !== 0
        if ( this.mwhe + this.mwht + this.water_m3 + this.co2_tonnes === 0 ) {
          window.console.log("Sum of Energy Metrics can't be 0.")
          this.performSetFormError("Sum of Energy Metrics can't be 0.")
        } else {
          if ( this.grant_eur + this.savings_eur + this.total_investment_eur + this.capital_investment_eur + this.expenses_eur + this.payback_yrs  === 0 ) {
            window.console.log("Sum of Financial Metrics can't be 0.")
            this.performSetFormError("Sum of Financial Metrics can't be 0.")
          } else {
              var site_id = this.getSitesAndSeuAndOpp()["sites"].find(x => x.site_code === this.site_code).id

              this.performOpportunityCreate({
                           "opportunity_number": this.site_code + "-" + this.opportunityNumberDate + "-" + this.randomInt + "O",
                           "energy_audit": this.energy_audit,
                           "site_code": site_id,
                           "seu": this.seu,
                           "area": "Other",
                           "length_estimate": this.length_estimate || "-",
                           "link": this.link,
                           "description": this.description,
                           "mwhe": this.mwhe || 0,
                           "mwht": this.mwht || 0,
                           "water_m3": this.water_m3 || 0,
                           "co2_tonnes": this.co2_tonnes || 0,
                           "grant_eur": this.grant_eur || 0,
                           "savings_eur": this.savings_eur || 0,
                           "total_investment_eur": this.total_investment_eur || 0,
                           "capital_investment_eur" : this.capital_investment_eur || 0,
                           "expenses_eur" : this.expenses_eur || 0,
                           "date": this.date,
                          })
              }
          }

        }


    },
    hasHistory () {
      return window.history?.length > 1
    },
  },
  computed: {
    date() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    opportunityNumberDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return day + month + year.toString().slice(2,4);

    },
    randomInt() {
      return Math.floor(Math.random() * (999 - 100) + 100)
    }



  },
  created() {
    this.fetchSitesAndSeuAndOpp();
    this.fetchNestedSitesAndSeu();
    this.performSetFormError(null);
    document.title = "Create Opportunity";
    window.scrollTo(0,0);
  }
}


</script>
