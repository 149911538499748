<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getNestedSitesAndPdfReportProjects()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'energy-guide-search'}" class="horizontal-link underline-green">Energy Guides
          </router-link>
        </li>
      </ul>
    </div>
  <div class="row plain-element">
    <div class="row row-opportunities">
                <div class="row plain-element">
          <div v-if="getNestedSitesAndPdfReportProjects()" class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site Code</label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearProjects()">
                <option value="" selected> All Sites</option>
                <option v-for="(key, value, index) in getNestedSitesAndPdfReportProjects()" :key="index">
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getNestedSitesAndPdfReportProjects()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Project</label>
              </div>
              <select v-model="project_number" name="project_number" id="project_number" @change="clearSearch()">
                <option value="" selected> All Projects</option>
                <option v-for="(element, index) in getNestedSitesAndPdfReportProjects()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>

          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
            <div v-if="getNestedSitesAndPdfReportProjects()" class="input-field  col col-12 col-sm-12 col-form">
              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
        </div>
      <p class="w-100"></p>
      <div v-if="getUsername() && getLoader() === true" class="row">
        <div class="loader" id="loader"></div>
      </div>
      <div v-if="getPdfReportList()  && getLoader() === false" class="row plain-element">
        <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
          <h6> {{ isoFilteredList.length }} Results</h6>
        </div>
      </div>
      <p class="w-100"></p>
      <div id="parent">
        <div id="child">
          <table v-if="getPdfReportList() && getLoader() === false"  id="searchTable">
            <thead v-if="isoFilteredList.length < 100 && isoFilteredList.length > 0">
              <tr>
                <th @click="sortTableNew(0)" class="text-start">Site Code
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(1)" class="text-center">Project Number
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(2)" class="text-center">Title
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(3)" class="text-center">ISO50k1 Chapter
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(4)" class="text-center">Uploaded
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(5)" class="text-center">User
                  <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                  </span>
                </th>
                <th @click="sortTableNew(6)" class="text-center">Download
                  <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <thead v-else-if="isoFilteredList.length > 100">
              <tr>
                <th class="text-start">Site Code
                </th>
                <th class="text-center">Project Number
                </th>
                <th class="text-center">Title
                </th>
                <th class="text-center">ISO50k1 Chapter
                </th>
                <th class="text-center">Uploaded
                </th>
                <th class="text-center">User
                </th>
                <th class="text-center">Download
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in isoFilteredList" :key="report.id">
                <td class="text-start field-number">{{report.site_code.site_code}}</td>
                <td class="text-center field-number">{{report.project_number}}</td>
                <td class="text-center field-number">{{report.filename | truncatechars(80) | normalize() }}</td>
                <td class="text-center field-number">{{report.iso_chapter.chapter }}</td>
                <td class="text-center field-number">{{report.date.slice(0,10)}}</td>
                <td class="text-center field-number">{{report.user}}</td>
                <td class="text-center field-number">
                  <a id="btn-inline-download" class="btn btn-report" :href="'/documents/pdf-report-download/' + report.filename">
                  <i class="fas fa-file-download"></i>&nbsp; Download
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

</template>

<script>
import { sortTableNew } from "@/common/tables.js";
import { truncatechars } from "@/common/general.js";
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "PdfReportsStandardSearch",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      active: "opportunities",
      siteList: null,
      searchQuery: null,
      site: "",
      project_number: "",
      iso_chapter: "",
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getNestedSitesAndPdfReportProjects", "isLoggedIn", "getLoader", "getResultCount", "getPdfReportList", "getIsoChapters", "getSiteNames" ]),
    ...mapActions([ "performSetFormError", "fetchAllPdfReports", "performSetFormError", "fetchSiteNames"]),
    sortTableNew: sortTableNew,
    getAllPdfReportList() {
      this.fetchAllPdfReports();
    },
    hasHistory () {
      return window.history?.length > 1
    },
    clearProjects() {
        if (this.site === "") {
          this.project_number = ""
          this.status = ""
          this.search = ""
          this.iso_chapter = ""
        } else {
          this.project_number = ""
          this.status = ""
          this.search = ""
          this.iso_chapter = ""
        }
    },
    clearSearch() {
       this.search = "";
    },
  },
  computed: {
    filteredList() {
        if (this.project_number !== ""  && this.site !== "") {
            return this.getPdfReportList().filter(report => {
              return report.project_number.toLowerCase().includes(this.project_number.toLowerCase())
            })
        } else if (this.site !== ""  && this.project_number === "") {
            return this.getPdfReportList().filter(report => {
              return report.site_code.site_code.toLowerCase() === this.site.toLowerCase()
            })
        } else {
          return this.getPdfReportList()
        }
    },
    keywordFilteredList() {
      return this.filteredList.filter(report => {
        return report.site_code.site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               report.project_number.toLowerCase().includes(this.search.toLowerCase()) ||
               report.filename.toLowerCase().includes(this.search.toLowerCase()) ||
               report.iso_chapter__chapter.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    isoFilteredList() {
      return this.keywordFilteredList.filter(report => {
          return report.iso_chapter__chapter === "000"
      })
    }
  },
  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatechars: truncatechars,
    normalize(value) {
      if (value) {
        value = value.replace("_", " ").replace(".pdf", "")
      }
      return value
    }
  },
  created() {
    this.performSetFormError(null);
    this.fetchSiteNames();
    this.getAllPdfReportList();
    document.title = "Search Energy Guides";
    window.scrollTo(0,0);
  },

}
</script>

