<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()"  class="dashboard-cards">
      <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getOpportunitiesSites()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link  :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link  :to="{name: 'site-reports'}" class="horizontal-link">Site Reports</router-link>
        </li>
      </ul>
    </div>
    <div class="row row-title">
      <div class="page-title-container">
        <h3 class="underline-green underline-green-small">
          Site Reports
        </h3>
      </div>
    </div>
    <div class="row row-search">
      <div class="row plain-element text-start">
        <div v-if="getOpportunitiesSites().sites" class="row plain-element">
            <div v-if="getOpportunitiesSites().sites.length > 0" class="row row-opportunities">
              <div  class="row row-cards">
                  <div v-for="(site, index) in getOpportunitiesSites().sites" :key="index" class="col col-sm-3 col-md-3 col-lg-2 plain-element">
                    <router-link :to="{ name: 'site-view', params: {id: site.site_code__id.toString() }}">
                      <div class="card site-card">
                        {{ site.site_code__site_code }}
                      </div>
                    </router-link>
                </div>
              </div>
            </div>
            <div v-else  class="row plain-element text-center">
                <p class="w-100"></p>
                <h6>No results match search criteria</h6>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "SiteReports",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      searchQuery: null,
      active: "sites",
      siteList: null,

    }
  },

  methods: {
    ...mapGetters(["getOpportunitiesSites", "getFormError", "getUsername", "isLoggedIn"]),
    ...mapActions(["fetchSitesAndSeu", "performSetFormError", "fetchOpportunitiesSites"]),
  },
  computed: {
    filteredSiteList() {
      return this.getOpportunitiesSites().sites.filter(site => {
        return site.site_code__site_code.toLowerCase().includes(this.search.toLowerCase())
      })

    }
  },
  created() {
    this.fetchOpportunitiesSites();
    this.performSetFormError(null);
    document.title = "Site Reports";
    window.scrollTo(0,0);
  },
  mounted() {

  },
  beforeCreated() {

  }
}
</script>
