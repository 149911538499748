import api from "../../api/api.js";
import router from "../../router.js";

const state = {
  enpiList: [],
  enpiData: null,
  enpiId: "",
  nestedEnpi: null,
  siteSeuEnpi: null,
  siteEnpi: null,
};


const getters = {
  getEnpiList: state => state.enpiList,
  getEnpiData: state => state.enpiData,
  getEnpiId: state => state.enpiId,
  getNestedEnpi: state => state.nestedEnpi,
  getSiteSeuEnpi: state => state.siteSeuEnpi,
  getSiteEnpi: state => state.siteEnpi,
};


const actions = {
  async fetchAllEnpi({commit}) {
    commit("setLoader", true);
    const response = await api.getAllEnpi();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setEnpiList", response.queryset);
      commit("setResultCount", response.queryset.length);
      commit("setLoader", false);
    }
  },
  async fetchEnpiData({commit}, payload) {
    const response = await api.enpiData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setEnpiData", response);
    }
  },
  async fetchSiteEnpi({commit}, payload) {
    const response = await api.siteEnpi(payload.site_code);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
    } else {
      console.log(response);
      commit("setSiteEnpi", response);
    }
  },
  async fetchNestedEnpi({commit}) {
    const response = await api.nestedEnpi();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedEnpi", response.queryset);
    }
  },
  async fetchSiteSeuEnpi({commit}, payload) {
    const response = await api.siteSeuEnpi(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setSiteSeuEnpi", response.results);
    }
  },
  async performCreateEnpi({commit}, payload) {
    const response = await api.enpiCreate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setFormError", response.error);
      } else {
        router.push(`/`);
      }
    }
  }

}
const mutations = {
  setEnpiList:  (state, enpiList) => {
    state.enpiList = enpiList;
  },
  setEnpiData: (state, enpiData) => {
    state.enpiData = enpiData;
  },
  setEnpiId: (state, enpiId) => {
    state.enpiId = enpiId;
  },
  setNestedEnpi: (state, nestedEnpi) => {
    state.nestedEnpi = nestedEnpi;
  },
  setSiteSeuEnpi: (state, siteSeuEnpi) => {
    state.siteSeuEnpi = siteSeuEnpi;
  },
  setSiteEnpi: (state, siteEnpi) => {
    state.siteEnpi = siteEnpi;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
}