<template>
<form @submit.prevent="changeUserProfile()" enctype="multipart/form-data">
  <div class="row plain-element">
    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="row row-functions text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
          <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
               class="img responsive img-icon">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
          <h3> Your Profile  </h3>
        </div>
        <div v-if="current_position !== position " class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element col-buttons">
          <button type="submit" class="btn btn-report">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <p class="w-100"></p><p class="w-100"></p>
  <div class="row plain-element text-start">
    <div class="col col-12 col-sm-12 col-md-6 col-lg-3 text-start">
      <div class="row plain-element">
        <h6 class="font-dark-grey">Position</h6>
        <div class="row plain-element text-start">
          <div class="col col-12 col-sm-12 col-md-12 col-lg-8 plain-element">
            <div class="input-field col col-12 col-sm-12 col-form">
              <select v-model="position">
                <option>Data Scientist</option>
                <option>Energy Engineer</option>
                <option>Project Engineer</option>
                <option>Project Manager</option>
                <option>Senior Energy Management Consultant</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</template>

<script>

import { mapGetters, mapActions } from "vuex";


export default {
  name: "ChangeUserProfileComponent",
  props: {
    id: { type: Number, required: true },
    username: { type: String, required: true },
    current_position: { type: String, required: true },
  },
  data() {
    return {
        position: this.current_position,
      }
  },

  methods: {
    ...mapGetters(["getProfile", "getCompanyString", "getUsername"]),
    ...mapActions(["performSetFormError", "performUpdateUserProfile"]),
    changeUserProfile() {
     this.performUpdateUserProfile({
        "id": this.id,
        "username": this.username,
        "position": this.position
     })
    },
  },
  computed: {
  },
  created() {
  }
}
</script>


