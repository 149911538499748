<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getDivisionPlanData()" class="dashboard-site">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'division-plan-search'}" class="horizontal-link">Division Plan Search
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'division-plan-create'}" class="horizontal-link">Create Division Plan</router-link>
        </li>
      </ul>
    </div>
    <div class="card card-details">
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                   class="img responsive img-icon">
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element text-start">
              <h3 v-if="getDivisionPlanData().name.length < 46"  >{{ truncatechars(getDivisionPlanData().name, 50) }}</h3>
              <h4 v-else  id="h4-line-height-32">{{ truncatechars(getDivisionPlanData().name, 60) }}</h4>
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-7 plain-element col-buttons">
              <a :href="'/api/full-download-division-plan/' + id" class="btn btn-report">
                  Excel
              </a>
              <router-link v-if="getDivisionPlanData().plan_manager ===  getUsername()"  :to="{name: 'division-plan-update', params: {id: id}}" class="btn btn-report">
                Update
              </router-link>
              <router-link v-if="getDivisionPlanData().plan_manager ===  getUsername()" :to="{name: 'attach-remove-division-site-plan', params: {id: id}}" class="btn btn-report">
                Attach Plans
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row plain-element">
        <DivisionPlanTabsComponent
          :id = getDivisionPlanData().id.toString()
          :tab = tab
          :opportunity_plan_relations_length = getDivisionPlanData().opportunity_plan_relations.opportunity_plan_relations.length
        />
      </div>
      <p v-if="tab==='plan-details'" class="w-100"></p>
      <div v-if="tab==='plan-details'" class="row plain-element">
        <DivisionPlanDetailsComponent
          :divisionPlanData = getDivisionPlanData()
        />
      </div>
      <div  v-if="tab === 'opportunities' " class="row plain-element">
        <DivisionPlanOpportunitiesComponent
          :id = getDivisionPlanData().id.toString()
          :site_seus = getDivisionPlanData().opportunity_plan_relations.nested_site_seu
          :opportunities = getDivisionPlanData().opportunity_plan_relations.opportunity_plan_relations
          :currency_details = getDivisionPlanData().currency_details
          :carbonPerEuroDict = getCarbonPerEuroDict()
          :currency = getDivisionPlanData().currency
          :siteNames = getSiteNames()
        />
      </div>
      <div  v-if="tab === 'site-plans' " class="row plain-element">
        <DivisionPlanSitePlansComponent
          :id = getDivisionPlanData().id.toString()
          :site_plans = getDivisionPlanData().division_site_plan_relations.division_site_plan_relations
          :opportunities = getDivisionPlanData().opportunity_plan_relations.opportunity_plan_relations
          :currency_details = getDivisionPlanData().currency_details
          :carbonPerEuroDict = getCarbonPerEuroDict()
          :currency = getDivisionPlanData().currency
          :siteNames = getSiteNames()
          :plan_manager = getDivisionPlanData().plan_manager
        />
      </div>
      <div v-if="tab === 'financial-graphs'" class="row plain-element">
        <DivisionPlanFinancialGraphsComponent
          :opportunity_plan_relations_length = getDivisionPlanData().opportunity_plan_relations.opportunity_plan_relations.length
          :investmentWaterfallChartData = getInvestmentWaterfallChartData()
          :costSavingsWaterfallChartData = getCostSavingsWaterfallChartData()
          :investmentChartData = getInvestmentChartData()
          :financialClimateActionPhaseWaterfallChartData = getFinancialClimateActionPhaseWaterfallChartData()
          :costSavingsChartData = getCostSavingsChartData()
          :carbonPerEuroChartData = getCarbonPerEuroChartData()
          :currency_details = getDivisionPlanData().currency_details
        />
      </div>
      <div v-if="tab === 'climate-graphs'" class="row plain-element">
        <DivisionPlanClimateGraphsComponent
          :opportunity_plan_relations_length = getDivisionPlanData().opportunity_plan_relations.opportunity_plan_relations.length
          :carbonClimateActionPhaseWaterfallChartData = getCarbonClimateActionPhaseWaterfallChartData()
          :carbonWaterfallChartData = getCarbonWaterfallChartData()
          :carbonChartData = getCarbonChartData()
          :divisionPlanCarbonTracker = getDivisionPlanCarbonTracker()
          :plan_manager = getDivisionPlanData().plan_manager
          :siteNames = getDivisionPlanData().site_names
          :site_carbon_reduction_per_climate_action_phase = getDivisionPlanData().site_carbon_reduction_per_climate_action_phase
          :site_total_carbon_chart_data = getDivisionPlanData().site_total_carbon_chart_data
          :id = getDivisionPlanData().id
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {  sortTableNew, sortOppTableCurrencyInt, sortPlanTable, sortPlanTableCurrencyInt } from "@/common/tables.js";
import { mapGetters, mapActions } from "vuex";
import { hasHistory, truncatechars } from "@/common/general.js";

import DivisionPlanTabsComponent from "@/components/division_plans/DivisionPlanTabsComponent.vue";
import DivisionPlanDetailsComponent from "@/components/division_plans/DivisionPlanDetailsComponent.vue";
import DivisionPlanOpportunitiesComponent from "@/components/division_plans/DivisionPlanOpportunitiesComponent.vue";
import DivisionPlanSitePlansComponent from "@/components/division_plans/DivisionPlanSitePlansComponent.vue";
import DivisionPlanFinancialGraphsComponent from "@/components/division_plans/DivisionPlanFinancialGraphsComponent.vue";
import DivisionPlanClimateGraphsComponent from "@/components/division_plans/DivisionPlanClimateGraphsComponent.vue";

import NoPermissionComponent from "@/components/NoPermissionComponent.vue";


export default {
  name: "DivisionPlanView",
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      activeTab: "planDetails",
    }
  },
  components: {
    DivisionPlanTabsComponent,
    DivisionPlanDetailsComponent,
    DivisionPlanOpportunitiesComponent,
    DivisionPlanSitePlansComponent,
    DivisionPlanFinancialGraphsComponent,
    DivisionPlanClimateGraphsComponent,
    NoPermissionComponent,

  },
  mounted() {

  },
  methods: {
    ...mapGetters(["getDivisionPlanData", "getUsername", "isLoggedIn", "getCompanyString","getInvestmentWaterfallChartData",
                    "getInvestmentChartData", "getCarbonChartData","getCarbonWaterfallChartData", "getCostSavingsChartData","getDivisionPlanCarbonTracker",
                    "getCostSavingsWaterfallChartData", "getCostSavingsChartData", "getCarbonClimateActionPhaseWaterfallChartData",
                    "getFinancialClimateActionPhaseWaterfallChartData", "getSiteNames", "getCarbonPerEuroChartData","getSiteNames",
                    "getCarbonPerEuroDict"]),
    ...mapActions(["fetchDivisionPlanData", "performSetFormError", "fetchDivisionPlanCarbonTracker", "performSetSiteSpecificAnnualWaterfall", "fetchSiteNames"]),
    truncatechars: truncatechars,
    hasHistory: hasHistory,
    sortTableNew: sortTableNew,
    sortPlanTable: sortPlanTable,
    sortPlanTableCurrencyInt: sortPlanTableCurrencyInt,
    sortOppTableCurrencyInt: sortOppTableCurrencyInt,

    getDivisionPlanDetails(id) {
      this.fetchDivisionPlanData({"id": id })
    },


  },
  computed: {

  },
  created() {
    this.getDivisionPlanDetails(this.id);
    this.fetchDivisionPlanCarbonTracker({"id":this.id});
    this.performSetFormError(null);
    this.fetchSiteNames()
    document.title = "Division Plan Details";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getDivisionPlanDetails(this.chosenId);
    this.fetchDivisionPlanCarbonTracker({"id":this.chosenId});
    next();
  }

}
</script>
