<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Plan
        </a>
      </div>
      <div class="card card-description">
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
            <form method="POST" class="form-report" enctype="multipart/form-data" id="report-form" @submit.prevent="printToPDF()">
              <div class="row plain-element text-start">
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <h4> Generate PDF Report</h4>
                </div>
              </div>
              <div class="row plain-element text-start">
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <label>
                    <input type="checkbox" name="opportunity_list" v-model="opportunity_list" checked>
                    <span class="label-checkbox">Opportunity List</span>
                  </label>
                </div>
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <label>
                    <input type="checkbox" name="climate_graphs" v-model="climate_graphs" checked>
                    <span class="label-checkbox">Climate Graphs</span>
                  </label>
                </div>
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <label>
                    <input type="checkbox" name="financial_graphs" v-model="financial_graphs" checked>
                    <span class="label-checkbox">Financial Graphs</span>
                  </label>
                </div>
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <label>
                    <input type="checkbox" name="macc_graphs" v-model="macc_graphs" checked>
                    <span class="label-checkbox">MACC Graph</span>
                  </label>
                </div>
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <label>
                    <input type="checkbox" name="additional_macc_graphs" v-model="additional_macc_graphs">
                    <span class="label-checkbox">Additional MACC Graphs</span>
                  </label>
                </div>
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <label>
                    <input type="checkbox" name="opportunity_details" v-model="opportunity_details">
                    <span class="label-checkbox">Opportunity Details Pages(long)</span>
                  </label>
                </div>
              </div>
              <br>
              <br>
              <div class="row plain-element text-start">

              </div>

              <div class="row plain-element text-start">
                <div class="input-field  col col-12 col-sm-12 col-form" id="input-field-pdf-report">
                  <button id="buttonSubmit" type="submit" class="btn btn-submit">
                    <i class="fas fa-file-download"></i>&nbsp; Download Report
                  </button>
                </div>
              </div>
            </form>
            <br>
            <p class="error" v-if="getFormError()">
              <span id="formError">{{ getFormError() }}</span>
            </p>
            <p class="error" v-else>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"


export default {
  name: "SitePlantoPDF",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    NoPermissionComponent,
  },
  data() {
    return {
      opportunity_list: true,
      climate_graphs: true,
      financial_graphs: true,
      macc_graphs: true,
      additional_macc_graphs: false,
      opportunity_details: false


    }
  },
  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "isLoggedIn","getFormError"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError"]),
    hasHistory () {
      return window.history?.length > 1
    },
    truncatechars (value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit) + "...";
          }
          return value
      }
    },
    printToPDF() {
      window.open(`/html/site-plan-pdf-report-download/${this.id}/${this.opportunity_list}/${this.climate_graphs}/${this.financial_graphs}/${this.macc_graphs}/${this.additional_macc_graphs}/${this.opportunity_details}`, '_blank');
    }
  },
  computed: {

  },
  created() {
    this.performSetFormError(null);
    document.title = "Print to PDF";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    next();
  }

}
</script>
