<template>
    <div class="row plain-element">
      <div class="col col-4 col-sm-4">
        <div id="container-instruction" class="container-instruction">
          <div class="row plain-element text-center">
            <h5>Get verification codes with Google Authenticator</h5>
          </div>
          <br>
          <div class="row plain-element">
            <div class="col col-12 col-sm-12">
              <p>1) Install <b>Google Authenticator</b> app on your phone from the <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">App Store</a> or
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1" target="_blank" >Google Play Store</a>.
               If you prefer, you can also choose another authenticator app you like.
              </p>
            </div>
            <div class="col col-12 col-sm-12">
              <div id="mfa-img-div"><img  alt="img" src="https://saperapublicstorage.blob.core.windows.net/companymfastatic/img/ga.png" class="img-logo"></div>
            </div>
          </div>
          <br>
          <div class="row plain-element">
            <div class="col col-12 col-sm-12">
              <p>2) Open the <b>Google Authenticator</b> app on your phone and tap the "+" button, then tap "Scan a QR code".</p>
            </div>
            <div class="col col-12 col-sm-12">
              <div id="mfa-img-div"><img id="mfa-img-logo" alt="img" src="https://saperapublicstorage.blob.core.windows.net/companymfastatic/img/plus.png" class="img-logo"></div>
            </div>
          </div>
          <br>
          <div class="row plain-element">
            <div class="col col-12 col-sm-12">
              <p>3) Scan the QR code displayed on your computer screen with your phone's camera.</p>
            </div>
            <div class="col col-12 col-sm-12">
              <div id="mfa-img-div"><img id="mfa-img-logo-md" alt="img" src="https://saperapublicstorage.blob.core.windows.net/companymfastatic/img/scan.png" class="img-logo"></div>
            </div>

          </div>
          <br>
          <p hidden>4) Enter the verification code generated by the Google Authenticator app into the box on your computer screen.</p>
        </div>
      </div>
      <div class="col col-4 col-sm-4">
        <div class="form-container form-container-mfa">
              <p id="form-container-mfa-error-p" class="error">
          </p>
          <div id="mfa-form-user" class="form-user">
            <fieldset class="form-box">
              <div class="row plain-element center-align">
                <h5 class="underline-green">Scan QR code with Authenticator App</h5>
              </div>
              <div id="mfa-row-form" class="row text-center row-form">
                <div class="row plain-element">
                  <div class="input-field  col col-12 col-sm-12">
                    <img alt="img" :src="getQrCode()" class="w-10"/>
                  </div>
                </div>

              </div>
              <div class="row plain-element center-align">
                <h5>Then confirm by entering 6 digit code <br> from the Google Authenticator:</h5>
              </div>
              <div id="mfa-row-authenticator" class="row text-center row-form">
                <div class="row plain-element">
                  <div class="input-field  col col-12 col-sm-12">
                    <input type="text" v-model="initial_code" name="initial_code" id="initial_code" placeholder="6 digits code" minlength="6" maxlength="6" required>
                  </div>
                </div>
              </div>
              <button v-if="initial_code" @click="mfaSetup()" type="submit" class="btn-login"><span>Ready</span>
              </button>
              <button v-else  type="submit" id="btn-grey" class="btn-login" disabled><span>Ready</span>
              </button>
            </fieldset>
          </div>
          <div v-if="image">
          </div>

        </div>
      </div>
      <div class="input-field  col col-4 col-sm-4">
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import qrcode from "qrcode";

export default {
  name: "MfaSetupview",
  data() {
    return {
      idd: 777,
      initial_code: null,
      code: null,
      password: null,
      username: null,
      image: null,
    }
  },
  methods: {
    ...mapGetters(["getLoginData", "getUsername", "getQrCode"]),

    mfaSetup() {
            location.href=(`/login`)
    },
    cody() {
      qrcode.toDataURL("otpauth://totp/EM3%20SAPERA:Secret?secret=HFLMQ66DZE5WKITBC4TLOOQ3Q6B5A3R5&issuer=EM3%20SAPERA", (err, data) => {
          this.image = data;
        });
    },

  }
}
</script>