import api from "../../api/api.js"
import router from "../../router.js"

const state = {

  sitePlans: [],
  archivedSitePlans: [],
  sitePlanData: null,
  opportunityArray: null,
  sitePlanCarbonTracker: null,
};


const getters = {
  getSitePlans: state => state.sitePlans,
  getArchivedSitePlans: state => state.archivedSitePlans,
  getSitePlanData: state => state.sitePlanData,
  getSitePlanCarbonTracker: state => state.sitePlanCarbonTracker,
};


const actions = {
// SITE
  async fetchAllSitePlans({commit}) {
    commit("setLoader", true);
    const response = await api.getAllSitePlans();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setSitePlans", response.queryset);
      commit("setArchivedSitePlans", response.queryset_archive);
      commit("setResultCount", response.queryset.length );
      commit("setLoader", false);
    }
  },
// SITE
  async fetchSitePlanData({commit}, payload) {
    const response = await api.sitePlanData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setSitePlanData", response);
      commit("setOpportunityArray", response.additional_details.opportunity_plan_relations);

// SITE
//      Investment per Project Chart
      var investmentChartDataset = {
                labels: response.additional_details.investment_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.investment_per_project_chart_data[2],
                              data: response.additional_details.investment_per_project_chart_data[1],
                              tooltips: response.additional_details.investment_per_project_chart_data[3],
                              currencies: response.additional_details.investment_per_project_chart_data[4],
                          }]
            };
      commit("setInvestmentChartData", investmentChartDataset);
// SITE
//            Total investment Waterfall Chart
      var investmentWaterfallChartDataset  =  {
          labels: response.additional_details.total_investment_chart_data[0],
          datasets: [{
                        backgroundColor: response.additional_details.total_investment_chart_data[2],
                        data: response.additional_details.total_investment_chart_data[1],
                        tooltips: response.additional_details.total_investment_chart_data[4],
                        currencies: response.additional_details.total_investment_chart_data[5],
                    }]
      };
      commit("setInvestmentWaterfallChartData", investmentWaterfallChartDataset);
// SITE
//    Carbon  Climate Action Phase Waterfall
      var carbonClimateActionPhaseWaterfallChartDataset = {
                labels: response.additional_details.carbon_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.additional_details.carbon_climate_action_phase_chart_data[2],
                            data: response.additional_details.carbon_climate_action_phase_chart_data[1],
                            tooltips: response.additional_details.carbon_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setCarbonClimateActionPhaseWaterfallChartData", carbonClimateActionPhaseWaterfallChartDataset);
// SITE
//    Financial  Climate Action Phase Waterfall
      var financialClimateActionPhaseWaterfallChartDataset = {
                labels: response.additional_details.financial_climate_action_phase_chart_data[0],
                datasets:  [{
                            backgroundColor: response.additional_details.financial_climate_action_phase_chart_data[2],
                            data: response.additional_details.financial_climate_action_phase_chart_data[1],
                            tooltips: response.additional_details.financial_climate_action_phase_chart_data[4],
                            currencies: response.additional_details.financial_climate_action_phase_chart_data[5],
                          }]
      };
      commit("setFinancialClimateActionPhaseWaterfallChartData", financialClimateActionPhaseWaterfallChartDataset);

// SITE
//      Carbon per project chart
      var carbonChartDataset = {
                labels: response.additional_details.carbon_per_project_chart_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.carbon_per_project_chart_data[2],
                              data: response.additional_details.carbon_per_project_chart_data[1],
                              tooltips: response.additional_details.carbon_per_project_chart_data[3],
                          }]
            };
      commit("setCarbonChartData", carbonChartDataset);
// SITE
//      Carbon Waterfall Chart
      var carbonWaterfallChartDataset = {
           labels: response.additional_details.total_carbon_chart_data[0],
           datasets: [{
                      data: response.additional_details.carbon_tracker,
                      borderColor: "#ff7f0f",
                      borderWidth: 4,
                      type: 'line',
                      order: 0,
                      pointRadius: 1,
                    },
                    {
                     backgroundColor: response.additional_details.total_carbon_chart_data[2],
                     data: response.additional_details.total_carbon_chart_data[1],
                     tooltips: response.additional_details.total_carbon_chart_data[5],
                    }],
      };
      commit("setCarbonWaterfallChartData", carbonWaterfallChartDataset);
// SITE
//      Cost Savings Waterfall Chart
      var costSavingsWaterfallChartDataset = {
           labels: response.additional_details.total_cost_savings_data[0],
           datasets: [{
                         backgroundColor: response.additional_details.total_cost_savings_data[2],
                         data: response.additional_details.total_cost_savings_data[1],
                         tooltips: response.additional_details.total_cost_savings_data[5],
                         currencies: response.additional_details.total_cost_savings_data[6],
                     }],
      };
      commit("setCostSavingsWaterfallChartData", costSavingsWaterfallChartDataset);
// SITE
//    Cost Savings per Project chart
      var costSavingsChartDataset = {
                labels: response.additional_details.cost_savings_per_project_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.cost_savings_per_project_data[2],
                              data: response.additional_details.cost_savings_per_project_data[1],
                              tooltips: response.additional_details.cost_savings_per_project_data[3],
                              currencies: response.additional_details.cost_savings_per_project_data[4],
                          }]
            };
      commit("setCostSavingsChartData", costSavingsChartDataset);
// SITE
//      Carbon per Euro chart
      var carbonPerEuroChartDataset = {
                labels: response.additional_details.carbon_per_euro_data[0],
                datasets: [{
                              backgroundColor: response.additional_details.carbon_per_euro_data[2],
                              data: response.additional_details.carbon_per_euro_data[1],
                              tooltips: response.additional_details.carbon_per_euro_data[3],
                              currencies: response.additional_details.carbon_per_euro_data[4],
                          }]
            };
      commit("setCarbonPerEuroChartData", carbonPerEuroChartDataset);
// SITE
//      Carbon per Euro
      if (response.additional_details.carbon_per_euro_data.length > 0) {
        var carbonPerEuroDict = response.additional_details.carbon_per_euro_data[5];
        commit("setCarbonPerEuroDict", carbonPerEuroDict);
      } else {
        commit("setCarbonPerEuroDict", {});
      }


    }
  },
// SITE
  async performCreateSitePlan({commit}, payload) {
    commit("setLoader", true);
    const response = await api.sitePlanCreate(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setLoader", false);
        commit("setFormError", response.error);
      } else {
        commit("setLoader", false);
        router.push(`/site-plan-search`);
      }
    }
  },
// SITE
  performSitePlanUpdate({commit}, payload) {
    const response = api.sitePlanUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/plan-success/${payload.id}`);
      }
  },
// SITE
  performSitePlanCopy({commit}, payload) {
    const response = api.sitePlanCopy(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/site-plan-search`);
      }
  },
// SITE
  performSitePlanPublish({commit}, payload) {
    const response = api.sitePlanPublish(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/plan-success/${payload.id}`);
      }
  },
// SITE
  performUpdatePlanDates({commit}, payload) {
    const response = api.updatePlanDates(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {

      router.push(`/update-success/${payload.id}`);
    }
  },
// SITE
  async performCreateOpportunityPlanRelation({commit}, payload) {
    commit("setLoader", true);
    const response = await api.createOpportunityPlanRelation(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setLoader", false);
      router.push(`/plan-success/${payload.id}`);
    }
  },
// SITE
  async performReorderPlan({commit}, payload) {
    const response = await api.planReorder(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/plan-success/${payload.id}`);
      }
  },
// SITE
  async fetchSitePlanCarbonTracker({commit}, payload) {
    const response = await api.sitePlanCarbonTracker(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setSitePlanCarbonTracker", response.carbon_trackers);
      commit("setLoader", false);
    }
  },
// SITE
  async performSitePlanCarbonTrackerUpdate({commit}, payload) {
    commit("setLoader", true);
    const response = await api.sitePlanCarbonTrackerUpdate(payload)
    if (!response)  {
      commit("setLoader", false);
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      if (response.error) {
        commit("setLoader", false);
        commit("setFormError", response.error);
      } else {
        commit("setLoader", false);
        router.push(`/update-success/${payload.id}`);
      }
    }
  },
};


const mutations = {
  setSitePlans:  (state, sitePlans) => {
    state.sitePlans = sitePlans;
  },
  setArchivedSitePlans:  (state, archivedSitePlans) => {
    state.archivedSitePlans = archivedSitePlans;
  },
  setSitePlanData: (state, sitePlanData) => {
    state.sitePlanData = sitePlanData
  },
  setSitePlanCarbonTracker: (state, sitePlanCarbonTracker) => {
    state.sitePlanCarbonTracker = sitePlanCarbonTracker;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}