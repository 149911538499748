import qrcode from "qrcode";
import api from "../../api/api.js"

const state = {
  loginData: {"id": 0},
  qrCode: null,
  initialLoginCredentials: null,
  activeInitialLoginTab: "password"
};

const getters = {
  getLoginData: state => state.loginData,
  getQrCode: state => state.qrCode,
  getInitialLoginCredentials: state => state.initialLoginCredentials,
  getActiveInitialLoginTab: state => state.activeInitialLoginTab,
};


const actions = {
  updateLoginData({commit}, payload) {
    commit("setLoginData", payload);
  },
  activateInitialLoginTab({commit}, payload) {
    commit("setActiveInitialLoginTab", payload);
  },
  updateQrCode({commit}, payload) {
    var image = null
    qrcode.toDataURL(payload, (err, data) => {
      image = data;
    });
    commit("setQrCode", image);
  },
  async fetchInitialLoginCredentials({commit, dispatch}, payload) {
    const response = await api.initialLoginCredentials(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setInitialLoginCredentials", response.credentials);
      dispatch ("updateQrCode", response.credentials.otpauth_url);

      commit("setLoader", false);
    }
  },
  async performCompleteInitialSetup({commit}, payload) {
    const response = await api.completeInitialSetup(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
       if (response.error) {
        console.log(response.error)
        commit("setFormError", response.error);
        commit("setActiveInitialLoginTab", "password")
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
        commit("setLoader", false);
      } else {
        commit("setLoader", false);
        window.location.href = "/";
      }
    }
  },
};

const mutations = {
  setLoginData: (state, loginData) => {
    state.loginData = loginData;
  },
  setActiveInitialLoginTab: (state, activeInitialLoginTab) => {
    state.activeInitialLoginTab = activeInitialLoginTab;
  },
  setQrCode: (state, qrCode) => {
    state.qrCode = qrCode;
  },
  setInitialLoginCredentials: (state, initialLoginCredentials) => {
    state.initialLoginCredentials = initialLoginCredentials;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}