<template>
    <div v-if="macc_carbon_offsetting_cost" class="row plain-element">
        <form @submit.prevent="updatePlan()" enctype="multipart/form-data">
            <div class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                    <div class="row row-functions text-start">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                            <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png'"
                                 class="img responsive img-icon">
                        </div>
                        <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                            <h3 class="font-dark-grey">Update Ref. Lines for {{ getCorporatePlanData().name }}</h3>
                        </div>
                        <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                            <button type="submit" class="btn btn-report">
                                Submit Update
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row plain-element text-start">
                    <div class=" col col-12 col-sm-12 col-md-6 col-lg-7 plain-element text-end">
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                                <div class="row plain-element text-start">
                                    <h6>
                                        Carbon Offsetting Cost:
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                            Tonnes:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-5 col-value">
                                            <input id="macc_carbon_offsetting_cost" type="number" name="macc_carbon_offsetting_cost"
                                                   v-model="macc_carbon_offsetting_cost" class="validate" min="-10000000" max="1000000000"
                                                   step="01" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="w-100"> </p>
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                                <div class="row plain-element text-start">
                                    <h6>
                                        Tonnes Per Investment Benchmark:
                                    </h6>
                                    <div class="row">
                                        <div class="input-field col col-sm-8 col-md-6 col-lg-3 ">
                                            Tonnes:
                                        </div>
                                        <div class="input-field col col-sm-4 col-md-6 col-lg-5 col-value">
                                            <input id="macc_carbon_per_money" type="number" name="macc_carbon_per_money"
                                                   v-model="macc_carbon_per_money" class="validate" min="-10000000" max="1000000000"
                                                   step="0.1" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </form>
    </div>
</template>


<script>
import { validateString, } from "@/common/validation.js";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "UpdateCorporatePlanRefLineComponent",
  props: {
    id: { type: String, required: true },
    current_name: { type: String, required: false },
    current_description: { type: String, required: false },
    current_currency: { type: String, required: false },
    current_company: { type: String, required: false },

    current_macc_carbon_offsetting_cost: { type: String, required: false },
    current_macc_carbon_per_money: { type: String, required: false },
  },
  data() {
    return {
      name: this.current_name,
      description: this.current_description,
      company: this.current_company,
      currency: this.current_currency,
      macc_carbon_offsetting_cost: this.current_macc_carbon_offsetting_cost,
      macc_carbon_per_money: this.current_macc_carbon_per_money,
    }
  },

  methods: {
    ...mapGetters(["getCorporatePlanData", "getUsername", "getCompanyString", "getCurrencies"]),
    ...mapActions(["performCorporatePlanUpdate", "performSetFormError"]),
    validate: validateString,
    updatePlan() {
      if (this.validate(this.name) === "error" ||
          this.validate(this.description) === "error"
      )  {
            this.performSetFormError("Forbidden character at the start of a string(+,=,-,@)");
      } else {
      this.performCorporatePlanUpdate({
                               "id": this.id,
                               "name": this.validate(this.name),
                               "description": this.validate(this.description),
                               "currency": this.currency,
                               "company": this.company,
                               "macc_carbon_offsetting_cost": this.macc_carbon_offsetting_cost,
                               "macc_carbon_per_money": this.macc_carbon_per_money,
                              })
      }
    },
  },
  computed: {
  },
  created() {

  }


}
</script>