<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'corporate-plan-search'}" class="horizontal-link">Corporate Plan Search
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'corporate-plan-create'}" class="horizontal-link underline-green">Create Corporate Plan</router-link>
        </li>
      </ul>
    </div>
    <div class="card card-details">
      <p class="error" v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error" v-else>
      </p>
      <form @submit.prevent="createCorporatePlan()" enctype="multipart/form-data">
        <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
            <div class="row row-functions text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                     class="img responsive img-icon">
              </div>
              <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                <h3 class="font-dark-grey">Create Corporate Plan</h3>
              </div>
              <div  v-if="getLoader() === false" class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                <button type="submit" class="btn btn-report">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
        </div>
        <div v-if="getCompanies() && getLoader() === false" class="row plain-element text-start">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
            <div class="row plain-element opportunity-description text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="row text-start">
                  <h6 class="font-dark-grey">Company<span class="font-14">*</span></h6>
                  <div class=" col col-12 col-sm-12 col-md-11 col-lg-8 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-form">
                      <select v-model="company" name="company" id="company" required>
                        <option v-for="(company, index) in getCompanies()" :value="company.company_name" :key="index">
                          {{ company.company_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element opportunity-description text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div v-if="getCompanies()" class="row text-start">
                  <h6 class="font-dark-grey">Currency<span class="font-14">*</span></h6>
                  <div class=" col col-12 col-sm-12 col-md-11 col-lg-8 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-form">
                      <select v-model="currency" name="currency" id="currency" required>
                        <option v-for="(element, index) in getCurrencies()" :value="element" :key="index">
                          {{ element }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey"> Name<span class="font-14">*</span></h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="2" cols="40" name="name" v-model="name" required>

                    </textarea>
                </div>
              </div>
            </div>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Description:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="5" cols="40" name="description" v-model="description">

                    </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
</template>


<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "CorporatePlanCreate",
  components: {
    NoPermissionComponent,
  },
  data() {
    return {
      company : null,
      name: null,
      description: null,
      currency: null,
    }
  },
  methods: {
    ...mapGetters(["getCorporatePlanData","getFormError", "getUsername", "isLoggedIn", "getCompanies", "getCompanyString", "getLoader", "getCurrencies"]),
    ...mapActions(["fetchCorporatePlanData", "performSetFormError", "fetchCompanies", "performCreateCorporatePlan"]),
    createCorporatePlan() {
      this.performCreateCorporatePlan({
        "company" : this.company,
        "name": this.name,
        "description": this.description,
        "currency": this.currency,
      })
    },
  },

  created() {
    document.title = "Create Corporate Plan";
    this.fetchCompanies();
    window.scrollTo(0,0);
  }
}


</script>
