<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a href="/db/permissions">
          <i class="fas fa-chevron-left"></i> Back to HomePage
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>


      <div  v-if="getSiteAdmin() == true" class="row plain-element">
        <div class="row plain-element">
          <div class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
              <div class="row row-functions text-start">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                  <img :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                       class="img responsive img-icon">
                </div>
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
                  <h3> Choose Site</h3>
                </div>

              </div>
            </div>
          </div>
          <p class="w-100"></p>
          <div class="row plain-element text-start">
            <div class="col col-12 col-sm-12 col-md-6 col-lg-12 text-start">
              <div class="row plain-element">
                <div v-if="getSiteNames()" class="row plain-element">
                  <div v-for="(site, site_code) in getSiteNames()" class="col col-12 col-sm-12 col-md-12 username-card" :key="site_code">
                    <div class="card menu-card menu-card-320" @click="goToSiteServices(site_code)">
                      <h5><i class="fas fa-chevron-right" aria-hidden="true"></i>{{site_code}} - {{ site }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row plain-element">
        <div class="row plain-element text-center">
          <p class="text-info"> </p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "CompanyChoiceView",
  components: {
    NoPermissionComponent,

  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getUsername","getSiteNames", "getUsers", "getCompanyString", "isLoggedIn", "getFormError", "getUserPermissionsArray", "getUserList", "getSiteAdmin" ]),
    ...mapActions(["performSetFormError", "fetchUserList"]),
    hasHistory () {
      return window.history?.length > 1
    },
    goToSiteServices(site_code) {
        location.href=(`/services/${site_code}/`);
    },
  },
  computed: {
  },
  created() {
    this.performSetFormError(null);
    this.fetchUserList();
    document.title = "User Permissions";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchUserList();
    next();
  }
}
</script>
