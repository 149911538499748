<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername() && getOpportunityData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Opportunity
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <UpdateOpportunityComponent
              :id = id
              :current_length_estimate = getOpportunityData().length_estimate
              :current_description = getOpportunityData().description
              :current_mwhe = getOpportunityData().mwhe
              :current_mwht = getOpportunityData().mwht
              :current_water_m3 = getOpportunityData().water_m3
              :current_irr_pct = getOpportunityData().irr_pct
              :current_co2_tonnes = getOpportunityData().co2_tonnes
              :current_grant_eur = getOpportunityData().grant_eur
              :current_savings_eur = getOpportunityData().savings_eur
              :current_total_investment_eur = getOpportunityData().total_investment_eur
              :current_capital_investment_eur  = getOpportunityData().capital_investment_eur
              :current_expenses_eur  = getOpportunityData().expenses_eur
              :current_energy_audit = getOpportunityData().energy_audit
              :current_status = getOpportunityData().status
              :current_link = getOpportunityData().link
              :current_porposal_pdf = getOpportunityData().propsal_pdf
      />
    </div>
  </div>
</div>
</template>


<script>

import { mapGetters, mapActions } from "vuex";
import UpdateOpportunityComponent from "@/components/opportunities/UpdateOpportunityComponent.vue";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "OpportunityUpdate",
  components: {
    UpdateOpportunityComponent,
    NoPermissionComponent,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getOpportunityData","getFormError", "getUsername", "isLoggedIn"]),
    ...mapActions(["fetchOpportunityData", "fetchSitesAndSeu", "performSetFormError"]),
    getOpportunityDetails() {
      this.fetchOpportunityData({"id": this.id })
    },
    hasHistory () {
      return window.history?.length > 1
    },
  },

  created() {
    this.fetchSitesAndSeu();
    this.getOpportunityDetails();
    this.performSetFormError(null);
    document.title = "Update Opportunity";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.getOpportunityDetails();
    next();
  }
}


</script>
