<template>
<div class="row-plans-tabs plain-element text-center">
        <div class=" col col-4 col-sm-4 col-md-2 col-lg-2 plain-element text-start">
          <div class="btn-plans-tab" @click="activateTab('plan-details')">
            <div v-if="tab === 'plan-details'" class="tab-plans-active">Plan Details</div>
            <div v-else>Plan Details</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-2 col-lg-2 plain-element text-start">
          <div class="btn-plans-tab" @click="activateTab('site-plans')">
            <div v-if="tab === 'site-plans'" class="tab-plans-active">Site Plans</div>
            <div v-else>Site Plans</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-2 col-lg-2 plain-element text-start">
          <div  class="btn-plans-tab" @click="activateTab('opportunities')">
            <div v-if="tab === 'opportunities'" class="tab-plans-active">Opportunities</div>
            <div v-else>Opportunities</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div  class="btn-plans-tab" @click="activateTab('climate-graphs')">
            <div v-if="tab === 'climate-graphs'" class="tab-plans-active">
              <span class="opp-abb-md">Climate Graphs</span>
              <span class="opp-abb-lg">Climate Graphs</span>
            </div>
            <div v-else>
              <span class="opp-abb-md">Climate Graphs</span>
              <span class="opp-abb-lg">Climate Graphs</span>
            </div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div  class="btn-plans-tab" @click="activateTab('financial-graphs')">
            <div v-if="tab === 'financial-graphs'" class="tab-plans-active">
              <span class="opp-abb-md">Fin. Graphs</span>
              <span class="opp-abb-lg">Financial Graphs</span>
            </div>
            <div v-else>
              <span class="opp-abb-md">Fin. Graphs</span>
              <span class="opp-abb-lg">Financial Graphs</span>
            </div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div class="btn-plans-tab"  v-if="parseInt(opportunity_plan_relations_length) > 0" @click="goToDivisionMaccGraph()">
            <div>MACC Graphs</div>
          </div>
        </div>
      </div>
</template>

<script>



export default {
  name: "DivisionPlanTabsComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
    opportunity_plan_relations_length: {
      type: Number,
      required: true,
    },
  },
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    activateTab(activatedTab) {
        this.$router.push({ name: 'division-plan-view', params: { id: this.id, tab: activatedTab } });
    },
    goToDivisionMaccGraph() {
      if (event.ctrlKey === true) {
        const customPath = '/api/division-macc-graph/' + this.id;
        const routeData = this.$router.resolve({ path: customPath });
        window.open(routeData.href, '_blank');
      } else {
        const customPath = '/api/division-macc-graph/' + this.id;
        const routeData = this.$router.resolve({ path: customPath });
        window.location.href = routeData.href; // Navigate to the custom path
      }
    },
  },

  computed: {


  },
  created() {


  },
}
</script>
