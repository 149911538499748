<template>
<div class="row plain-element">
  <form @submit.prevent="updateAlarm()" enctype="multipart/form-data">
    <div class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
            <div class="row row-functions text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                <img :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/em3-plain.png'" class="img responsive img-icon">
              </div>
              <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
                <h3 class="font-dark-grey">Update Alarm For {{ getAlarmData().site_code }} - {{ getAlarmData().seu }} - {{ getAlarmData().enpi }} </h3>
              </div>
              <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
                <router-link :to="{name: 'alarm-delete', params: {id: getAlarmData().id}}" class="btn btn-report" id="btn-light-red">Delete</router-link>
                <button type="submit" class="btn btn-report">
                  Submit Update
                </button>
              </div>
            </div>
          </div>
          <p class="w-100"></p>


          <div class="row plain-element opportunity-description text-start">
            <h6 class="font-dark-grey">
              Update Threshold:
            </h6>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-3 plain-element">
              <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
              <input id="threshold" type="number" name="threshold" v-model="threshold" min="0" max="1000000000"
                                                 step="0.1" required>
              </div>
            </div>
          </div>
          <p class="w-100"></p>
          <div class="row plain-element opportunity-description text-start">
            <h6 class="font-dark-grey">
              Update Above/Below:
            </h6>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-3 plain-element">
              <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                <select v-model="above" name="above" id="above" required>
                  <option value="true">Above</option>
                  <option value="false">Below</option>
                </select>
              </div>
            </div>
          </div>
          <p class="w-100"></p>
          <div class="row plain-element opportunity-description text-start">
            <h6 class="font-dark-grey">
              Update Email:
            </h6>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-3 plain-element">
              <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                <textarea rows="1" cols="40" name="author" v-model="email" maxlength="10">

                  </textarea>
              </div>
            </div>
          </div>
          <div class="row plain-element opportunity-description text-start">
            <h6 class="font-dark-grey">
              On/Off:
            </h6>
            <div class=" col col-12 col-sm-12 col-md-6 col-lg-3 plain-element">
              <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                <div class="row plain-element">
                    <div class="input-field  col col-12 col-sm-12">
                        <label class="energy-audit">
                          <input id="energy_audit" name="status" v-model="status" value="status" type="checkbox">
                          <span v-if="status === true" class="label-checkbox font-dark-grey"> Alarm is Active </span>
                          <span v-else class="label-checkbox font-dark-grey"> Alarm is Disabled </span>
                        </label>
                    </div>
                  </div>
              </div>
            </div>
          </div>

    </div>
  </form>
</div>
</template>

<script>
import { validateString, } from "@/common/validation.js";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "UpdateAlarmComponent",
  props: {
    id: { type: String, required: true },
    current_site_code: { type: String, required: false },
    current_seu: { type: String, required: false },
    current_enpi: { type: String, required: true },
    current_creator: { type: String, required: false },
    current_threshold: { type: String, required: false },
    current_email: { type: String, required: false },
    current_above: { type: Boolean, required: false },
    current_status: { type: Boolean, required: false },
  },
  data() {
    return {
      site_code: this.current_site_code,
      seu: this.current_seu,
      enpi: this.current_enpi,
      creator: this.current_creator,
      threshold: this.current_threshold,
      email: this.current_email,
      above: this.current_above,
      status: this.current_status
    }
  },

  methods: {
    ...mapGetters(["getAlarmData", "getUsername", "getCompanyString"]),
    ...mapActions(["performAlarmUpdate", "performSetFormError"]),
    validate: validateString,
    updateAlarm() {
      if (
        this.validate(this.creator)  === "error"  ||
        this.validate(this.email )  === "error"
      ){
        this.performSetFormError("Forbidden character at the start of a string(+,=,-,@)");
      } else {
        this.performAlarmUpdate({
           "id": this.id,
           "site_code": this.site_code,
           "seu": this.seu,
           "enpi": this.enpi,
           "creator": this.creator,
           "threshold": this.threshold,
           "email": this.email,
           "above": this.above === "true",
           "status": this.status === true
          })
       }
    },
  },
  computed: {
  },
  created() {

  }


}
</script>