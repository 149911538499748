<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && id" class="dashboard" v-cloak>
    <div  class="dashboard-cards">
      <div class="row row-title">
        <div class="">
          <h4 class=" underline-green"> Update Successful </h4>
        </div>
      </div>
      <div class="row plain-element">
        <div>
          <div class="row plain-element text-center">
              <a :href="'/corporate-plan/plan-details/' + id" type="submit" class="btn btn-success">Go to Plan</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import { mapGetters } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";


export default {
  name: "PlanSuccessReload",
  components: {
    NoPermissionComponent,

  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    }
  },

  methods: {
    ...mapGetters(["getUsername", "isLoggedIn"]),
  },
  created() {
    document.title = "Success";
    window.scrollTo(0,0);
  }
}


</script>
