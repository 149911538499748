<template>
  <div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
      <NoPermissionComponent/>
    </div>
    <div v-if="getUsername()" class="dashboard" v-cloak>
      <div class="row row-cards">
        <div class="row text-start row-back">
          <a href="/">
            <i class="fas fa-chevron-left"></i> Back To Homepage
          </a>
        </div>
        <div class="card card-description">

          <form @submit.prevent="generateActionReport()" method="POST" class="form-report width-100" enctype="multipart/form-data">
            <div class="row plain-element">
              <div v-if="getServiceSitesAndSeu()" class="col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
                <div class="row plain-element text-start">
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <h4 class="border-bottom-teal"> Generate Report</h4>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="getSiteNames()" class="row plain-element">
              <div v-if="getServiceSitesAndSeu()" class="col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                <div class="row plain-element text-start">
                  <label class="" for="reviewer">Report Name*</label>
                  <div class="input-field  col col-12 col-sm-12 col-form">

                    <select name="site_code" v-model="site_code" required>

                      <option v-for="(key, value, index) in getServiceSitesAndSeu()" :value="value" :key="index">
                        {{ getSiteNames()[value] }}
                      </option>

                    </select>
                  </div>
                </div>
                <br>
                <div class="row plain-element text-start">
                  <label class="" for="reviewer">Report Reviewer*</label>
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <input type="text" name="reviewer" v-model="reviewer" placeholder="" required>
                  </div>
                </div>
                <br>
                <div class="row plain-element text-start">
                  <label class="" for="submitted_to">Submitted To*</label>
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <input type="text" name="submitted_to" v-model="submitted_to" placeholder="" required>
                  </div>
                </div>
                <div class="row plain-element text-start">
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <label>
                      <input type="checkbox" name="enpi_tables" v-model="enpi_tables">
                      <span class="label-checkbox">Include EnPI Tables</span>
                    </label>
                  </div>
                </div>
                <br>
                <div class="row plain-element text-start">
                  <div class="input-field col col-sm-5 col-form">
                    <label class="" for="yearweek">YearWeek*</label>
                    <input v-model="yearweek" type="number" name="yearweek" class="validate" value="x"
                           min="1901" max="3053" step="1" placeholder="2401" required>

                  </div>
                  <div class="input-field col col-sm-2 col-form"></div>
                  <div class="input-field col col-sm-5 col-form">
                    <label class="" for="revision">Revision no.*</label>
                    <input v-model="revision" type="number" name="revision" class="validate" value="0"
                           min="0" max="99" step="1" placeholder="Revision no." required>
                  </div>
                </div>
                <br>
                <div class="row plain-element text-start">
                </div>
                <div class="row plain-element text-start">
                  <div class="input-field  col col-12 col-sm-12 col-form">
                    <div v-if="getLoader()" class="loader"></div>
                    <button v-else type="submit" class="btn btn-submit">
                      <i class="fas fa-file-download"></i>&nbsp; Download Report
                    </button>

                  </div>
                </div>
                <br>


                <div class="row plain-element text-start">
                  <p class="error"  v-if="getFormError()">
                    <span id="formError">{{ getFormError() }}</span>
                  </p>
                  <p class="error"  v-else>
                  </p>
                  <br>
                  <p>{{ message }}</p>
                </div>
              </div>
              <div v-if="getServiceSitesAndSeu()" class="col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";


export default {
  name: "ActionPDFReport",
  components: {
    NoPermissionComponent,

  },
  props: {

  },
  data() {
    return {
      custom_page: false,
      custom_page_title: "",
      custom_filename: null,
      custom_png_file: null,
      power_bi: "Active",
      site_code: "",
      reviewer: "",
      submitted_to: "",
      enpi_tables: "",
      yearweek: this.getYearWeek(new Date()),
      revision: 0,
      message: "",
      selectedPages: [],
      selectedNames: [],
      powerbi_page: false,
      showReports: [],
    }
  },

  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getSiteNames", "getServiceSitesAndSeu", "getLoader", "getFormError", "getNestedSitesAndDashboardDetails"]),
    ...mapActions(["performSetFormError", "fetchServiceSitesAndSeu", "performGenerateActionReport", "fetchNestedSitesAndDashboardDetails","fetchSiteNames"]),
    logPageNames() {
      const pageNamesArray = [];
        this.selectedPages.forEach((page) => {
          const reportId = page.report_id;

          const pageObject = {
            [reportId]: page.name,
          };

          pageNamesArray.push(pageObject);
        });

        const uniqueReportIds = [...new Set(pageNamesArray.map(obj => Object.keys(obj)[0]))];
        console.log("Unique report_id count:", uniqueReportIds.length);

        console.log(pageNamesArray);
    },
    toggleReport(index) {
      // Toggle the visibility of the clicked report section
      this.$set(this.showReports, index, !this.showReports[index]);
    },
    getYearWeek(date) {
      const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
      const weekNumber = this.getWeekNumber(date);
      const formattedWeek = weekNumber.toString().padStart(2, '0');
      return `${year}${formattedWeek}`;
    },
    getWeekNumber(date) {
      const target = new Date(date.valueOf());
      const dayNr = (date.getDay() + 6) % 7; // Calculate the day of the week

      target.setDate(target.getDate() - dayNr + 3); // Move date to Thursday of the same week
      const firstThursday = target.valueOf();

      target.setMonth(0, 1); // Set the target to the first day of January
      const dayDiff = Math.round((firstThursday - target) / (86400000 * 7)); // Calculate full weeks to Thursday

      return 1 + dayDiff; // Return the week number
    },
    generateActionReport() {
//      Selected pages + report count
      const pageNamesArray = [];
      this.selectedPages.forEach((page) => {
        const reportId = page.report_id;

        const pageObject = {
          [reportId]: page.name,
        };

        pageNamesArray.push(pageObject);
      });

      const uniqueReportIds = [...new Set(pageNamesArray.map(obj => Object.keys(obj)[0]))];
      if (uniqueReportIds.length > 3) {
        this.performSetFormError("Too many Reports were chosen. Maximum 3 reports allowed.")
        console.log("too much")
      } else {
        this.performSetFormError(null)
        let formData = new FormData();
        formData.append("power_bi", this.powerbi_page);
        formData.append("site_code", this.site_code);
        formData.append("reviewer", this.reviewer);
        formData.append("submitted_to", this.submitted_to);
        formData.append("enpi_tables", this.enpi_tables);
        formData.append("yearweek", this.yearweek);
        formData.append("revision", this.revision);
        formData.append("message", this.message);
        formData.append("custom_page", this.custom_page);
        formData.append("custom_filename", this.custom_filename);
        formData.append("custom_page_title", this.custom_page_title);
        formData.append("available_pages", []);
        formData.append("selected_pages", JSON.stringify(this.selectedPages));
        formData.append("selected_names", this.selectedPages.map(pageName => this.getPageDisplayName(pageName)));

        if (this.custom_png_file) {
          formData.append("custom_png_file", this.custom_png_file);
        }
        this.performGenerateActionReport(formData);
      }
    },
    handleFileUpload() {
      this.custom_png_file = this.$refs.file.files[0];
      this.custom_filename = this.$refs.file.files[0].name;
      console.log(this.custom_png_file)
    },
  },
  created() {
    document.title = "Action Report";
    this.performSetFormError(null);
    this.fetchSiteNames();
    this.fetchServiceSitesAndSeu();
    this.fetchNestedSitesAndDashboardDetails();
    window.scrollTo(0,0);
  }
}


</script>
<style scoped>
.expanded {
  cursor: pointer;
}
.expanded:hover {
  text-decoration: underline;
}
</style>