<template>
  <div class="row plain-element">
    <div v-if="sitesArray.length > 10" class="row-tabs plain-element text-center">
      <div v-for="site in sitesArray.slice(0, 10)" class="btn-tab btn-tab-company-short" :key="site.site_code" @click="activate(site.site_code)">
        <div v-if="site.site_code === getActiveSite()" class="tab-active">{{ site.site_name | truncatecompany(8)  }}</div>
        <div v-else class="tab">{{ site.site_name | truncatecompany(8) }}</div>
      </div>
    </div>
    <div v-else-if="sitesArray.length > 7 && sitesArray.length < 10" class="row-tabs plain-element text-center">
      <div v-for="site in sitesArray" class="btn-tab btn-tab-company-long" :key="site.site_code" @click="activate(site.site_code)">
        <div v-if="site.site_code === getActiveSite()" class="tab-active">{{ site.site_name | truncatecompany(10)  }}</div>
        <div v-else class="tab">{{ site.site_name | truncatecompany(10) }}</div>
      </div>
    </div>
    <div v-else class="row-tabs plain-element text-center">

      <div v-for="site in sitesArray" class="btn-tab btn-tab-company-long" :key="site.site_code" @click="activate(site.site_code)">
        <div v-if="site.site_code === getActiveSite()" class="tab-active">{{ site.site_name | truncatecompany(10) }}</div>
        <div v-else class="tab">{{ site.site_name | truncatecompany(10) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RowTabsComponent",
  props: {
    sitesArray: { type: Array, required: true },
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getActiveSite"]),
    ...mapActions(["activateSite"]),

    activate(site) {
        this.activateSite(site)
    },
  },
  computed: {

  },

  filters: {
//  Just in case if some strings would be too long and would destroy a layout
    truncatecompany (value, limit) {
      if (value) {
          if (value.length > limit) {
            value = value.substring(0, limit) + ".";
            }
            return value
        }
      }
  },
  created() {

  },
}
</script>