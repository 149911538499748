<template>
<div class="row plain-element">
    <div v-if="!isLoggedIn()"  class="dashboard-cards">
        <NoPermissionComponent/>
    </div>
   <div v-if="getUsername()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="$router.push('/site-choice')">
          <i class="fas fa-chevron-left"></i> Back to Site Choice
        </a>
      </div>
    </div>
    <div class="card card-details">
      <p class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error"  v-else>
      </p>
      <div v-if="loading" class="loading-message">Loading...</div>
      <div v-else  class="row plain-element">
        <div class="row plain-element">
          <p class="w-100"></p>
          <div v-if="getSiteServices()" class="row plain-element text-start">
            <div class="col col-12 col-sm-12 col-md-6 col-lg-12 text-start">
              <div class="row plain-element">
                <ChangeSiteServicesComponent
                  :site_services = getSiteServices()
                  :site_code = site_code


                />
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import ChangeSiteServicesComponent from "@/components/permissions/ChangeSiteServicesComponent.vue"
export default {
  name: "SiteServicesView",
  components: {
    NoPermissionComponent,
    ChangeSiteServicesComponent,

  },
  props: {
    site_code: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getFormError", "getSiteServices"]),
    ...mapActions(["performSetFormError", "fetchSiteServices"]),
    hasHistory () {
      return window.history?.length > 1
    },
    async fetchData() {
      try {
        await this.fetchSiteServices({ site_code: this.site_code });
        // Data has been successfully fetched
        this.loading = false;
      } catch (error) {
        // Handle error if necessary
        console.error("Error fetching data:", error);
        this.loading = false;
      }
    },

  },
  computed: {
  },
  created() {
    this.performSetFormError(null);
    this.fetchData();
    document.title = "Site Services";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchData();
    next();
  }
}
</script>
