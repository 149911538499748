<template>
  <div v-if="description" class="row plain-element">
    <form @submit.prevent="updateDivisionPlan()" enctype="multipart/form-data">
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/EM3.png'"
                   class="img responsive img-icon">
            </div>
            <div class="col col-sm-6 col-md-8 col-lg-8 plain-element text-start">
              <h3 class="font-dark-grey">Update {{ getDivisionPlanData().name }} </h3>
            </div>
            <div class="col col-sm-6 col-md-4 col-lg-3 plain-element text-end">
              <button type="submit" class="btn btn-report">
                Submit Update
              </button>
            </div>
          </div>
        </div>
        <div class="row plain-element text-start">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Update Currency:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <select v-model="currency" name="currency" id="currency" required>
                    <option v-for="(element, index) in getCurrencies()" :value="element" :key="index">
                      {{ element }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Update Name:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="2" cols="40" name="name" v-model="name" maxlength="120" required>

                    </textarea>
                </div>
              </div>
            </div>
            <div class="row plain-element opportunity-description text-start">
              <h6 class="font-dark-grey">
                Update Description:

              </h6>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-11 col-lg-10 col-form">
                  <textarea rows="5" cols="40" name="description" v-model="description" maxlength="120" required>

                    </textarea>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </form>
  </div>
</template>


<script>
import { validateString, } from "@/common/validation.js";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "UpdateDivisionPlanComponent",
  props: {
    id: { type: String, required: true },
    current_name: { type: String, required: false },
    current_description: { type: String, required: false },
    current_currency: { type: String, required: false },
    current_company: { type: String, required: false },
  },
  data() {
    return {
      name: this.current_name,
      description: this.current_description,
      company: this.current_company,
      currency: this.current_currency,
    }
  },

  methods: {
    ...mapGetters(["getDivisionPlanData", "getUsername", "getCompanyString", "getCurrencies"]),
    ...mapActions(["performDivisionPlanUpdate", "performSetFormError"]),
    validate: validateString,
    updateDivisionPlan() {
      if (this.validate(this.name) === "error" ||
          this.validate(this.description) === "error"
      )  {
            this.performSetFormError("Forbidden character at the start of a string(+,=,-,@)");
      } else {
      this.performDivisionPlanUpdate({
                               "id": this.id,
                               "name": this.validate(this.name),
                               "description": this.validate(this.description),
                               "currency": this.currency,
                               "company": this.company,
                              })
      }
    },
  },
  computed: {
  },
  created() {

  }


}
</script>